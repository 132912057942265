// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { activityService } from "../../../../store/services/ServiceActivity";
import { LOADING_TIME } from "../../../../store/config";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import MyActivityItem from "./MyActivityItem";
import MyActivityNew from "./MyActivityNew";
import { Grid, Button, Modal, Form } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function ActivitiesList() {
    // state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [copiedId, setCopiedId] = useState(null);
    const [newName, setNewName] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await activityService.getCompanyActivities("");
            setData(result);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    /** component functions **/

    const handleCopy = (id) => {
        setIsOpen(true);
        setCopiedId(id);
    };

    const handleClose = () => {
        setIsOpen(false);
        setNewName("");
    };

    const handleCopyAction = async () => {
        setProcessing(true);
        if (newName === "") {
            setErrorMessage("Nevyplnil(a) si povinný údaj!");
            setProcessing(false);
            return;
        }

        let result = await activityService.duplicateCompanyOffer(
            { name: newName },
            copiedId
        );

        if (!result) {
            alert("Error");
            setProcessing(false);
        } else {
            let activity = await activityService.getCompanyActivities(
                "?id=" + result.id
            );
            if (activity[0]) {
                setData([...data, activity[0]]);
            } else {
                alert("Error");
                setProcessing(false);
            }
        }

        setProcessing(false);
        setIsOpen(false);
    };

    const handleNameChange = (e, data) => {
        setNewName(data.value);
        setErrorMessage(null);
    };

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HeaderTitle title="Prehľad mojich aktivít" />
                    <section className="activities">
                        <Grid>
                            <Grid.Row stretched>
                                {data.map((activity) => (
                                    <Grid.Column
                                        mobile={16}
                                        tablet={8}
                                        computer={4}
                                        key={activity.id}
                                    >
                                        <MyActivityItem
                                            data={activity ? activity : {}}
                                            handleCopy={() =>
                                                handleCopy(activity.id)
                                            }
                                        />
                                    </Grid.Column>
                                ))}
                                <Grid.Column
                                    mobile={16}
                                    tablet={8}
                                    computer={4}
                                >
                                    <MyActivityNew />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </section>
                    <Modal open={isOpen} size="tiny">
                        <Modal.Content>
                            <h2 style={{ textAlign: "center" }}>
                                Kopírovať aktivitu
                            </h2>
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Input
                                        fluid
                                        type="text"
                                        autoFocus
                                        required
                                        name="name"
                                        label="Názov novej aktivity"
                                        placeholder="Názov novej aktivity"
                                        onChange={handleNameChange}
                                        error={errorMessage}
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions style={{ textAlign: "center" }}>
                            <Button
                                secondary
                                onClick={handleClose}
                                disabled={processing}
                            >
                                Návrat na aktivity
                            </Button>
                            <Button
                                loading={processing}
                                disabled={processing}
                                color="pink"
                                icon="copy"
                                labelPosition="right"
                                content="Kopírovať aktivitu"
                                onClick={handleCopyAction}
                            />
                        </Modal.Actions>
                    </Modal>
                </>
            )}
        </Fragment>
    );
}

export default ActivitiesList;
