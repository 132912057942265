// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { paymentService } from "../../../../store/services/ServicePayment";
import { LOADING_TIME } from "../../../../store/config";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import OrderedEventsTable from "./OrderedEventsTable";
import { Card, Grid, Form, Button } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

// component
const ClientEvents = () => {

    // eslint-disable-next-line
    const [data, setData] = useState([]);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({
        from: moment().startOf('month').format("DD.MM.YYYY"),
        to: moment().endOf('month').format("DD.MM.YYYY"),
        status: null,
        activity: null
    })

    const [activityOptions, setActivityOptions] = useState([])

    const [stats, setStats] = useState({
        total: 0,
        used: 0,
        nonUsed: 0,
        refunded: 0,
    })

    // fetch data
    async function fetchData() {
        setIsLoading(true);
        let params = `?from=${moment(filters.from, "DD.MM.YYYY").format("YYYY-MM-DD")}&to=${moment(filters.to, "DD.MM.YYYY").format("YYYY-MM-DD")}`;
        
        if( filters.status !== null ){
            params += `${filters.status}`
        }
        
        const result = await paymentService.getDigitalProductsForUser(params);

        if (result.status === 200) {
            // calculate stats
            const orders = result.response
            orders.sort(function(a, b) {
                let c = new Date(a.event.starts);
                let d = new Date(b.event.starts);
                return d - c;
            });

            let totalRefunded = 0;
            let totalUsed = 0;
            let totalNonUsed = 0;
            let options = [];
            let all = orders.length;
            for(let i = 0; i < orders.length; i++) {
                // create activity list:
                if( !options.some(item => item.value === orders[i].event.activity.id) ){
                    options.push({
                        id: orders[i].event.activity.id,
                        value: orders[i].event.activity.id,
                        text: orders[i].event.activity.name
                    })
                }

                if( (filters.activity === orders[i].event.activity.id || filters.activity === null) ){
                    orders[i] = {...orders[i], show: true};
                    if( orders[i].is_used ) {
                        totalUsed += 1
                    } else {
                        totalNonUsed += 1
                    }
                    
                    if( orders[i].is_refunded ){
                        totalRefunded += 1
                    }
                } else {
                    orders[i] = {...orders[i], show: false};
                    all -= 1;
                }
                
            }

            setData(orders);
            setActivityOptions(options)
            setStats({
                total: all,
                used: totalUsed,
                nonUsed: totalNonUsed,
                refunded: totalRefunded,
            })
        }

        setTimeout(function () {
            setIsLoading(false);
        }, LOADING_TIME);
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const handleFilter = async () => {
        fetchData()
    }

    const statusOptions = [
        { key: 2, text: 'Nevyužité', value: '&is_used=false' },
        { key: 3, text: 'Využité', value: '&is_used=true&is_refunded=false' },
        { key: 4, text: 'Zrušená/vrátená', value: '&is_refunded=true' },
    ]

    // template
    return (
        <Fragment>
            <HeaderTitle title="Moje termíny" />
            <section>
                <Grid>
                    <Grid.Row computer={8} tablet={16} mobile={16} style={{ paddingBottom: 0 }}>
                        <Grid.Column>
                            <Form onSubmit={handleFilter}>
                                <Form.Group widths="equal">
                                    <DateInput
                                        fluid
                                        required
                                        localization="sk"
                                        label="Dátum od"
                                        placeholder="DD.MM.YYYY"
                                        dateFormat="DD.MM.YYYY"
                                        value={filters.from}
                                        onChange={(e, { value }) => setFilters({...filters, from: value})}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                    />
                                    <DateInput
                                        fluid
                                        required
                                        localization="sk"
                                        label="Dátum do"
                                        placeholder="DD.MM.YYYY"
                                        dateFormat="DD.MM.YYYY"
                                        value={filters.to}
                                        onChange={(e, { value }) => setFilters({...filters, to: value})}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                    />
                                    <Form.Select
                                        fluid
                                        clearable
                                        label='Stav'
                                        options={statusOptions}
                                        placeholder='Vyberte stav'
                                        value={ filters.status }
                                        onChange={(e, { value }) => setFilters({...filters, status: value})}
                                    />
                                    <Form.Select
                                        fluid
                                        search
                                        clearable
                                        label='Aktivita'
                                        options={activityOptions}
                                        placeholder='Vyberte aktivitu'
                                        value={ filters.activity }
                                        onChange={(e, { value }) => {
                                            if( value === "" ) setFilters({...filters, activity: null})
                                            if( value !== "" ) setFilters({...filters, activity: value})
                                        }}
                                    />
                                    <Form.Field style={{ marginTop: "1.8rem" }}>
                                        <Button style={{ background: "var(--primary)", color: "var(--white)" }}>Potvrdiť</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                { isLoading 
                    ? <Loading />
                    : 
                    <>
                        <Grid style={{ marginBottom: "1rem" }}>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <Card fluid style={{ height: "5rem" }}>
                                        <Card.Content textAlign="center">
                                            Celkovo: <strong>{ stats.total }</strong> 
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column>
                                    <Card fluid style={{ height: "5rem" }}>
                                        <Card.Content textAlign="center">
                                            Využité: <strong>{ stats.used }</strong> 
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column>
                                    <Card fluid style={{ height: "5rem" }}>
                                        <Card.Content textAlign="center">
                                            Nevyužité: <strong>{ stats.nonUsed }</strong> 
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column>
                                    <Card fluid style={{ height: "5rem" }}>
                                        <Card.Content textAlign="center">
                                            Zrušené/vratené: <strong>{ stats.refunded }</strong> 
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <OrderedEventsTable orders={data} setOrders={setData} isLoading={isLoading} />
                    </>
                }
            </section>
        </Fragment>
    );
};

export default ClientEvents;
