// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { addressService } from "../../../../store/services/ServiceAddress";
import { LOADING_TIME } from "../../../../store/config";
import { routes } from "../../../../store/routes";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import AddressItem from "./AddressItem";
import { Grid } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../../../index";
import ButtonCardLink from "../../../../components/buttons/ButtonCardLink";

// component
function AddressesList() {
    // state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await addressService.getAddresses();
            setData(result);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // template

    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HeaderTitle title="Miesto konania aktivity" />
                    <section className="addresses" style={{ marginTop: "5px" }}>
                        <Grid>
                            <Grid.Row stretched columns={4}>
                                {data.map((address) => (
                                    <Grid.Column
                                        mobile={16}
                                        tablet={4}
                                        computer={4}
                                        key={address.id}
                                    >
                                        <AddressItem
                                            data={address}
                                            key={address.id}
                                        />
                                    </Grid.Column>
                                ))}
                                <Grid.Column
                                    mobile={16}
                                    tablet={8}
                                    computer={4}
                                >
                                    <ButtonCardLink
                                        to={routes.ADDRESSES_ADD}
                                        text="Nové miesto"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </section>
                </>
            )}
        </Fragment>
    );
}

export default AddressesList;
