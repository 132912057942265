// react
import React, { useRef, useState, useEffect } from "react";
// store
import { activityService } from "../../../../store/services/ServiceActivity";
import { handleSourceReference } from "../../../../store/services/helpers/functions";
// components
import {
    Form,
    Modal,
    Button,
    Icon,
    Header,
    Image,
    Popup,
    Segment,
    Divider,
    Card,
} from "semantic-ui-react";
// others
import ReactCrop from "react-image-crop";

// component
function Gallery({ images, setImages, mainImage, setMainImage }) {
    const imgRef = useRef(null);

    // state
    const [isImageUpload, setIsImageUpload] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState({
        file: null,
        crop: {
            unit: "%",
            width: 100,
            aspect: 12 / 5, // 1200x500
        },
        source: null,
    });
    const [croppedImage, setCroppedImage] = useState(null);
    const [companyImages, setCompanyImages] = useState([]);

    // functions
    const handleImageUploadClose = () => {
        setIsImageUpload(false);
        setUploadedImage({
            file: null,
            crop: {
                unit: "%",
                width: 100,
                aspect: 12 / 5,
            },
            source: null,
        });
    };

    const handleImageUploadOpen = () => {
        setIsImageUpload(true);
    };

    const handleFile = (e) => {
        const file = e.target.files[0];

        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

        if (!allowedTypes.includes(file["type"])) {
            alert(
                "Tento typ súboru nie je podporovaný! Iba JPG alebo PNG obrázky sú podporované."
            );
            return;
        }

        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            setUploadedImage({
                file: file,
                crop: uploadedImage.crop,
                source: fileReader.result,
            });
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    const onImageLoaded = (image) => {
        imgRef.current = image;
        setUploadedImage({
            file: imgRef.current,
            crop: uploadedImage.crop,
            source: uploadedImage.source,
        });
    };

    const onCropChange = (crop) => {
        setUploadedImage({
            file: uploadedImage.file,
            crop: crop,
            source: uploadedImage.source,
        });
    };

    const onCropComplete = (crop) => {
        if (imgRef.current && crop.width && crop.height) {
            getCroppedImg(imgRef.current, crop);
        }
    };

    function dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], filename, { type: mime });
        setCroppedImage(croppedImage);
    }

    function getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const reader = new FileReader();
        canvas.toBlob((blob) => {
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                dataURLtoFile(reader.result, "image.jpg");
            };
        });
    }

    const handleImageUpload = async () => {
        setIsUploading(true);

        if (croppedImage !== null) {
            const formData = new FormData();
            formData.append("source", croppedImage);

            // send post request to upload image:
            let image = await activityService.imageUpload(formData);
            if (image !== false) {
                if (images.length === 0) {
                    setMainImage(image.id);
                }

                setImages([...images, image]);
                setIsUploading(false);

                setUploadedImage({
                    file: null,
                    crop: {
                        unit: "%",
                        width: 100,
                        aspect: 12 / 5,
                    },
                    source: null,
                });

                setIsImageUpload(false);
            } else {
                setIsImageUpload(true);
                setIsUploading(false);
            }
        } else {
            alert("Vyberte obrázok");
            setIsUploading(false);
            return;
        }
    };

    const setPrimary = (id) => {
        setMainImage(id);
    };

    const removeImage = (position) => {
        let tmpImages = [...images];
        tmpImages.splice(position, 1);

        setImages(tmpImages);
    };

    const addToGallery = (image) => {
        if (images.length === 0) {
            setMainImage(image.id);
        }

        setImages([...images, image]);
        setIsImageUpload(false);
    };

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            const companyImages = await activityService.getCompanyImages();

            // first filter out those that are matched with current gallery images
            let notUsedImages = companyImages.filter(
                (o1) => !images.some((o2) => o1.id === o2.id)
            );
            setCompanyImages(notUsedImages);
        };

        fetchData();
    }, [images]);

    // template
    return (
        <Form.Field>
            <label>Galéria</label>
            <Image.Group size="tiny">
                {images.map((image, index) => (
                    <Popup
                        key={index}
                        on="click"
                        trigger={
                            <Button
                                size="medium"
                                type="button"
                                className="gallery-image"
                                color={mainImage === image.id ? "pink" : "grey"}
                                title={
                                    mainImage === image.id
                                        ? "Nastavený ako hlavný obrázok."
                                        : ""
                                }
                            >
                                <Image
                                    src={handleSourceReference(image.source)}
                                    alt={`Image ${index}`}
                                    key={index}
                                />
                            </Button>
                        }
                        flowing
                        hoverable
                    >
                        <Image
                            src={handleSourceReference(image.source)}
                            alt={`Image ${index}`}
                            size="medium"
                        />
                        <br />

                        <Button
                            fluid
                            primary
                            onClick={(e) => setPrimary(image.id)}
                            disabled={mainImage === image.id}
                        >
                            Nastaviť ako primárny
                        </Button>
                        {mainImage !== image.id ? (
                            <>
                                <br />
                                <Button
                                    fluid
                                    color="red"
                                    onClick={(e) => removeImage(index)}
                                >
                                    Odobrať
                                </Button>
                            </>
                        ) : (
                            ""
                        )}
                    </Popup>
                ))}

                <Button
                    style={{ marginTop: "0.5rem" }}
                    onClick={handleImageUploadOpen}
                    type="button"
                    size="massive"
                    color="pink"
                    icon={<Icon name="plus" title="Pridať obrázok" />}
                />
                <Modal
                    open={isImageUpload}
                    onClose={handleImageUploadClose}
                    size="large"
                >
                    <Modal.Content>
                        <div style={{ textAlign: "center" }}>
                            {uploadedImage.source === null && (
                                <label htmlFor="profile_pic">
                                    <Segment style={{ cursor: "pointer" }}>
                                        <Header icon>
                                            <Icon name="cloud upload" />
                                            Kliknutím vyberte vlastný obrázok z
                                            počítača
                                        </Header>
                                    </Segment>
                                </label>
                            )}
                            <input
                                type="file"
                                id="profile_pic"
                                onChange={handleFile}
                                style={{ display: "none" }}
                            />
                            <Divider horizontal />

                            {uploadedImage.source && (
                                <ReactCrop
                                    src={uploadedImage.source}
                                    crop={uploadedImage.crop}
                                    locked
                                    disabled={isUploading}
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onCropChange}
                                />
                            )}

                            {uploadedImage.source === null && (
                                <>
                                    <Divider horizontal>
                                        Alebo vyberte z existujúcich obrázkov
                                    </Divider>
                                    <Card.Group
                                        stackable
                                        doubling
                                        itemsPerRow={3}
                                        style={{ paddingTop: "10px" }}
                                    >
                                        {companyImages.length !== 0 ? (
                                            companyImages.map(
                                                (image, index) => (
                                                    <Card fluid key={index}>
                                                        <Card.Content className="full-size">
                                                            <Image
                                                                size="massive"
                                                                src={handleSourceReference(image.source)}
                                                            />
                                                        </Card.Content>
                                                        <Card.Content extra>
                                                            <Button
                                                                fluid
                                                                primary
                                                                size="tiny"
                                                                onClick={() =>
                                                                    addToGallery(image)
                                                                }
                                                            >
                                                                Pridať obrázok
                                                            </Button>
                                                        </Card.Content>
                                                    </Card>
                                                )
                                            )
                                        ) : (
                                            <Header
                                                as="h3"
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                }}
                                                icon
                                            >
                                                <Icon name="meh" style={{ fontSize: "1.2rem" }} />
                                                <Header.Subheader>
                                                    Neboli najdene žiadne
                                                    existujúce obrázky.
                                                </Header.Subheader>
                                            </Header>
                                        )}
                                    </Card.Group>
                                </>
                            )}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            secondary
                            onClick={handleImageUploadClose}
                            disabled={isUploading}
                        >
                            Zrušiť
                        </Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Uložiť obrázok"
                            onClick={handleImageUpload}
                            disabled={isUploading || croppedImage === null}
                            loading={isUploading}
                        />
                    </Modal.Actions>
                </Modal>
            </Image.Group>
        </Form.Field>
    );
}

export default Gallery;
