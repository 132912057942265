// react
import React from "react";
// store
import { routes } from "../../../../store/routes";
// components
import ButtonIconEdit from "../../../../components/buttons/ButtonIconEdit";
import DimmerLoader from "../../../../components/others/DimmerLoader";
import DataTable from "../../../../components/tables/DataTable";

// component
function CategoryTable({ data, isLoading }) {
    const orderedHeaders = ["name", "tags", "status", "tableActions"];

    const TableAction = ({ row }) => {
        return <ButtonIconEdit link={routes.CATEGORIES_EDIT + row.id} />;
    };

    const headers = {
        id: {
            text: "ID",
            invisible: true,
        },
        tags: {
            text: "Kľúčové slová",
            invisible: true,
        },
        name: {
            text: "Názov",
            filterable: true,
            sortable: true,
        },
        status: {
            text: "Status",
            filterable: false,
            sortable: true,
            transform: (value) => `${value === true ? "Aktívna" : "Neaktívna"}`,
        },
        tableActions: {
            text: "Akcie",
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return <TableAction row={row} />;
            },
        },
    };

    // template
    return (
        <>
            <DimmerLoader active={isLoading} />
            <DataTable
                data={data}
                orderedHeaders={orderedHeaders}
                headers={headers}
            />
        </>
    );
}

export default CategoryTable;
