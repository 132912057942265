// react
import React from "react";
import { Switch, Route } from "react-router-dom";
// store
import { routes } from "../../../../store/routes";
import { authService } from "../../../../store/services/ServiceAuth";
// components
import PlanningOverview from "./PlanningOverview";

// component
function Planning() {
    authService.useIsProvider();

    // template
    return (
        <Switch>
            <Route exact path={routes.PLANNING} component={PlanningOverview} />
        </Switch>
    );
}

export default Planning;
