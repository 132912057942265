// react
import React, { useState, useEffect } from "react";
// store
import { LOADING_TIME } from "../../../../store/config";
import { activityService } from "../../../../store/services/ServiceActivity";
import { activeOptions } from "../../../../store/services/helpers/settings";
// components
import ActivityTable from "./ActivityTable";
import ButtonAdd from "../../../../components/buttons/ButtonAdd";
import ButtonGroupSearch from "../../../../components/buttons/ButtonGroupSearch";
import AccordionColumn from "../../../../components/others/AccordionColumn";
import { Form, Grid, Segment, Header } from "semantic-ui-react";

// component
function ActivitySearch() {
    // state
    const [data, setData] = useState([]);
    const [tableIsLoading, setTableIsLoading] = useState(false);

    // form attributes
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");

    const handleTags = (tags) => {
        let tagsList = ""
        for (let i = 0; i < tags.length; i++) {
            if( tags.length - 1 === i ){
                tagsList += tags[i].name
            } else {
                tagsList += tags[i].name + ", " 
            }
        }

        return tagsList
    } 

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setTableIsLoading(true);
            const result = await activityService.getActivities("");

            if (result.status === 200) {
                // setup data array for table
                let tableData = [];
                for (let i = 0; i < result.response.length; i++) {
                    tableData.push({
                        id: result.response[i].id,
                        name: result.response[i].name,
                        status: result.response[i].status,
                        tags: handleTags(result.response[i].tags),
                    });
                }

                setData(tableData);
            }


            setTimeout(function () {
                setTableIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);


    /** component functions **/
    const handleOnChangeSearch = (e, data) => {
        setSearch(data.value);
    };

    const handleOnChangeStatus = (e, data) => {
        setStatus(data.value);
    };

    const resetForm = async () => {
        setTableIsLoading(true);
        setSearch("");
        setStatus("");

        const result = await activityService.getActivities("");
        if (result.status === 200) {
            // setup data array for table
            let tableData = [];
            for (let i = 0; i < result.response.length; i++) {

                tableData.push({
                    id: result.response[i].id,
                    name: result.response[i].name,
                    status: result.response[i].status,
                    tags: handleTags(result.response[i].tags),
                });
            }

            setData(tableData);
        }

        setTimeout(function () {
            setTableIsLoading(false);
        }, LOADING_TIME);
    };

    const handleSubmit = async () => {
        setTableIsLoading(true);
        let params = "";
        if (search !== "" && status === "") {
            params = `?search=${search}`;
        } else if (search === "" && status !== "") {
            params = `?status=${status}`;
        } else if (search !== "" && status !== "") {
            params = `?search=${search}&status=${status}`;
        }

        // send request to get data based on parameters
        const result = await activityService.getActivities(params);
        if (result.status === 200) {
            // setup data array for table
            let tableData = [];
            for (let i = 0; i < result.response.length; i++) {
                tableData.push({
                    id: result.response[i].id,
                    name: result.response[i].name,
                    status: result.response[i].status,
                    tags: handleTags(result.response[i].tags),
                });
            }

            setData(tableData);
        }

        setTimeout(function () {
            setTableIsLoading(false);
        }, LOADING_TIME);
    };

    console.log(data)

    // search form inputs
    const panels = [
        {
            key: "details",
            title: "Kritéria vyhľadávania",
            content: {
                content: (
                    <div>
                        <Form
                            onSubmit={handleSubmit}
                            id="form-search-activities"
                        >
                            <Form.Group widths="equal">
                                <Form.Input
                                    fluid
                                    label="Vyhľadaj"
                                    placeholder="Vyhľadaj"
                                    value={search}
                                    onChange={handleOnChangeSearch}
                                />
                                <Form.Select
                                    fluid
                                    label="Status"
                                    options={activeOptions}
                                    placeholder="Status"
                                    value={status}
                                    onChange={handleOnChangeStatus}
                                />
                            </Form.Group>
                        </Form>

                        <ButtonGroupSearch
                            form="form-search-activities"
                            resetDisabled={search === "" && status === ""}
                            resetOnClick={resetForm}
                        />
                    </div>
                ),
            },
        },
    ];

    // template
    return (
        <Grid>
            <ButtonAdd
                link="/dashboard/activities/add"
                text="Pridať aktivitu"
            />
            <AccordionColumn panels={panels} />

            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Segment>
                    <Header as="h5">Prehľad aktivít</Header>

                    <ActivityTable
                        data={data ? data : []}
                        isLoading={tableIsLoading}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default ActivitySearch;
