// react
import React, { useState, Fragment, useEffect } from "react";
// store
import { activityService } from "../../store/services/ServiceActivity";
import { addressService } from "../../store/services/ServiceAddress";
import { userProfileService } from "../../store/services/ServiceUserProfile";
import {
    durationOptions,
    currencyOptions,
} from "../../store/services/helpers/settings";
import { LOADING_TIME } from "../../store/config";
import {
    setSelectOptions,
    setSelectOptionsActivities,
} from "../../store/services/helpers/functions";
import {
    isDurationError,
    isEmpty,
    isEmptyErrorList,
    isPriceError,
} from "../../store/services/helpers/validation";
// components
import Loading from "../../components/others/Loading";
import ButtonSubmit from "../../components/buttons/ButtonSubmit";
import TutorialButtonBack from "./TutorialButtonBack";
import { Form, Dropdown, Grid } from "semantic-ui-react";

// component
function ActivityCreateForm({ backTutorial, tutorialFinish }) {
    // state
    const [name, setName] = useState("");
    const [activity, setActivity] = useState("");
    const [activityPlace, setActivityPlace] = useState("");
    const [duration, setDuration] = useState("");
    const [durationType, setDurationType] = useState(
        durationOptions.length.toString()
    );
    const [price, setPrice] = useState("");
    const [currency, setCurrency] = useState("1");
    const [isLoading, setIsLoading] = useState(false);
    const [activities, setActivities] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [country, setCountry] = useState([]);
    const [processing, setProcessing] = useState(false);

    // fetch data - active categories
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const activitiesData = await activityService.getActivities(
                "?status=true"
            );
            setActivities(activitiesData.response);

            const result = await addressService.getAddresses();
            if (result[0]) {
                setActivityPlace(result[0].id);
                setAddresses(result);
            }

            const profile = await userProfileService.getUserProfile();
            if (profile[0]) {
                setCountry(profile[0].country);

                for (let i = 0; i < currencyOptions.length; i++) {
                    if (
                        currencyOptions[i].text === profile[0].country.currency
                    ) {
                        setCurrency(currencyOptions[i].value.toString());
                        break;
                    }
                }
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };
        fetchData();
    }, []);

    // set options for dropdown form field - activities, addresses
    const activitiesOptions = setSelectOptionsActivities(activities, country);
    const addressesOptions = setSelectOptions(addresses);

    // handle currency options
    const currencyOptionsCopy = [];
    for (let i = 0; i < currencyOptions.length; i++) {
        if (currencyOptions[i].text === country.currency) {
            currencyOptionsCopy.push(currencyOptions[i]);
            break;
        }
    }

    /** component functions **/

    const handleActivityChange = (e, data) => {
        setActivity(data.value);
    };

    const handleAddressChange = (e, data) => {
        setActivityPlace(data.value);
    };

    const handleDurationTypeChange = (e, data) => {
        setDurationType(data.value);
    };

    const handleCurrencyChange = (e, data) => {
        setCurrency(data.value);
    };

    const handleRegister = async (event) => {
        event.preventDefault();
        setProcessing(true);

        let formError = false;

        // validate form fields
        if (isDurationError(duration) || isPriceError(price)) {
            formError = true;
        }

        if (!formError) {
            const data = {
                name: name,
                activity: activity,
                duration: duration,
                price: price,
                durationType: durationType,
                currencyType: currency,
                address: parseInt(activityPlace),
                images: [],
                addresses: parseInt(activityPlace)
                    ? [parseInt(activityPlace)]
                    : [],
                mainImage: null,
            };

            if (await activityService.saveCompanyActivity(data)) {
                if (
                    await userProfileService.updateUserProfile({
                        tutorial: true,
                    })
                ) {
                    tutorialFinish();
                    setProcessing(false);
                }
                setProcessing(false);
            } else {
                setProcessing(false);
            }
        }

        setProcessing(false);
    };

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Form onSubmit={handleRegister}>
                            <Form.Field>
                                <Form.Input
                                    required
                                    autoFocus
                                    fluid
                                    label="Názov aktivity"
                                    placeholder="Názov aktivity"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>
                                    Druh aktivity{" "}
                                    <sup style={{ color: "red" }}>*</sup>
                                </label>
                                <Dropdown
                                    fluid
                                    required
                                    width={8}
                                    search
                                    selection
                                    placeholder="Vyhľadaj druh aktivity"
                                    options={activitiesOptions}
                                    onChange={handleActivityChange}
                                    value={activity}
                                />
                                <br />

                                <Form.Select
                                    fluid
                                    label="Miesto konania aktivity"
                                    placeholder="Miesto konania aktivity"
                                    options={addressesOptions}
                                    required
                                    onChange={handleAddressChange}
                                    value={activityPlace}
                                />
                            </Form.Field>
                            <Form.Group>
                                <Form.Input
                                    width={8}
                                    required
                                    fluid
                                    label="Trvanie aktivity"
                                    placeholder="Trvanie aktivity"
                                    type="number"
                                    min="0"
                                    value={duration}
                                    onChange={(e) =>
                                        setDuration(e.target.value)
                                    }
                                    error={
                                        !isEmpty(duration)
                                            ? isDurationError(duration)
                                            : false
                                    }
                                />
                                <Form.Select
                                    width={8}
                                    required
                                    fluid
                                    label="Doba trvania"
                                    placeholder="Doba trvania"
                                    options={durationOptions}
                                    onChange={handleDurationTypeChange}
                                    value={durationType}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Input
                                    width={8}
                                    required
                                    fluid
                                    label="Cena"
                                    placeholder="0.00"
                                    type="number"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    error={
                                        !isEmpty(price)
                                            ? isPriceError(price)
                                            : false
                                    }
                                />
                                <Form.Select
                                    width={8}
                                    required
                                    fluid
                                    label="Mena"
                                    placeholder="Mena"
                                    options={currencyOptionsCopy}
                                    onChange={handleCurrencyChange}
                                    value={currency}
                                />
                            </Form.Group>

                            <Form.Group>
                                <TutorialButtonBack
                                    disabled={processing}
                                    onClick={backTutorial}
                                />
                                <ButtonSubmit
                                    loading={processing}
                                    disabled={
                                        processing ||
                                        !isEmptyErrorList([
                                            name,
                                            activity,
                                            duration,
                                            durationType,
                                            activityPlace,
                                            price,
                                        ])
                                    }
                                    text="Pokračovať"
                                />
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid>
            )}
        </Fragment>
    );
}

export default ActivityCreateForm;
