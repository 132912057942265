const userReducer = (user = {}, action) => {
    switch (action.type) {
        case "ADD_USER":
            return {
                id: action.user.id,
                email: action.user.email,
                firstname: action.user.firstname,
                lastname: action.user.lastname,
                role: action.user.role,
                is_coach: action.user.is_coach,
                is_town: action.user.is_town,
                date_joined: action.user.date_joined,
                tutorial: action.user.tutorial,
                language: action.user.language,
            };
        case "LOGOUT_USER":
            return {};
        default:
            return user;
    }
};

export default userReducer;
