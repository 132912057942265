// Mena
export const currencyOptions = [
    { key: 1, text: "EUR", value: "1" },
    { key: 2, text: "CZK", value: "2" },
    { key: 3, text: "USD", value: "3" },
    { key: 4, text: "RUB", value: "4" },
];

// Typy balíkov
export const bundleOptions = [
    { key: 2, text: "Paušál", value: "2" },
    { key: 1, text: "Podiel z transakcie", value: "1" },
];

// Typy dlžok pre aktivitu
export const activityLengthOptions = [
    { key: 1, text: "Na konrétny čas", value: "1" },
    { key: 2, text: "Na časový interval", value: "2" },
    { key: 3, text: "Bez časového intervalu", value: "3" },
];

// Aktívny/Neaktívny status
export const activeOptions = [
    { key: 1, text: "Aktívny(a)", value: true },
    { key: 2, text: "Neaktívny(a)", value: false },
];

// Planovane opakovanie
export const planningRepeatOptions = [
    { key: 1, text: "Bez opakovania", value: "1" },
    { key: 2, text: "Opakuj denne", value: "2" },
    { key: 3, text: "Opakuj týždenne", value: "3" },
    { key: 4, text: "Opakuj mesačne", value: "4" },
    { key: 5, text: "Opakuj ročne", value: "5" },
    { key: 6, text: "Opakuj PO - PI", value: "6" },
    { key: 7, text: "Opakuj PO, ST, PI", value: "7" },
    { key: 8, text: "Opakuj UT, ST", value: "8" },
];

// Typy poskytovateľov
export const providerOptions = [
    { key: 1, text: "Živnostník", value: "1" },
    { key: 2, text: "Právnická osoba", value: "2" },
];

// Typy dlžok trvania
export const durationOptions = [
    { key: 1, text: "Deň/Dni", value: "1" },
    // { key: 2, text: "Mesiac/Mesiace", value: "2" },
    // { key: 3, text: "Rok/Roky", value: "3" },
    { key: 4, text: "Hodina/Hodín", value: "4" },
    { key: 5, text: "Minúta/Minút", value: "5" },
];

// Jazyky aplikácie
export const languageOptions = [
    { key: 1, text: "Slovak", value: "sk", flag: "sk" },
    { key: 2, text: "Czech", value: "cz", flag: "cz" },
    { key: 3, text: "English", value: "en", flag: "uk" },
    { key: 4, text: "German", value: "de", flag: "de" },
    { key: 5, text: "Russian", value: "ru", flag: "ru" },
    { key: 6, text: "French", value: "fr", flag: "fr" },
    { key: 7, text: "Spanish", value: "es", flag: "es" },
];

// moznosti opakovania - planovanie
export const eventRepeatOptions = [
    { key: 1, text: "Bez opakovania", value: "1" },
    { key: 2, text: "S opakovaním", value: "2" },
];

// Socialne siete
export const socialNetworkOptions = [
    {
        key: "chain",
        text: "Link",
        value: "chain",
        icon: "chain",
    },
    {
        key: "globe",
        text: "Website",
        value: "globe",
        icon: "globe",
    },
    {
        key: "facebook",
        text: "Facebook",
        value: "facebook",
        icon: "facebook",
    },
    {
        key: "facebook messenger",
        text: "Facebook Messenger",
        value: "facebook messenger",
        icon: "facebook messenger",
    },
    {
        key: "google",
        text: "Google",
        value: "google",
        icon: "google",
    },
    {
        key: "instagram",
        text: "Instagram",
        value: "instagram",
        icon: "instagram",
    },
    {
        key: "linkedin",
        text: "LinkedIn",
        value: "linkedin",
        icon: "linkedin",
    },
    {
        key: "skype",
        text: "Skype",
        value: "skype",
        icon: "skype",
    },
    {
        key: "slack",
        text: "Slack",
        value: "slack",
        icon: "slack",
    },
    {
        key: "snapchat",
        text: "Snapchat",
        value: "snapchat",
        icon: "snapchat",
    },
    {
        key: "twitter",
        text: "Twitter",
        value: "twitter",
        icon: "twitter",
    },
    {
        key: "vimeo",
        text: "Vimeo",
        value: "vimeo",
        icon: "vimeo",
    },
    {
        key: "youtube",
        text: "YouTube",
        value: "youtube",
        icon: "youtube",
    },
];

// provider role types
export const providerRoleOptions = [
    { key: 1, text: "Poskytovateľ", value: "1" },
    { key: 2, text: "Kouč", value: "2" },
    { key: 3, text: "Mesto", value: "3" },
];
