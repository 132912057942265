import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// store 
import { API } from '../../../../../store/config';
import { commonService } from '../../../../../store/services/ServiceCommon';
import { routes } from "../../../../../store/routes";
// components
import { DateInput } from "semantic-ui-calendar-react";
import {  Icon, Divider, Grid, Form, Button, Table, Segment } from "semantic-ui-react";
import HeaderTitle from "../../../../../components/others/HeaderTitle";
import SuperModal from '../../../../../components/modals/SuperModal';
import ProvidersModalView from "./ProvidersModalView";
import moment from "moment";

// component
function CoachesListView() {
    const user = useSelector((state) => state.user);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false)
    // eslint-disable-next-line
    const [generated, setGenerated] = useState(false)
    const [stats, setStats] = useState([])
    const [years, setYears] = useState([])
    const [form, setForm] = useState({
        mode: "full",
        date_from: "",
        date_to: "",
        year: moment().format("YYYY")
    })

    useEffect( () => {
        const limit = 10
        const current = form.year.toString()
        let yearList = [{ key: current, value: current, text: current }]
        let currentYearPast = current
        for (let i = 0; i < limit; i++) {
            currentYearPast = parseInt(currentYearPast) - 1
            yearList.push({ key: currentYearPast.toString(), value: currentYearPast.toString(), text: currentYearPast.toString() })
        }
        yearList.sort((a, b) => (a.value > b.value) ? 1 : -1)
        setYears(yearList)
    }, [form.year])

    useEffect( () => {
        let firstDate = ""
        let lastDate = ""
        let year = parseInt(form.year) + 1
        
        if(['Q1', 'Q2', 'Q3', 'Q4'].includes(form.mode)){
            if( form.mode === "Q1" ){
                firstDate = moment(year + "-01-01", ).subtract(4, 'quarter').startOf('quarter').format("YYYY-MM-DD")
                lastDate = moment(year + "-01-01").subtract(4, 'quarter').endOf('quarter').format("YYYY-MM-DD")
            }

            if( form.mode === "Q2" ){
                firstDate = moment(year + "-01-01").subtract(3, 'quarter').startOf('quarter').format("YYYY-MM-DD")
                lastDate = moment(year + "-01-01").subtract(3, 'quarter').endOf('quarter').format("YYYY-MM-DD")
            }

            if( form.mode === "Q3" ){
                firstDate = moment(year + "-01-01").subtract(2, 'quarter').startOf('quarter').format("YYYY-MM-DD")
                lastDate = moment(year + "-01-01").subtract(2, 'quarter').endOf('quarter').format("YYYY-MM-DD")
            }

            if( form.mode === "Q4" ){
                firstDate = moment(year + "-01-01").subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD")
                lastDate = moment(year + "-01-01").subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD")
            }

            setForm(prevState => ({ ...prevState, date_from: firstDate, date_to: lastDate }))
        }
        
        if( form.mode === "custom" ){
            firstDate = moment().startOf('month').format("DD.MM.YYYY")
            lastDate = moment().endOf('month').format("DD.MM.YYYY")
            setForm(prevState => ({ ...prevState, date_from: firstDate, date_to: lastDate }))
        }
    }, [form.mode, form.year])

    const handleSubmit = async () => {
        setIsLoading(true)
        let params = ""
        let dateFrom = form.date_from
        let dateTo = form.date_to

        if( form.mode === "custom" ){
            dateFrom = moment(moment(dateFrom, "DD.MM.YYYY")).format("YYYY-MM-DD") 
            dateTo = moment(moment(dateTo, "DD.MM.YYYY")).format("YYYY-MM-DD") 
        }

        if( form.date_from !== "" && form.date_to !== "" && form.mode !== "full" ){
            params = "?from=" + dateFrom + "&to=" + dateTo
        }

        const request = await commonService.getStats(API.GET_STATS + "coaches/" + params)
        if( request.status === 200 ){
            setStats(request.response)
        }
        setIsLoading(false)
        setGenerated(true)
    }

    const displayEarned = (coach) => {
        return parseFloat(parseFloat(coach.network.earned) - parseFloat(coach.network.refunded)).toFixed(2)
    }

    // template
    return (
        <>
            { user.role === "Admin" && 
                <Fragment>
                    <HeaderTitle title={"Administratíva - štatistika koučov"} />
                    <section>
                        <div style={{ marginTop: "0.5rem" }}>
                            <Link to={routes.DASHBOARD}>
                                <span style={{ fontSize: "1.2rem", color: "black" }}><Icon name="arrow alternate circle left"/> Návrat na prehľad</span>
                            </Link>
                        </div>

                        <Grid>
                            <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                                <Grid.Column>
                                    <Divider/>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group>
                                            <Form.Select 
                                                label="Režim"
                                                value={form.mode}
                                                onChange={(e, { value }) => setForm(prevState => ({...prevState, mode: value}))}
                                                options={[
                                                    { key: 1, value: "full", text: "Celá história"},
                                                    { key: 2, value: "Q1", text: "Q1"},
                                                    { key: 3, value: "Q2", text: "Q2"},
                                                    { key: 4, value: "Q3", text: "Q3"},
                                                    { key: 5, value: "Q4", text: "Q4"},
                                                    { key: 6, value: "custom", text: "Vlastný rozsah"},
                                                ]}
                                            />

                                            { ['Q1', 'Q2', 'Q3', 'Q4'].includes(form.mode) &&
                                                <Form.Select
                                                    search
                                                    label="Vyberte rok"
                                                    value={form.year}
                                                    onChange={(e, { value }) => setForm(prevState => ({...prevState, year: value}))}
                                                    options={years}
                                                />
                                            }

                                            { form.mode === "custom" && 
                                                <>
                                                    <DateInput
                                                        clearable
                                                        localization="sk"
                                                        label="Dátum od"
                                                        placeholder="DD.MM.YYYY"
                                                        dateFormat="DD.MM.YYYY"
                                                        value={form.date_from}
                                                        onChange={(e, { value }) => setForm({...form, date_from: value})}
                                                        iconPosition="right"
                                                        animation="none"
                                                        closable
                                                        hideMobileKeyboard
                                                    />
                                                    <DateInput
                                                        clearable
                                                        localization="sk"
                                                        label="Dátum do"
                                                        placeholder="DD.MM.YYYY"
                                                        dateFormat="DD.MM.YYYY"
                                                        value={form.date_to}
                                                        onChange={(e, { value }) => setForm({...form, date_to: value})}
                                                        iconPosition="right"
                                                        animation="none"
                                                        closable
                                                        hideMobileKeyboard
                                                    />
                                                </>
                                            }

                                            <Form.Field style={{ marginTop: "1.8rem" }}>
                                                <Button disabled={isLoading} style={{ background: "var(--primary)", color: "var(--white)" }}>Generuj report</Button>
                                            </Form.Field>
                                        </Form.Group>
                                        { (form.mode !== "full" && form.mode !== "custom") &&
                                        <>
                                            Zvolené obdobie: {" "}
                                            <strong>
                                                { moment(form.date_from).format("DD.MM.YYYY") } - { moment(form.date_to).format("DD.MM.YYYY") }
                                            </strong>
                                        </>
                                        }
                                    </Form>
                                    <Divider/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        { !generated && !isLoading &&
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column textAlign="center" style={{ fontSize: "1.2rem" }}>
                                        Pre generovanie reportu si nastavte filter a zvoľte možnosť <strong>Generuj report</strong>.
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }

                        <Segment loading={isLoading} style={{ border: "none", shadow: "none", boxShadow: "none", background: "transparent", padding: 0 }}>
                            { !isLoading && 
                                <Table striped unstackable style={{ overflow: "auto" }}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{ width: "30%" }}>Kouč</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="left">Veľkosť siete</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="left">Zisk (EUR)</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="left">Predané / Reklamované</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="left">Miera reklamovanosti</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="left">Miera získavania zákaznikov</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Akcie</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        { stats.length === 0 && 
                                            <Table.Row> 
                                                <Table.Cell colSpan={7} style={{ textAlign: "center", fontWeight: "bold" }}>
                                                    Neboli najdené žiadne záznamy
                                                </Table.Cell>
                                            </Table.Row>
                                        }

                                        {stats.map((coach) => (
                                            <Table.Row>
                                                <Table.Cell> { coach.name } </Table.Cell>
                                                <Table.Cell> { coach.network.network_size } </Table.Cell>
                                                <Table.Cell> { displayEarned(coach) } </Table.Cell>
                                                <Table.Cell> { (parseInt(coach.network.activity_sold) - parseInt(coach.network.activity_refunded)) } / { coach.network.activity_refunded } </Table.Cell>
                                                <Table.Cell> { coach.network.complaint_rate + "%" } </Table.Cell>
                                                <Table.Cell> { coach.network.customer_gain_rate + "%" } </Table.Cell>
                                                <Table.Cell textAlign="center"> 
                                                    <SuperModal
                                                        closeIcon
                                                        size="large"
                                                        trigger={
                                                            <Icon style={{ cursor: "pointer" }} name="eye"/> 
                                                        }
                                                        content={<ProvidersModalView filters={form} coach={coach.user_id}/>}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            }
                        </Segment>
                    </section>
                </Fragment>
            }
        </>
        
    );
}

export default CoachesListView;
