// react
import React, { useEffect } from "react";
// components
import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import Banner from "../../components/general/Banner";
// sections
import ContactInfo from "./ContactInfo";
import Map from "../../components/others/Map";
// others
import { GAPageView, initGA } from "../../index";

// component
function Contact() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <div className="website">
            <Navbar linkBack="/" />
            <Banner src="/images/public/contact/banner.png" />
            <ContactInfo />
            <Map
                current={true}
                destination={{
                    lat: 48.9369284,
                    lng: 21.9084632,
                }}
                path={true}
                navigation={true}
            />
            <Footer />
        </div>
    );
}

export default Contact;
