// react
import React from "react";
import { Link } from "react-router-dom";
// components
import { Button } from "semantic-ui-react";

// component
function ButtonIconEdit({ link }) {
    // template
    return <Button as={Link} to={link} circular color="grey" icon="pencil" />;
}

export default ButtonIconEdit;
