// react
import React, { Fragment, useState } from "react";
import { googleMapsAPIKEY } from '../../store/config';
// components
import Loading from "../../components/others/Loading";
import {
    GoogleMap,
    LoadScript,
    Marker,
    DistanceMatrixService,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import { Icon, Grid, Header, Segment, Button } from "semantic-ui-react";

const mapStyles = {
    height: "40vh",
    width: "100%",
};

const defaultOptions = {
    zoomControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    mapTypeControl: false,
    scaleControl: false,
    styles: [
        {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
        },
    ],
};

// component
function ActivityDetailMap({ latitude, longitude }) {
    // state
    const [activityPosition] = useState({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
    });
    const [userPosition, setUserPosition] = useState({});
    const [travelDistance, setTravelDistance] = useState("");
    const [walkDuration, setWalkDuration] = useState("");
    const [carDuration, setCarDuration] = useState("");
    const [response, setResponse] = useState(null);

    const success = (position) => {
        setUserPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        });
    };

    const searchMyLocation = () => {
        if ("geolocation" in navigator) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    if (
                        result.state === "granted" ||
                        result.state === "prompt"
                    ) {
                        navigator.geolocation.getCurrentPosition(success);
                    } else {
                        alert("Vašu polohu nebolo možné nastaviť!");
                    }
                });
        } else {
            alert(
                "Meranie polohy nie je dostupné v tomto prehliadači. Spustite stránku v inom prehliadači."
            );
        }
    };

    // template
    return (
        <Segment style={{ marginTop: "1rem" }}>
            {userPosition.lat ? (
                <Button
                    as="a"
                    href={
                        "https://www.waze.com/sk/livemap/directions?navigate=yes&from=" +
                        userPosition.lat +
                        "," +
                        userPosition.lng +
                        "&to=" +
                        activityPosition.lat +
                        "," +
                        activityPosition.lng +
                        ""
                    }
                    style={{ width: "100%" }}
                    target="_blank"
                    size="medium"
                    color="green"
                >
                    Navigovať na aktivitu
                </Button>
            ) : (
                ""
            )}
            <Header as="h4">
                <Icon name="map outline" />
                Mapa {userPosition.lat ? `| ${travelDistance} od teba` : "- m"}
            </Header>

            <Grid>
                <Grid.Row columns={2} centered>
                    <Grid.Column>
                        <Icon.Group>
                            <Icon name="male" />
                            {userPosition.lat ? walkDuration : "- min"}
                        </Icon.Group>
                    </Grid.Column>
                    <Grid.Column>
                        <Icon.Group>
                            <Icon name="car" />
                            {userPosition.lat ? carDuration : "- min"}
                        </Icon.Group>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <LoadScript
                            googleMapsApiKey={googleMapsAPIKEY}
                            preventGoogleFontsLoading={false}
                            loadingElement={<Loading />}
                        >
                            <GoogleMap
                                mapContainerStyle={mapStyles}
                                zoom={15}
                                center={activityPosition}
                                options={defaultOptions}
                                onLoad={searchMyLocation}
                            >
                                {activityPosition.lat ? (
                                    <Marker
                                        icon="http://maps.google.com/mapfiles/ms/icons/pink-dot.png"
                                        position={activityPosition}
                                        title="Aktivita"
                                        draggable={false}
                                    />
                                ) : null}

                                <Fragment>
                                    {activityPosition.lat &&
                                        userPosition.lat &&
                                        response === null && (
                                            <DirectionsService
                                                options={{
                                                    destination: {
                                                        lat:
                                                            activityPosition.lat,
                                                        lng:
                                                            activityPosition.lng,
                                                    },
                                                    origin: userPosition,
                                                    travelMode: "DRIVING",
                                                }}
                                                callback={(response) => {
                                                    if (response !== null) {
                                                        setResponse(response);
                                                    }
                                                }}
                                            />
                                        )}

                                    {response !== null && (
                                        <DirectionsRenderer
                                            options={{
                                                directions: response,
                                            }}
                                        />
                                    )}

                                    {userPosition.lat &&
                                    travelDistance === "" &&
                                    carDuration === "" &&
                                    walkDuration === "" ? (
                                        <Fragment>
                                            <DistanceMatrixService
                                                options={{
                                                    destinations: [
                                                        activityPosition,
                                                    ],
                                                    origins: [userPosition],
                                                    travelMode: "DRIVING",
                                                }}
                                                callback={(response) => {
                                                    if (response !== null) {
                                                        setTravelDistance(
                                                            response.rows[0]
                                                                .elements[0]
                                                                .distance.text
                                                        );
                                                        setCarDuration(
                                                            response.rows[0]
                                                                .elements[0]
                                                                .duration.text
                                                        );
                                                    }
                                                }}
                                            />
                                            <DistanceMatrixService
                                                options={{
                                                    destinations: [
                                                        activityPosition,
                                                    ],
                                                    origins: [userPosition],
                                                    travelMode: "WALKING",
                                                }}
                                                callback={(response) => {
                                                    if (response !== null) {
                                                        setWalkDuration(
                                                            response.rows[0]
                                                                .elements[0]
                                                                .duration.text
                                                        );
                                                    }
                                                }}
                                            />
                                        </Fragment>
                                    ) : null}
                                </Fragment>
                            </GoogleMap>
                        </LoadScript>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default ActivityDetailMap;
