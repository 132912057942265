// react
import React from "react";

// components
import AboutUsSection from "./AboutUsSection";
import { Grid, Header, Icon } from "semantic-ui-react";


// component
function AboutUsInfo() {
    // template
    return (
        <Grid className="section" relaxed>
            <Grid.Row columns={1} centered>
                <h2>
                    Chceme od teba,
                </h2>

                <Header as="h4" style={{ marginTop: -10, opacity: 0.5 }}>
                    aby si
                </Header>
                
                <Icon name="angle down" size="big" color="pink" />
            </Grid.Row>

            <Grid.Row columns={3} centered>
                <AboutUsSection
                    icon="eye"
                    header="Objavil nové miesta"
                    text="Buď turistom vo vlastnom meste. Objav čarovné zákutia, spoznaj históriu a zabudni na stereotyp. Preskúmaj každý kút tvojho mesta, objav detaily, aj nádherné výhľady či pamiatky. Nechaj sa vtiahnuť príbehmi, o ktorých si ani len netušil. Objavuj ich s nami."
                />
                <AboutUsSection
                    icon="child"
                    header="Bol aktívny"
                    text="Nájdi si nové hobby, alebo svoj deň urob krajším. Aktívnejší život ti prinesie nové zážitky, priateľstvá a pozitívne myslenie. Buď aktívny a objav v sebe skrytý talent, ukáž sám sebe, že nuda nie je to, čo chceš. "
                />
                <AboutUsSection
                    icon="smile"
                    header="Športoval"
                    text="Tisíce výhovoriek, prečo nešportovať už neobstoja. Zdvihni svoje sebavedomie, vytvaruj svoju postavu, zlepši si kondíciu a zabudni na negatívnu náladu. Objav endorfíny v nespočetnom množstve športových aktivít. Prekonaj sám seba a buď aktívny. "
                />
            </Grid.Row>


            <Grid.Row columns={2} centered 
                style={{ marginTop: '3rem' }}
            >
                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                >
                    <img src="/images/icons/design_dot.png" alt="Dot" />
                    <br />

                    <h2>
                        O nás
                    </h2>
                    <p>
                    Activity Point je aplikácia pre  ľudí, ktorých nebaví sedieť doma. Vďaka nej si vieš vyhľadať aktivity na mieste, kde sa práve nachádzaš. Stačí si ju len stiahnuť a využívať. Chceme, aby si bol aktívny a svoj voľný čas obohatil o možnosti športovania, relaxácie, objavovania či vzdelávania. Aplikácia ti jednoducho ukáže aktivity a ty si z nich vyberieš. Vieš sa na nich nielen rovno prihlásiť, ale aj si zakúpiť lístky/vstupenky.
                    </p>
                </Grid.Column>

                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                    textAlign={"center"}
                    style={{height: 'auto'}}
                >
                    <div style={{minHeight: '16rem', width: '100%!important', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img src="/images/logos/logo_dark_version.png" alt="Logo"
                            width="300"
                         />     
                    </div>
                </Grid.Column>
            </Grid.Row>


            <Grid.Row columns={2} centered 
                style={{ marginTop: '3rem' }}
            >
                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                >
                    <img src="/images/icons/design_dot.png" alt="Dot" />
                    <br />

                    <h2>
                        Jednoduchšie, rýchlejšie a vopred
                    </h2>
                    <p>
                        Touto víziou sme sa začali zaoberať už v roku 2017. Dnes uvádzame na trh aplikáciu Activity Point, ktorá poskytuje úplne nové postupy predaja, registrácie a objednávania produktu. 
                        <br /><br />
                        Aplikácia Activity Point digitalizuje aktivity, ktoré ponúkajú tretie strany a vytvára zaujímavé synergie. Spája nami vyvinutý rezervačný a účtovný systém s online platobným systémom a navigáciou. Zákazník si všetko nakúpi online a nemusí stáť v radoch. Svoj výlet si vie vopred, rýchlo a jednoducho naplánovať. Možno už zajtra pôjde na svoju obľúbenú kávičku, odohrá svoj prvý futbalový zápas či zájde na masáž.
                    </p>
                </Grid.Column>

                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                >
                    <img src="/images/icons/design_dot.png" alt="Dot" style={{opacity: 0}} />

                    <h2>
                        Voľne dostupné aktivity
                    </h2>
                    <p>
                        Našim zámerom je, aby sme prostredníctvom programu AP Solidarity zobrazovali aj voľne dostupné aktivity v rámci miest a obcí na Slovensku. Výhodou je, že v našej aplikácii nebudú len aktivity od klientov, ale všetky, ktoré sú bežne dostupné a bude sa môcť na nich záujemca prihlásiť. Cez besedu v knižnici, prednášky v kultúrnych strediskách až po športové aktivity, ktoré organizuje mesto alebo obec. 
                    </p>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} 
                style={{ marginTop: '4rem' }}
                centered
            >
                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                    textAlign={"center"}
                    style={{height: 'auto'}}
                >
                    <img src="/images/public/about/about_us_connect.jpg" alt="Connect" 
                        style={{
                            borderRadius: '4px'
                        }} 
                    />
                </Grid.Column>

                <Grid.Column
                    mobile={12}
                    tablet={6}
                    computer={6}
                    style={{marginTop: '1rem'}}
                >
                    <h2>
                        Jazyk 21. storočia
                    </h2>
                    <p>
                        Náš život sa preniesol do online sféry, kde si z pohodlia domova vieme vybaviť všetko. Na druhej strane ľudia potrebujú aktivity a podniky zase zákazníkov. Je dôležité, aby majitelia prevádzok po otvorení po pandémii ponúkali nové aktivity novým zákazníkom, a to jazykom 21. storočia. Aktivít sa dá pripraviť veľa, len musíte vedieť ovplyvňovať ich nastavenie tak, ako to chce váš zákazník.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default AboutUsInfo;
