import { API } from "../config";
import { APIRequest } from "./helpers/requests";

/*
 * Service functions
 */
export const paymentService = {
    getPaymentInitForEvent,
    validatePayment,
    getInvoicesForUser,
    getInvoiceById,
    getDigitalProductsForUser,
    getInvoicesForProvider,
    getDigitalProductsForProvider,
    getProfits,
    getRewards,
};

async function getPaymentInitForEvent(data) {
    return await APIRequest({
        url: API.PAYMENT_INIT_FOR_EVENTS,
        method: "POST",
        data: data,
        private: true,
    });
}

async function validatePayment(uri_params) {
    return await APIRequest({
        url: API.PAYMENT_FINISH + "?" + uri_params,
        method: "GET",
        data: {},
        private: false,
    });
}

async function getInvoicesForUser(params) {
    return await APIRequest({
        url: API.GET_INVOICES_FOR_USERS + params,
        method: "GET",
        data: {},
        private: true,
    });
}

async function getDigitalProductsForUser(params) {
    return await APIRequest({
        url: API.GET_DIGITAL_PRODUCTS_FOR_USER + params,
        method: "GET",
        data: {},
        private: true,
    });
}

async function getDigitalProductsForProvider(params) {
    return await APIRequest({
        url: API.GET_DIGITAL_PRODUCTS_FOR_PROVIDERS + params,
        method: "GET",
        data: {},
        private: true,
    });
}

async function getInvoiceById(id) {
    return await APIRequest({
        url: API.GET_INVOICE_BY_ID + id,
        method: "GET",
        data: {},
        private: true,
    });
}


async function getProfits(params) {
    return await APIRequest({
        url: API.GET_PROFITS + params,
        method: "GET",
        data: {},
        private: true,
    });
}

async function getRewards(params) {
    return await APIRequest({
        url: API.GET_REWARDS + params,
        method: "GET",
        data: {},
        private: true,
    });
}

async function getInvoicesForProvider(params) {
    return await APIRequest({
        url: API.GET_INVOICES_FOR_PROVIDERS + params,
        method: "GET",
        data: {},
        private: true,
    });
}
