// react
import React, { useState, useEffect } from "react";
// store
import { countryService } from "../../../../store/services/ServiceCountry";
// components
import RequestDetailField from "./RequestDetailField";
import { Header, Modal, Grid, Divider } from "semantic-ui-react";

// component
function RequestDetail({ modalOpen, handleModalClose, request }) {
    // state
    const [country, setCountry] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const result = await countryService.getCountries();

            setCountry(
                result.find((countryItem) => countryItem.id === request.country)
            );
        };

        fetchData();
    }, [request.country]);

    // template
    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            closeOnDimmerClick={false}
            closeIcon
            size="tiny"
        >
            <Header icon="info circle" content="Detail žiadosti" />
            <Modal.Content>
                <Grid>
                    <Grid.Row columns={2} width="equal">
                        <RequestDetailField
                            header="Typ poskytovateľa"
                            value={
                                request.providerType === 1
                                    ? `Živnostník`
                                    : `Právnická osoba`
                            }
                        />
                        <RequestDetailField
                            header={`Názov spoločnosti`}
                            value={request.companyName}
                        />
                    </Grid.Row>
                    <Grid.Row columns={2} width="equal">
                        <RequestDetailField
                            header={`IČO`}
                            value={request.id_number}
                        />
                        <RequestDetailField
                            header="Dátum podania žiadosti"
                            value={request.created}
                        />
                    </Grid.Row>
                    <Divider horizontal>Kontaktné informácie</Divider>
                    <Grid.Row>
                        <RequestDetailField
                            header="Meno a priezvisko"
                            value={`${request.firstName} ${request.lastName}`}
                        />
                    </Grid.Row>
                    <Grid.Row columns={2} width="equal">
                        <RequestDetailField
                            header="Email"
                            value={request.email}
                        />
                        <RequestDetailField
                            header="Telefón"
                            value={request.phone}
                        />
                    </Grid.Row>
                    <Grid.Row columns={2} width="equal">
                        <RequestDetailField
                            header="Prezývka"
                            value={request.nickName}
                        />
                        <RequestDetailField
                            header="Krajina"
                            value={country ? country.name : request.country}
                        />
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <RequestDetailField
                            header="Správa k žiadosti"
                            value={request.message}
                        />
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>
    );
}

export default RequestDetail;
