import request from "umi-request";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";
import { APIRequest } from "./helpers/requests";

/*
 * Service functions
 */
export const activityService = {
    getActivities,
    getPublicActivities,
    saveActivity,
    editActivity,
    deleteActivity,
    getCategories,
    getCompanyActivities,
    duplicateCompanyOffer,
    saveCompanyActivity,
    editCompanyActivity,
    deleteCompanyActivity,
    imageUpload,
    getCompanyImages,
    verifyActivity,
};

async function getCategories() {
    let data;
    // create Async - Await request to get all active categories
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_ACTIVE_CATEGORIES, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getActivities(params) {
    return await APIRequest({
        url: API.GET_ACTIVITIES + params,
        method: "GET",
        data: {},
        private: true,
    });
}

async function getPublicActivities(params) {
    let data;
    // create Async - Await request to get all activities
    data = await request(API.GET_ACTIVITIES + params, {
        method: "get",
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function saveActivity(data) {
    let saved;
    // create Async - Await request to save Activity
    let accessToken = await authService.getAccessToken();
    saved = await request(API.CREATE_ACTIVITY, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return saved;
}

async function editActivity(data, id) {
    let updated;
    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_OR_DELETE_ACTIVITY + id, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}

async function deleteActivity(id) {
    let deleted;
    let accessToken = await authService.getAccessToken();
    deleted = await request(API.EDIT_OR_DELETE_ACTIVITY + id, {
        method: "delete",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return deleted;
}

async function getCompanyActivities(params) {
    let data;
    // create Async - Await request to get all active categories
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_COMPANY_ACTIVITIES + params, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function saveCompanyActivity(data) {
    let saved;
    // create Async - Await request to save Activity
    let accessToken = await authService.getAccessToken();
    saved = await request(API.CREATE_COMPANY_ACTIVITY, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return saved;
}

async function editCompanyActivity(data, id) {
    let updated;
    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_OR_DELETE_COMPANY_ACTIVITY + id, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}

async function deleteCompanyActivity(id) {
    let deleted;
    let accessToken = await authService.getAccessToken();
    deleted = await request(API.EDIT_OR_DELETE_COMPANY_ACTIVITY + id, {
        method: "delete",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return deleted;
}

async function duplicateCompanyOffer(data, id) {
    let created;
    // create Async - Await request to Duplicate ActivityOffer
    let accessToken = await authService.getAccessToken();
    created = await request(API.DUPLICATE_COMPANY_OFFER + id, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return false;
        });

    return created;
}

async function verifyActivity(data) {
    return await APIRequest({
        url: API.VERIFY_ACTIVITY,
        method: "POST",
        data: data,
        private: true,
    });
}

async function imageUpload(formData) {
    let saved;
    // create Async - Await request to save Activity
    let accessToken = await authService.getAccessToken();
    saved = await request(API.COMPANY_IMAGES, {
        method: "post",
        data: formData,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return false;
        });

    return saved;
}

async function getCompanyImages() {
    let data;
    // create Async - Await request to get all active categories
    let accessToken = await authService.getAccessToken();
    data = await request(API.COMPANY_IMAGES, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}


