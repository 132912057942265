// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { activityService } from "../../store/services/ServiceActivity";
import { setSelectOptions } from "../../store/services/helpers/functions";
// components
import Navbar from "../../components/general/Navbar";
import InfoCard from "./InfoCard";
import AsyncSelect from 'react-select/async';
// import SearchBar from "./SearchBar"; - may use later
import { Slider } from "react-semantic-ui-range";
import { Modal, Button, Icon, Form, Divider } from "semantic-ui-react";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";

// others
import moment from "moment";
import "moment/min/locales.min";
moment.locale("sk");


// component
function SearchMapOverlay(props) {
    // state
    const [activities, setActivities] = useState([]);
    const [sliderValue, setSliderValue] = useState(props.config.distance);

    const settings = {
        start: props.config.distance,
        min: 100,
        max: 10000,
        step: 100,
        onChange: (value) => {
            props.setDistanceFunc(value);
            setSliderValue(value);
        },
    };

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            const activities = await activityService.getPublicActivities(
                "?status=true"
            );
            setActivities(activities);
        };

        fetchData();
    }, []);

    /** component functions **/

    const handleChange = (selected) => {
        props.setActivityTypesFunc(selected.map(item => item.value));
    };

    // set options for dropdown form field
    const activitiesOptions = setSelectOptions(activities);

    const loadActivityList = async (search) => {
        // const activities = await activityService.getPublicActivities("?status=true&search=" + search);
        // return activities.map(item => ({ value: item.id, label: item.name }));
        const results = activitiesOptions.filter(item => item.text.toLowerCase().includes(search.toLowerCase()))
        return results.map(item => ({ value: item.value, label: item.text }));
    } 

    // template
    return (
        <>
            <div hidden={!props.mapLoaded}>
                <div className="search-bar-block">
                    <Navbar
                        isSearch
                        onSelect={props.autocomplete}
                        searchConfig={props.searchConfig}
                        searching={props.searching}
                        searchMyLocation={props.searchMyLocation}
                        linkBack="/"
                    />

                    {/* <SearchBar
                        onSelect={props.autocomplete}
                        searchConfig={props.searchConfig}
                        searching={props.searching}
                        searchMyLocation={props.searchMyLocation}
                    /> */}
                </div>

                <div className="info-block-wrapper">
                    {props.foundActivities.map((events, idx) => (
                        <Fragment key={props.uuid()}/*key={idx + Math.floor(Math.random() * 100)}*/>
                            {events.length > 0 &&
                                events.map((event) => (
                                    <Fragment key={props.uuid()}/*key={event.id}*/>
                                        {props.selectedMarker.length > 0 ? (
                                            JSON.stringify(props.selectedMarker) ===
                                                JSON.stringify(event.address) && (
                                                <InfoCard
                                                    mapLoaded={props.mapLoaded}
                                                    event={event}
                                                    selectedMarker={
                                                        props.selectedMarker
                                                    }
                                                    usersPosition={
                                                        props.usersPosition
                                                    }
                                                />
                                            )
                                        ) : (
                                            <InfoCard
                                                mapLoaded={props.mapLoaded}
                                                event={event}
                                                selectedMarker={
                                                    props.selectedMarker
                                                }
                                                usersPosition={
                                                    props.usersPosition
                                                }
                                            />
                                        )}
                                    </Fragment>
                                ))
                            }
                        </Fragment>
                    ))}
                </div>

                <Modal
                    centered={false}
                    size="tiny"
                    style={{
                        borderRadius: "0",
                        borderBottom: "4px solid #e03997",
                    }}
                    open={props.loadConfigSettings}
                >
                    <Modal.Content>
                        <Form onSubmit={(event) => props.handleSearch(event)}>
                            <Form.Field widths="equal">
                                <label>
                                    Max. vzdialenosť:
                                    <span style={{ float: "right" }}>
                                        {sliderValue >= 1000
                                            ? sliderValue / 1000 + " km"
                                            : sliderValue + " m"}
                                    </span>
                                </label>
                                <Slider
                                    style={{
                                        trackFill: {
                                            backgroundColor: "#e03997",
                                        },
                                        thumb: {
                                            width: "20px",
                                            height: "20px",
                                        },
                                    }}
                                    settings={settings}
                                />
                            </Form.Field>

                            <Divider />

                            <Form.Group widths="equal">
                                {<DateInput
                                    required
                                    localization="sk"
                                    label="Dátum"
                                    placeholder="YYYY-MM-DD"
                                    dateFormat="YYYY-MM-DD"

                                    minDate={new Date(moment().year(), moment().month(), moment().day() - 1)}
                                    maxDate={moment().add(1, 'years')}

                                    iconPosition="right"
                                    animation="none"
                                    value={props.config.date}
                                    onChange={(_, data) =>
                                        props.setDateFunc(data.value)
                                    }
                                    closable
                                    hideMobileKeyboard
                                />
                                }
                                <TimeInput
                                    required
                                    label="Čas"
                                    placeholder="HH:MM"
                                    timeFormat="24"
                                    iconPosition="right"
                                    value={props.config.time}
                                    onChange={(_, data) =>
                                        props.setTimeFunc(data.value)
                                    }
                                    animation="none"
                                    closable
                                    hideMobileKeyboard
                                />
                            </Form.Group>

                            {/* <Form.Group widths="equal">
                                <Form.Select
                                    multiple
                                    search
                                    selection
                                    clearable
                                    options={activitiesOptions}
                                    label="Kategórie"
                                    placeholder="Vyber aktivity"
                                    value={props.config.activityTypes}
                                    onChange={(_, value) => handleChange(value)}
                                />
                            </Form.Group> */}

                            <label style={{ fontWeight: "bold", fontSize: "0.9rem" }}>Kategórie</label>
                            <AsyncSelect
                                className="react-select search-box"
                                isMulti
                                cacheOptions
                                onChange={ (selected) => handleChange(selected) }
                                value={activitiesOptions.filter(item => props.config.activityTypes.includes(item.value)).map(item => ({ value: item.value, label: item.text }))}
                                defaultOptions={activitiesOptions.map(item => ({ value: item.value, label: item.text }))}
                                loadOptions={(search) => loadActivityList(search)}
                            />

                            <Divider />
                            <Button
                                fluid
                                color="green"
                                style={{ marginLeft: "0", borderRadius: "0" }}
                                onClick={props.searchFunc}
                            >
                                <Icon name="search" /> Uložiť a vyhľadať
                            </Button>
                        </Form>
                    </Modal.Content>
                </Modal>
            </div>
        </>
    );
}

export default SearchMapOverlay;
