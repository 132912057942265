// react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
// components
import RegisterTypeCard from "./RegisterTypeCard";
import { Grid, Header } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../index";

// component
function RegisterTypeSelect() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Grid columns={2} centered>
            <Grid.Row>
                <Header as="h2">Vyber si jednu z možností</Header>
            </Grid.Row>
            <Grid.Row style={{ textAlign: "center" }}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Link to={routes.REGISTER_CLIENT}>
                        <RegisterTypeCard
                            header="Registrovať ako užívateľ"
                            icon="futbol outline"
                            text="Ako užívateľ si vyhľadáš aktivity, ktoré si následne užiješ."
                        />
                    </Link>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Link to={routes.REGISTER_PROVIDER}>
                        <RegisterTypeCard
                            header="Registrovať ako poskytovateľ"
                            icon="calendar alternate outline"
                            text="Ako poskytovateľ môžeš vytvárať,
                                  plánovať a ponúkať aktivity."
                        />
                    </Link>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default RegisterTypeSelect;
