// react
import React from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../../../store/routes";
// components
import { Card, Icon, Header } from "semantic-ui-react";

// component
const MyActivityNew = () => {
    // template
    return (
        <Card
            as={Link}
            to={routes.MY_ACTIVITIES_ADD}
            fluid
            style={{
                marginBottom: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "120px",
                    backgroundImage:
                        "url(/images/general/image_placeholder.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div>
            <Card.Content style={{ textAlign: "center" }}>
                <Icon
                    circular
                    size="big"
                    color="pink"
                    name="plus"
                    style={{ width: "100%" }}
                />
                <Header as="h3" style={{ marginTop: "1rem" }}>
                    Nová aktivita
                </Header>
            </Card.Content>
        </Card>
    );
};

export default MyActivityNew;
