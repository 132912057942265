// react
import React, { Fragment, useEffect } from "react";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import PaymentSearch from "./PaymentSearch";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function PaymentsList() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Prehľad mojich platieb" />
            <section>
                <PaymentSearch />
            </section>
        </Fragment>
    );
}

export default PaymentsList;
