// react
import React from "react";
// components
import { Button, Grid, Header, Icon } from "semantic-ui-react";

// component
function PlanningCalendarHeader({ currentWeekDate, previousWeek, nextWeek }) {
    // template
    return (
        <>
            <Grid.Row only="computer tablet">
                <Grid.Column width={4}>
                    <Button
                        icon
                        labelPosition="left"
                        floated="left"
                        onClick={previousWeek}
                    >
                        <Icon name="left arrow" />
                        Predchádzajúci týždeň
                    </Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Header as="h4" icon textAlign="center">
                        <Icon name="calendar alternate outline" circular />
                        <Header.Content>{currentWeekDate}</Header.Content>
                    </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Button
                        icon
                        labelPosition="right"
                        floated="right"
                        onClick={nextWeek}
                    >
                        Nasledujúci týždeň
                        <Icon name="right arrow" />
                    </Button>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row only="mobile">
                <Grid.Column width={4}>
                    <Button floated="left" onClick={previousWeek}>
                        <Icon name="left arrow" />
                    </Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Header as="h4" icon textAlign="center">
                        <Icon name="calendar alternate outline" circular />
                        <Header.Content>{currentWeekDate}</Header.Content>
                    </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Button floated="right" onClick={nextWeek}>
                        <Icon name="right arrow" />
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </>
    );
}

export default PlanningCalendarHeader;
