// react
import React from "react";
// components
import { Card, Icon } from "semantic-ui-react";

// component
function ActivityCard(props) {
    // template
    return (
        <Card style={{ boxShadow: "none", padding: "0.1rem 1rem" }}>
            <Card.Content>
                <Icon name={props.icon} className="icnBlack" size="big" />

                <Card.Header as="h4">{props.title}</Card.Header>
                <Card.Description>{props.content}</Card.Description>
                {/*
                <div className='btn'>
                    <Button className='btn' labelPosition='left' as="a" href={props.reference}>
                        <div className='learnMoreButton'><b>Learn more</b></div>
                    </Button>
                </div>
                */}
            </Card.Content>
        </Card>
    );
}

export default ActivityCard;
