import request from "umi-request";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";
import { APIRequest } from "./helpers/requests";

/*
 * Service functions
 */
export const categoryService = {
    getCategories,
    getActiveCategories,
    getCategoryById,
    saveCategory,
    editCategory,
    deleteCategory,
};

async function getCategories(params) {
    return await APIRequest({
        url: API.GET_CATEGORIES + params,
        method: "GET",
        data: {},
        private: true,
    });
}

async function getActiveCategories() {
    let data;
    // create Async - Await request to get all active categories
    data = await request(API.GET_ACTIVE_CATEGORIES, {
        method: "get",
        headers: {},
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getCategoryById(id) {
    let data;
    // create Async - Await request to get all categories
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_CATEGORIES + "?id=" + id, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return {};
        });

    return data;
}

async function saveCategory(data) {
    let saved;
    // create Async - Await request to get all categories
    let accessToken = await authService.getAccessToken();
    saved = await request(API.CREATE_CATEGORY, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return saved;
}

async function editCategory(data, id) {
    let updated;

    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_OR_DELETE_CATEGORY + id, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}

async function deleteCategory(id) {
    let deleted;

    let accessToken = await authService.getAccessToken();
    deleted = await request(API.EDIT_OR_DELETE_CATEGORY + id, {
        method: "delete",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return deleted;
}
