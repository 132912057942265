// react
import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
// store
import { routes } from "../../store/routes";
import { authService } from "../../store/services/ServiceAuth";
import { isEmailError, isEmpty } from "../../store/services/helpers/validation";
// components
import ModalResponse from "../../components/modals/ModalResponse";
import { Form, Grid, Header, Message, Segment } from "semantic-ui-react";
// others
import { useTranslation } from "react-i18next";

// component
function LoginForm(props) {
    const { i18n } = useTranslation();
    // state
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage] = useState("Neplatné prihlasovacie údaje!");
    const [isProcessing, setIsProcessing] = useState(false);
    let history = useHistory();

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // validate form fields
        let formError = false;
        if (isEmailError(email)) {
            formError = true;
        }

        if (!formError) {
            const loginResult = await authService.login(
                email,
                password,
                props.dispatch,
                history,
                i18n
            );

            if (loginResult === false) {
                handleErrorOpen();
            } else {
                if (props.onLogin && loginResult.role === "User") {
                    props.onLogin();
                    setIsProcessing(false);
                    return;
                } else if(props.onLogin && loginResult.role !== "User"){
                    props.onLogin("wrong_user");
                    setIsProcessing(false);
                    return;
                }

                if (loginResult.role === "Provider") {
                    history.push(routes.MY_ACTIVITIES);
                    return;
                } else {
                    history.push(routes.DASHBOARD);
                    return;
                }
            }
        }

        setIsProcessing(false);
    };

    const handleErrorOpen = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    // template
    return (
        <Fragment>
            <Grid centered>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Form onSubmit={handleLogin}>
                        <Segment>
                            <Link to={routes.HOME}>
                                <img
                                    src="/images/logos/logo_dark_version.png"
                                    width="60%"
                                    style={{
                                        padding: "1rem",
                                        display: "block",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                    alt="Activity Point logo"
                                />
                            </Link>
                            <Form.Group>
                                <Form.Input
                                    width={16}
                                    size="huge"
                                    required
                                    autoFocus
                                    fluid
                                    label="Email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={
                                        !isEmpty(email)
                                            ? isEmailError(email)
                                            : false
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Input
                                    width={16}
                                    size="huge"
                                    required
                                    fluid
                                    type="password"
                                    label="Heslo"
                                    placeholder="Heslo"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </Form.Group>

                            <Header as="h5">
                                <Link to={routes.PASSWORD_RESET}>
                                    Zabudol si heslo?
                                </Link>
                            </Header>

                            <div style={{ textAlign: "center" }}>
                                <Form.Button
                                    fluid
                                    size="huge"
                                    color="pink"
                                    type="submit"
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        isEmpty(email) ||
                                        isEmpty(password)
                                    }
                                    style={{ margin: "0.5rem auto" }}
                                >
                                    Prihlásiť sa
                                </Form.Button>
                            </div>
                        </Segment>

                        {!props.basketRegistration && (
                            <Message style={{ textAlign: "center" }}>
                                <Header as="h5">
                                    Ešte nemáš konto?
                                    <Link
                                        to={routes.REGISTER}
                                    >{` Registruj sa tu`}</Link>
                                </Header>
                            </Message>
                        )}
                    </Form>
                </Grid.Column>
            </Grid>

            <ModalResponse
                open={errorOpen}
                onClose={handleErrorClose}
                icon="warning circle"
                header="Chyba"
                message={errorMessage}
                onClick={handleErrorClose}
            />
        </Fragment>
    );
}

export default connect()(LoginForm);
