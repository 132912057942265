import React /*, { useState }*/ from "react";

// components
import { Grid, Button, Header, Container } from "semantic-ui-react";
// import APSolidarityInfoSection from "./APSolidarityInfoSection";
// import APSolidarityForm from './APSolidarityForm';

export default function APSolidarityInfo({ setContacting }) {
    return (
        <Container>
            <Grid className="section" relaxed>
                <Grid.Row>
                    <Grid.Column id="about-activitypoint">
                        <Header
                            as="h2"
                            textAlign="center"
                            style={{ fontSize: "2.5rem", marginTop: "2rem" }}
                        >
                            Ako funguje ActivityPoint?
                        </Header>
                        {/* <p style={{ textAlign: "center", marginTop: "3rem", marginBottom: "3rem", fontSize: "1.5rem" }}>
                            ActivityPoint predstavuje mapu zaujímavých objektov a miest po celkom Slovensku, <br/>
                            ktoré sa oplatí navštíviť! Sú to historické pamiatky, parky, detské ihriská, prírodné krásy <br/>
                            a všetky ostatné miesta vhodné pre aktívny oddych. Do mapy viete jednoducho pridať aj miesta <br/>
                            vo vašom meste či obci a prilákať tak nových turistov. 
                        </p> */}
                        <p
                            style={{
                                textAlign: "center",
                                marginTop: "3rem",
                                marginBottom: "3rem",
                                fontSize: "1.5rem",
                            }}
                        >
                            Webová platforma ActivityPoint je primárne určená
                            návštevníkom miest a obcí, ktorí nepoznajú ponuku
                            aktivít v regióne.
                            <br />
                            <br />
                            ActivityPoint predstavuje mapu bodov v okolí
                            návštevníka.
                            <br />
                            <br />
                            Návštevník je jednoduchým spôsobom, bez potreby
                            registrácie informovaný, čo zaujímavé a hodnotné
                            región ponúka. <br />
                            <br />
                            Body zobrazené na mape, znázorňujú pohybové,
                            športové, edukačné, kultúrne a historické aktivity.{" "}
                            <br />
                            <br />
                            Mapa aktivít šetrí časvyhľadávania a umožňuje okrem
                            podrobného informovania aj navigáciu k jednotlivým
                            bodom. <br />
                        </p>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                style={{ width: "70%" }}
                                src="/images/public/activity/activitypoint-img-1.png"
                                alt="#ACTIVITYPOINT-1"
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        {/* <Header as="h2"textAlign="center" style={{ fontSize: "2.5rem", marginTop: "3rem" }}>
                            Zviditeľnite svoje mesto/obec a <br/> začnite zbierať príspevky
                        </Header>
                        <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
                            Spokojní návštevníci tiež vedia priamo cez ich mobilné zariadenia jednoducho <br/> a transparentne 
                            finančne prispieť vašej obci na jej ďalší rozvoj a budovanie. 
                        </p> */}
                        <Header
                            as="h2"
                            textAlign="center"
                            style={{ fontSize: "2.5rem", marginTop: "3rem" }}
                        >
                            Dajte hodnotu miestam vo svojom meste/obci
                        </Header>
                        <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
                            Vybudovali ste nové ihrisko? Otvorili nový skatepark
                            ? Alebo máte jedinečné miesto vo svojom regióne,
                            ktoré nájde návštevník len u vás ? Do mapy
                            ActivityPoint vieme všetky tieto miesta pridať v
                            podobe bodov na mape, čo umožňuje mestu alebo obci
                            digitálne prezentovať to najzaujímavejšie čo ponúka
                            a to 24 hodín denne, 7 dní v týždni, 365 dní v roku.
                            <br />
                            <br />
                            ActivityPoint je nástroj na úsporu finančných
                            prostriedkov ekologickou formou, ktoré by inak boli
                            vynaložené na štandardné formy prezentácie v
                            tlačovinách a bilboardoch.
                            <br />
                            <br />
                            Všetko úsilie spojené s registrovaním, mapovaním,
                            pridávaním bodov do mapy, leží na pleciach
                            ActivityPoint, vašu administratívu nezaťažíme.
                        </p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2} style={{ marginTop: "4rem" }} centered>
                    <Grid.Column
                        mobile={12}
                        tablet={6}
                        computer={6}
                        style={{ marginTop: "4rem" }}
                    >
                        {/* <h2>
                            Registrácia obce/mesta
                        </h2>
                        <p>
                        Registrácia je bezplatná. Každé mesto alebo obec môže zdarma registrovať viacero objektov vo svojej samospráve a začať zbierať príspevky. 
                        S registráciou Vám radi pomôžeme. 
                        Vyplňte nezáväzný registračný formulár a odpovieme Vám na všetky prípadné otázky.
                        </p> */}
                        <h2>Koľko to stojí peňazí ?</h2>
                        <p>
                            Cena 1 bodu zobrazeného na mape predstavuje 29,90 €
                            (bez DPH)/ mesiac. <br />
                            <br />
                            Vyplňte nezáväzný kontaktný formulár a my sa vám
                            ozveme.
                        </p>
                        <Button
                            color="pink"
                            style={{
                                marginTop: "1rem",
                                marginBottom: "2rem",
                                padding: "1.2rem 2rem",
                            }}
                            onClick={() =>
                                setContacting(
                                    (contactingPrev) => !contactingPrev
                                )
                            }
                        >
                            {/* Nezáväzná registrácia obce/mesta */}
                            Nezáväzný registračný formulár
                        </Button>
                    </Grid.Column>

                    <Grid.Column
                        mobile={12}
                        tablet={6}
                        computer={6}
                        textAlign={"center"}
                        style={{ height: "auto" }}
                    >
                        <img
                            src="/images/public/activity/activitypoint-side-image-apsolidarity.jpg"
                            alt="#ACTIVITYPOINT"
                            style={{
                                borderRadius: "4px",
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/* <APSolidarityForm 
                opened={contacting}
                setOpenedFunc={setContacting}
            /> */}
        </Container>
    );
}
