import request from "umi-request";
import { API } from "../config";

/*
 * Service functions
 */
export const activationService = {
    getUserActivation,
};

async function getUserActivation(userId, token) {
    let activated;
    // create Async - Await request
    activated = await request(
        API.GET_USER_ACTIVATION + userId + "/" + token + "/",
        {
            method: "get",
        }
    )
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 403) {
                    return true;
                }
            } else {
                return false;
            }
        });

    return activated;
}
