import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
// store
import { authService } from "../../../../store/services/ServiceAuth";
import { paymentService } from "../../../../store/services/ServicePayment";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import { Form, Button, Grid, Card, Divider } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
// import { FlexRow, FlexItem } from '../../../../components/tables/FlexTable'
import RewardsTable from './RewardsTable';
import GenerateRewardForm from './GenerateRewardForm';
import SuperModal from '../../../../components/modals/SuperModal';

const Rewards = () => {
    authService.useIsAdmin();

    const [isLoading, setIsLoading] = useState(true)
    const [filters, setFilters] = useState({
        from: moment().startOf('month').format("DD.MM.YYYY"),
        to: moment().endOf('month').format("DD.MM.YYYY"),
        type: "",
        providers: [],
    })
    const [rewards, setRewards] = useState([])
    const [view, setView] = useState(1)

    async function fetchRewards(){
        setIsLoading(true);

        let params = "?type=" + view
        if(filters.from) params += `&from=${moment(filters.from, "DD.MM.YYYY").format("YYYY-MM-DD")}`
        if(filters.to) params += `&to=${moment(filters.to, "DD.MM.YYYY").format("YYYY-MM-DD")}`

        const request = await paymentService.getRewards(params)

        if(request.status === 200){
            setRewards(request.response)
        }

        setIsLoading(false);
    }

    const handleFilter = async () => {
        fetchRewards()
    }

    useEffect(() => {
        fetchRewards()
        // eslint-disable-next-line
    }, [view])

    return (
        <Fragment>
            <HeaderTitle title="Prehľad odmien" />
            <section>
                <Grid style={{ marginBottom: "1rem" }}>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Card className={ view === 1 ? "active-card" : "" } onClick={ () => setView(1) } fluid style={{ height: "5rem", cursor: "pointer", fontWeight: "bold" }}>
                                <Card.Content textAlign="center">
                                    Odmeny - Zadávateľ
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column>
                            <Card className={ view === 2 ? "active-card" : "" } onClick={() => setView(2)} fluid style={{ height: "5rem", cursor: "pointer", fontWeight: "bold" }}>
                                <Card.Content textAlign="center">
                                    Odmeny - Kouč
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid>
                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column computer={8} tablet={16} mobile={16} >
                            <Form onSubmit={handleFilter}>
                                <Form.Group widths="equal">
                                    <DateInput
                                        fluid
                                        clearable
                                        localization="sk"
                                        label="Dátum od"
                                        placeholder="DD.MM.YYYY"
                                        dateFormat="DD.MM.YYYY"
                                        value={filters.from}
                                        onChange={(e, { value }) => setFilters({...filters, from: value})}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                    />
                                    <DateInput
                                        fluid
                                        clearable
                                        localization="sk"
                                        label="Dátum do"
                                        placeholder="DD.MM.YYYY"
                                        dateFormat="DD.MM.YYYY"
                                        value={filters.to}
                                        onChange={(e, { value }) => setFilters({...filters, to: value})}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                    />
                                    <Form.Field style={{ marginTop: "1.8rem" }}>
                                        <Button style={{ background: "var(--primary)", color: "var(--white)" }}>Potvrdiť</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                            <Form.Field style={{ marginTop: "1.8rem", textAlign: "right" }}>
                                <SuperModal
                                    closeIcon
                                    size="small"
                                    header={<span style={{ fontWeight: "normal", fontSize: "1.3rem" }}>Zvolený typ:  <strong>{ (view === 1 ? "Odmeny - Zadávateľ" : "Odmeny - Kouč")}</strong> </span>}
                                    trigger={
                                        <Button style={{ background: "var(--dark)", color: "var(--white)" }}>
                                            Vyhotoviť odmeny
                                        </Button>
                                    }
                                    content={<GenerateRewardForm view={view} filters={filters} fetchRewards={fetchRewards}/>}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                { isLoading 
                    ? <Loading />
                    : 
                    <>
                        
                        <Divider/>
                        <RewardsTable rewards={rewards}/>
                    </> 
                }
            </section>
        </Fragment>
    );
};

export default Rewards;