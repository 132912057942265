// react
import React from "react";
// components
import { Button, Header, Icon, Modal } from "semantic-ui-react";

// component
function ModalResponse({ open, onClose, icon, header, message, onClick }) {
    // template
    return (
        <Modal
            open={open}
            onClose={onClose}
            closeOnDimmerClick={false}
            size="mini"
        >
            <Header icon={icon} content={header} />
            <Modal.Content>
                <h3>{message}</h3>
            </Modal.Content>
            <Modal.Actions>
                <Button color="pink" onClick={onClick}>
                    <Icon name="checkmark" /> Rozumiem
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ModalResponse;
