// react
import React from "react";
import { Switch, Route } from "react-router-dom";
// store
import { routes } from "../../../../store/routes";
import { authService } from "../../../../store/services/ServiceAuth";
// components
import CategoryList from "./CategoryList";
import CategoryAdd from "./CategoryAdd";
import CategoryEdit from "./CategoryEdit";

// component
function Categories() {
    authService.useIsAdmin();

    // template
    return (
        <Switch>
            <Route exact path={routes.CATEGORIES} component={CategoryList} />
            <Route exact path={routes.CATEGORIES_ADD} component={CategoryAdd} />
            <Route
                exact
                path={routes.CATEGORIES_EDIT + ":id"}
                component={CategoryEdit}
            />
        </Switch>
    );
}

export default Categories;
