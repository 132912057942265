// react
import React, { useEffect, useState } from "react";
// store
import { LOADING_TIME } from "../../store/config";
import { activationService } from "../../store/services/ServiceActivation";
// components
import HeaderTitle from "../../components/others/HeaderTitle";
import ActivationMessage from "./ActivationMessage";
import LinkLogo from "../../components/others/LinkLogo";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../index";

// component
function Activation(props) {
    // state
    const [userId] = useState(props.match.params.uid);
    const [token] = useState(props.match.params.token);
    const [isLoading, setIsLoading] = useState(false);
    const [tryingAgain, setTryingAgain] = useState(false);
    const [activationSuccessful, setActivationSuccessful] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // check if activation is successful
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await activationService.getUserActivation(
                userId,
                token
            );
            if (!result) {
                setActivationSuccessful(false);
            } else {
                setActivationSuccessful(true);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };
        fetchData();
    }, [userId, token]);

    const activateAgain = async () => {
        setTryingAgain(true);

        const result = await activationService.getUserActivation(userId, token);
        if (result) {
            setTryingAgain(false);
            setActivationSuccessful(true);
        } else {
            setTryingAgain(false);
            setActivationSuccessful(false);
        }
    };

    // template
    return (
        <div className="cms">
            <nav className="left-menu">
                <LinkLogo />
            </nav>

            <div className="content-container">
                <HeaderTitle title="Aktivácia - vytvorenie nového účtu" />
                <section>
                    {isLoading ? (
                        <Segment placeholder>
                            <Dimmer active inverted>
                                <Loader size="massive" inverted>
                                    Prebieha aktivácia účtu
                                </Loader>
                            </Dimmer>
                        </Segment>
                    ) : (
                        <Segment
                            className="center-segment"
                            textAlign="center"
                            loading={tryingAgain}
                        >
                            {activationSuccessful ? (
                                <ActivationMessage success={true} />
                            ) : (
                                <ActivationMessage
                                    success={false}
                                    tryAgain={activateAgain}
                                />
                            )}
                        </Segment>
                    )}
                </section>
            </div>
        </div>
    );
}

export default Activation;
