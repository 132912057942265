import request from "umi-request";
import { API } from "../config";
// services
import { authService } from "./ServiceAuth";
import { APIRequest } from "./helpers/requests";

/*
 * Service functions
 */
export const addressService = {
    getAddresses,
    getAddressesById,
    saveAddress,
    editAddress,
    deleteAddress,
    createBaseAddress,
    getBaseAddress,
    updateBaseAddress,
};

async function getAddresses() {
    let data;
    // create Async - Await request to get all bundles
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_ADDRESSES, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getAddressesById(id) {
    let data;
    // create Async - Await request to get all bundles
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_ADDRESSES + "?id=" + id, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function saveAddress(data) {
    let saved;
    let accessToken = await authService.getAccessToken();
    saved = await request(API.CREATE_ADDRESS, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return saved;
}

async function editAddress(data, id) {
    let updated;
    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_OR_DELETE_ADDRESS + id, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}

async function deleteAddress(id) {
    let deleted;
    let accessToken = await authService.getAccessToken();
    deleted = await request(API.EDIT_OR_DELETE_ADDRESS + id, {
        method: "delete",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return deleted;
}

async function createBaseAddress(data) {
    return await APIRequest({
        url: API.BASE_ADDRESS,
        method: "POST",
        data: data,
        private: false,
    });
}

async function getBaseAddress(id) {
    return await APIRequest({
        url: API.BASE_ADDRESS + id,
        method: "GET",
        private: true,
    });
}

async function updateBaseAddress(id, data) {
    return await APIRequest({
        url: API.BASE_ADDRESS + id,
        method: "PUT",
        data: data,
        private: true,
    });
}
