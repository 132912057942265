// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { companyService } from "../../../../store/services/ServiceCompany";
import { LOADING_TIME } from "../../../../store/config";
import { routes } from "../../../../store/routes";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import CoachNetworkTable from "./CoachNetworkTable";
import ButtonAdd from "../../../../components/buttons/ButtonAdd";
import Loading from "../../../../components/others/Loading";

// component
function CoachNetwork() {
    // state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await companyService.getCoachNetwork();
            setData(result);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Kouč - sieť poskytovateľov" />
            <section>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <ButtonAdd
                            link={routes.COACH_REGISTRATION}
                            text="Zaregistrovať poskytovateľa"
                        />
                        <p
                            style={{
                                fontSize: "0.9rem",
                                float: "left",
                                paddingTop: "2rem",
                            }}
                        >
                            Sieť kouča: <strong>{data.length}</strong>
                        </p>
                        <CoachNetworkTable
                            providers={data}
                            isLoading={isLoading}
                        />
                    </>
                )}
            </section>
        </Fragment>
    );
}

export default CoachNetwork;
