// react
import React from "react";
// components
import PlanningDeleteDayForm from "./PlanningDeleteDayForm";
import { Modal } from "semantic-ui-react";
// others
import moment from "moment";

moment.locale("sk");

// component
function PlanningDeleteDay({
    modalDeleteDayOpen,
    handleModalDeleteDayClose,
    deleteDate,
    changeModalMessageText,
    changeModalMessageUpdate,
}) {
    const newMessage = (message) => {
        changeModalMessageText(message);
        changeModalMessageUpdate(true);
        handleModalDeleteDayClose();
    };

    //template
    return (
        <Modal
            closeIcon
            open={modalDeleteDayOpen}
            onClose={handleModalDeleteDayClose}
            closeOnDimmerClick={false}
        >
            <Modal.Header>{`Zrušiť udalosti dňa ${moment(deleteDate).format(
                "YYYY-MM-DD"
            )}`}</Modal.Header>

            <PlanningDeleteDayForm
                deleteDate={deleteDate}
                newMessage={newMessage}
            />
        </Modal>
    );
}

export default PlanningDeleteDay;
