// react
import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
// store
import { removeAllFromBasket, emptyBasketConfiguration } from "../../store/actions";
import { authService } from "../../store/services/ServiceAuth";
import { paymentService } from "../../store/services/ServicePayment";
// components
import BasketItem from "./BasketItem";
import CheckLoader from "./payment-modal/CheckLoader";
import LoginForm from "../login/LoginForm";
import {
    Modal,
    Button,
    Segment,
    Header,
    List,
    Divider,
    Message,
    Icon,
} from "semantic-ui-react";
import ButtonAction from "./payment-modal/ButtonAction";
import RegisterClient from "../register/RegisterClient";

// component
const PaymentModal = ({
    trigger,
    open,
    onClose,
    onOpen,
    basket_items,
    removeItem,
    setBasketEvents,
    items,
    setItems
}) => {
    // state
    const [processing, setProcessing] = useState(false);
    const [isChecking, setIsChecking] = useState(true);
    const [paymentURL, setPaymentURL] = useState("");
    const [isConflict, setIsConflict] = useState(false);
    const [isWrongUserLoggedIn, setIsWrongUserLoggedIn] = useState(false);
    const [isClientAccount, setIsClientAccount] = useState(false);
    const [isLoginRequested, setIsLoginRequested] = useState(false);
    const [isRegisterRequested, setIsRegisterRequested] = useState(false);
    const [blocked, setBlocked] = useState([]);
    const [allowed, setAllowed] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        async function paymentInit() {
            if (open) {
                // check if user is logged in:
                if (user.role && user.role === "User") {
                    if ((await authService.getAccessToken()) !== false) {
                        setIsClientAccount(true);
                        setIsChecking(false);
                        await check_payment();
                    }
                }
                setIsChecking(false);
            }
        }

        paymentInit();
        // eslint-disable-next-line
    }, [open]);

    const check_payment = async () => {
        // reset to initial
        setIsConflict(false);
        setBlocked([]);
        setAllowed([]);
        setProcessing(true);

        // call API endpoint:
        const result = await paymentService.getPaymentInitForEvent({
            events: basket_items.events,
            future_events: basket_items.future_events,
            basket_config: items
        });

        if (result.status === 200) {
            dispatch(removeAllFromBasket());
            dispatch(emptyBasketConfiguration());
            setBasketEvents([]);
            setItems([]);
            setPaymentURL(result.response.payment_url);
            window.location.href = result.response.payment_url;
            // window.location.replace(result.response.payment_url);
        } else if (result.status === 409) {
            setBlocked(result.response.blocked);
            setAllowed(result.response.allowed);
            setIsConflict(true);
        }

        setProcessing(false);
    };

    const onLogin = async (status) => {
        if( status !== undefined && status === "wrong_user" ){
            setIsWrongUserLoggedIn(true)
        } else {
            setIsWrongUserLoggedIn(false)
            setIsClientAccount(true);
            await check_payment();
        }
    };

    const removeItemFromView = (item) => {
        setBlocked(blocked.filter((blocked) => blocked !== item));
    };

    const cancelOrder = () => {
        dispatch(removeAllFromBasket());
        dispatch(emptyBasketConfiguration());
        setBasketEvents([]);
        setItems([]);
        onClose();
    };

    const setLoginForm = () => {
        setIsLoginRequested(true);
    };

    const closeModal = () => {
        setIsLoginRequested(false);
        setIsRegisterRequested(false);
        onClose();
    };

    // template
    return (
        <Modal
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            dimmer
            centered={false}
            size="large"
            onClose={closeModal}
            onOpen={onOpen}
            open={open}
            trigger={trigger}
            closeIcon={true}
        >
            <Segment>
                <Modal.Content style={{ padding: "2rem" }}>
                    <CheckLoader
                        isActive={isChecking}
                        message="Kontrolujem pristúpový účet, počkajte prosím."
                    />
                    <CheckLoader
                        isActive={processing}
                        message="Spracovávam objednávku, počkajte prosím."
                    />

                    {!isChecking && (
                        <Fragment>
                            {!isClientAccount && (
                                <Fragment>
                                    {!isLoginRequested ? (
                                        <>
                                            {!isRegisterRequested ? (
                                                <>
                                                    <Header
                                                        as="h3"
                                                        style={{
                                                            marginTop: "0.5rem",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Pre dokončenie
                                                        objednávky sa musíte
                                                        prihlásiť na Váš
                                                        užívateľský účet.
                                                        <br />
                                                        <ButtonAction
                                                            onClick={() =>
                                                                setIsLoginRequested(
                                                                    true
                                                                )
                                                            }
                                                            text="Prihlásiť sa"
                                                        />
                                                    </Header>
                                                    <Divider
                                                        horizontal
                                                        style={{
                                                            margin: "2rem",
                                                        }}
                                                    >
                                                        ALEBO
                                                    </Divider>
                                                    <Header
                                                        as="h4"
                                                        style={{
                                                            marginTop: "0.5rem",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Ak užívateľský účet ešte
                                                        nemáte, môžete si ho
                                                        vytvoriť.
                                                        <br />
                                                        <ButtonAction
                                                            onClick={() =>
                                                                setIsRegisterRequested(
                                                                    true
                                                                )
                                                            }
                                                            text="Registrovať sa"
                                                        />
                                                    </Header>
                                                </>
                                            ) : (
                                                <>
                                                    <Header as="h3">
                                                        Vytvorenie nového účtu -
                                                        Užívateľ ActivityPoint
                                                    </Header>
                                                    <RegisterClient
                                                        basketRegistration={
                                                            true
                                                        }
                                                        setLoginForm={
                                                            setLoginForm
                                                        }
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Header
                                                as="h3"
                                                style={{ textAlign: "center" }}
                                            >
                                                Prihlásenie - Užívateľ
                                                ActivityPoint
                                            </Header>
                                            <LoginForm
                                                onLogin={onLogin}
                                                basketRegistration={true}
                                            />
                                            { isWrongUserLoggedIn && 
                                                <Message negative style={{ textAlign: "center"}}>
                                                    <p> <Icon name="warning"/> Užívateľský účet, ktorý ste zadali nie je platným užívateľským účtom. Prosím prihláste sa na Váš užívateľský účet.</p>
                                                </Message>
                                            }
                                        </>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}

                    {isConflict && (
                        <Fragment>
                            <Header
                                as="h4"
                                textAlign="center"
                                style={{ marginTop: 0 }}
                            >
                                Ľutujeme, objednávku nebolo možné spracovať,
                                nižšie uvedené termíny nie je možné objednať.{" "}
                                <br />
                                Odoberte položky pre pokračovanie, alebo zrušte
                                proces objednávky.
                            </Header>
                            <Divider />
                            <List divided verticalAlign="middle">
                                {blocked &&
                                    blocked.map((item, index) => (
                                        <BasketItem
                                            key={index}
                                            item={item}
                                            items={items}
                                            setItems={setItems}
                                            removeProductFromBasket={removeItem}
                                            removeItemFromView={
                                                removeItemFromView
                                            }
                                        />
                                    ))}
                            </List>
                            <Divider />
                            {allowed.length === 0 && (
                                <Message
                                    style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                    }}
                                    negative
                                >
                                    Neexistuje žiadna povolená položka na
                                    pokračovanie v objednávke.
                                </Message>
                            )}
                            <Button.Group fluid size="huge">
                                <Button onClick={() => cancelOrder()} negative>
                                    Zrušiť objednávku
                                </Button>
                                {allowed.length !== 0 && (
                                    <Button
                                        onClick={() => check_payment()}
                                        positive
                                        disabled={
                                            (isConflict &&
                                                blocked.length > 0) ||
                                            allowed.length === 0
                                        }
                                    >
                                        Pokračovať ( {allowed.length} )
                                    </Button>
                                )}
                            </Button.Group>
                        </Fragment>
                    )}

                    {paymentURL && (
                        <Fragment>
                            <Message
                                style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                                positive
                            >
                                Prebieha presmerovanie na platobnú bránu Tatra Banky...
                            </Message>
                            <Button
                                as="a"
                                href={paymentURL}
                                disabled={processing}
                                fluid
                                size="large"
                                style={{ marginLeft: 0 }}
                                positive
                            >
                                Dokončiť objednávku <br />
                                <small>
                                    Budete presmerovaný na platobnú bránu Tatra Banky
                                </small>
                            </Button>
                        </Fragment>
                    )}
                </Modal.Content>
            </Segment>
        </Modal>
    );
};

export default PaymentModal;
