// react
import React from "react";

// components
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'


// component
export default function Banner({ src, badge, height }) {
    return (
        <div className="banner-wrapper">
            <div
                className="banner-container"
                style={{ backgroundImage: `url(${src})`, backgroundPosition: 'center 40%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', 
                    height: height || "52vH",
                }}
            />

            {
                badge && (
                    <div className="back">
                        <div className="text">
                            <span>{badge?.text}</span>
                        </div>
                        <Link to={badge?.link}>
                            <Button className="btnSearch">{badge?.btnText}</Button>
                        </Link>
                    </div>
                )
            }
        </div>
    );
}
