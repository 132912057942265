const basketReducer = (products = {
    events: [],
    future_events: []
}, action) => {
    switch (action.type) {
        case "ADD_TO_BASKET":
            if( action.product.event_type === "future" ){
                if (products.future_events.indexOf(action.product.id) === -1){
                    return {
                        events: products.events,
                        future_events: [...products.future_events, action.product.id]
                    };
                } else {
                    return products;
                }
            } else if( action.product.event_type !== "future" ){
                if (products.events.indexOf(action.product.id) === -1){
                    return {
                        future_events: products.future_events,
                        events: [...products.events, action.product.id]
                    };
                } else {
                    return products;
                }
            }
            break;
                
        case "REMOVE_FROM_BASKET":
            if( action.product.event_type === "future" ){
                let future_events = products.future_events.filter(product => parseInt(product) !== action.product.id);
                return {
                    events: products.events,
                    future_events: future_events
                }
                
            } else {
                if( action.product.event_type !== "future" ){
                    let events = products.events.filter(product => parseInt(product) !== action.product.id);
                    return {
                        future_events: products.future_events,
                        events: events
                    }
                    
                }
            }
            break;
        
        case "REMOVE_ALL_FROM_BASKET":
            return {
                events: [],
                future_events: []
            };
        default:
            return products;
    }
};

export default basketReducer;
