// react
import React, { Fragment, useEffect, useState } from "react";
// store
import { paymentService } from "../../../../store/services/ServicePayment";
import { LOADING_TIME } from "../../../../store/config";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import ClientInvoicesTable from "./ClientInvoicesTable";
import { Card, Grid, Form, Button } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

// component
const ClientInvoices = () => {

    // state
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({
        from: moment().startOf('month').format("DD.MM.YYYY"),
        to: moment().endOf('month').format("DD.MM.YYYY")
    })

    const [stats, setStats] = useState({
        totalInvoices: 0,
        totalPaid: 0,
        totalNonPaid: 0,
    })
    const [isLoading, setIsLoading] = useState(false);

    // fetch data
    async function fetchData() {
        setIsLoading(true);
        let params = `?from=${moment(filters.from, "DD.MM.YYYY").format("YYYY-MM-DD")}&to=${moment(filters.to, "DD.MM.YYYY").format("YYYY-MM-DD")}`;
        const result = await paymentService.getInvoicesForUser(params);

        if (result.status === 200) {
            // calculate stats
            const invoices = result.response

            let totalPaidInvoices = 0;
            let totalNonPaidInvoices = 0;
            let totalInvoices = invoices.length;
            for(let i = 0; i < invoices.length; i++) {
                if( invoices[i].status === 3 ) { // paid
                    totalPaidInvoices += 1
                } else {
                    totalNonPaidInvoices += 1
                }
            }


            setData(invoices);
            setStats({
                totalInvoices: totalInvoices,
                totalPaid: totalPaidInvoices,
                totalNonPaid: totalNonPaidInvoices,
            })
        }

        setTimeout(function () {
            setIsLoading(false);
        }, LOADING_TIME);
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const handleFilter = async () => {
        fetchData()
    }

    // template
    return (
        <Fragment>
            <HeaderTitle title="Moje Objednávky" />
            <section>
                <Grid>
                    <Grid.Row columns={2} style={{ paddingBottom: 0 }}>
                        <Grid.Column>
                            <Form onSubmit={handleFilter}>
                                <Form.Group widths="equal">
                                    <DateInput
                                        fluid
                                        required
                                        localization="sk"
                                        label="Dátum od"
                                        placeholder="DD.MM.YYYY"
                                        dateFormat="DD.MM.YYYY"
                                        value={filters.from}
                                        onChange={(e, { value }) => setFilters({...filters, from: value})}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                    />
                                    <DateInput
                                        fluid
                                        required
                                        localization="sk"
                                        label="Dátum do"
                                        placeholder="DD.MM.YYYY"
                                        dateFormat="DD.MM.YYYY"
                                        value={filters.to}
                                        onChange={(e, { value }) => setFilters({...filters, to: value})}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                    />
                                    <Form.Field style={{ marginTop: "1.8rem" }}>
                                        <Button style={{ background: "var(--primary)", color: "var(--white)" }}>Potvrdiť</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                        <Grid.Column/>
                    </Grid.Row>
                </Grid>
                { isLoading 
                    ? <Loading />
                    : 
                    <>
                        <Grid style={{ marginBottom: "1rem" }}>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <Card fluid style={{ height: "5rem" }}>
                                        <Card.Content textAlign="center">
                                            Celkovo faktúr: <strong>{ stats.totalInvoices }</strong> 
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column>
                                    <Card fluid style={{ height: "5rem" }}>
                                        <Card.Content textAlign="center">
                                            Zaplatené: <strong>{ stats.totalPaid }</strong> 
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column>
                                    <Card fluid style={{ height: "5rem" }}>
                                        <Card.Content textAlign="center">
                                            Nezaplatené: <strong>{ stats.totalNonPaid }</strong> 
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <ClientInvoicesTable invoices={data} isLoading={isLoading} />
                    </>
                }
            </section>
        </Fragment>
    );
};

export default ClientInvoices;
