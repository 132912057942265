import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// store
import { API } from '../../../../../store/config';
import { commonService } from '../../../../../store/services/ServiceCommon';
import { routes } from '../../../../../store/routes';
// components
import { Card, Grid, Header } from "semantic-ui-react";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import Loading from '../../../../../components/others/Loading';

const HomeScreenStats = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [stats, setStats] = useState({})

    useEffect(() => {
        async function fetchStats(){
            setIsLoading(true)
            const request = await commonService.getStats(API.GET_STATS + "totals/")
            
            if( request.status === 200 ){
                setStats(request.response)
            }
            setIsLoading(false)
        }

        fetchStats()
    }, [])


    return (
        <div>
            { isLoading 
                ? <Loading />
                : 
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <Card.Group itemsPerRow={3}>
                                <Card>
                                    <Card.Content>
                                        <Header as="h4">Počet zadávateľov: </Header>
                                        <span style={{ fontSize: "1.2rem" }}>{ stats.total_providers }</span>
                                        <div style={{ marginTop: "0.5rem" }}><Link style={{ color: "#d60c8c" }} to={routes.STATS_PROVIDERS}>Zobraziť zoznam</Link></div>
                                    </Card.Content>
                                </Card>
                                <Card>
                                    <Card.Content>
                                        <Header as="h4">Počet koučov: </Header>
                                        <span style={{ fontSize: "1.2rem" }}>{ stats.total_coaches }</span>
                                        <div style={{ marginTop: "0.5rem" }}><Link style={{ color: "#d60c8c" }} to={routes.STATS_COACHES}>Zobraziť zoznam</Link></div>
                                    </Card.Content>
                                </Card>
                                <Card>
                                    <Card.Content>
                                        <Header as="h4">Počet miest a obcí: </Header>
                                        <span style={{ fontSize: "1.2rem" }}>{ stats.total_towns }</span>
                                        <div style={{ marginTop: "0.5rem" }}><Link style={{ color: "#d60c8c" }} to={routes.STATS_TOWNS}>Zobraziť zoznam</Link></div>
                                    </Card.Content>
                                </Card>
                            </Card.Group>
                            <Card.Group itemsPerRow={2}>
                                <Card>
                                    <Card.Content>
                                        <Header as="h4">Počet užívateľov: </Header>
                                        <span style={{ fontSize: "1.2rem" }}>{ stats.total_clients }</span>
                                        <span>&nbsp;</span>
                                    </Card.Content>
                                </Card>
                                <Card>
                                    <Card.Content>
                                        <Header as="h4">Počet aktivít: </Header>
                                        <span style={{ fontSize: "1.2rem" }}>{ stats.total_activities }</span>
                                        <div style={{ marginTop: "0.5rem" }}><Link style={{ color: "#d60c8c" }} to={routes.STATS_ACTIVITIES}>Zobraziť zoznam</Link></div>
                                    </Card.Content>
                                </Card>
                            </Card.Group>
                            <Card fluid>
                                <Card.Content>
                                    <Header as="h4">Platby (zaplatené / nezaplatené): </Header>
                                    <span style={{ fontSize: "1.2rem" }}><strong style={{ fontSize: "1.5rem" }}>{ stats.total_paid_invoices + stats.total_non_paid_invoices }</strong> ( { stats.total_paid_invoices }/{ stats.total_non_paid_invoices } )</span>
                                    <div style={{ marginTop: "0.5rem" }}><Link style={{ color: "#d60c8c" }} to={routes.STATS_PAYMENTS}>Zobraziť zoznam</Link></div>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <Card.Group itemsPerRow={2}>
                                <Card>
                                    <Card.Content>
                                        <Doughnut data={{
                                            labels: ['Aktívny - ' + stats.total_active_users, 'Neaktívny - ' + stats.total_inactive_users],
                                            datasets: [
                                                { 
                                                    data: [stats.total_active_users, stats.total_inactive_users],
                                                    backgroundColor: [
                                                        'lightgreen',
                                                        'grey',
                                                    ]
                                                }
                                            ]
                                        }}/>
                                        <Header as="h5" textAlign="center" content={ "Registrovaných používateľov: " + stats.total_registrations }/>
                                    </Card.Content>
                                </Card>
                                <Card>
                                    <Card.Content>
                                        <Doughnut data={{
                                            labels: ['Publikovaných - ' + stats.total_published_events, 'Zrušených - ' + stats.total_canceled_events],
                                            datasets: [
                                                { 
                                                    data: [stats.total_published_events, stats.total_canceled_events],
                                                    backgroundColor: [
                                                        'orange',
                                                        'green',
                                                    ]
                                                }
                                            ]
                                        }}/>
                                        <Header as="h5" textAlign="center" content={ "Počet termínov: " + stats.total_events }/>
                                    </Card.Content>
                                </Card>
                            </Card.Group>
                        </Grid.Column>
                    </Grid.Row>
                    
                </Grid>
            }
        </div>
    );
};

export default HomeScreenStats;