// react
import React from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
// store
import { routes } from "../../store/routes";
// components
// import LanguageSelector from "../others/LanguageSelector";
import { Icon, Grid, Button, Dropdown } from "semantic-ui-react";
import BasketIcon from "./BasketIcon";
import Autocomplete from "react-google-autocomplete";

// component
function Navbar(props) {

    // template
    return (
        <>
        <Grid className="navbar" style={styles.navbar} centered columns={5}>
            {props.isHome ? (
                <Grid.Column
                    mobile={16}
                    tablet={4}
                    computer={4}
                    className="img"
                >
                    <Link to={routes.HOME}>
                        <img
                            src="/images/logos/logo_dark_version.png"
                            width={"155rem"}
                            alt="logo"
                        />
                    </Link>
                </Grid.Column>
            ) : (
                <>
                    <Grid.Column
                        only="tablet computer"
                        tablet={3}
                        computer={3}
                        className="img"
                    >
                        <Link to={routes.HOME}>
                            <img
                                src="/images/logos/logo_dark_version.png"
                                width={"155rem"}
                                alt="logo"
                            />
                        </Link>
                    </Grid.Column>
                </>
            )}

            <Grid.Column
                style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                mobile={props.isSearch ? 12 : 16}
                tablet={props.isSearch ? 11 : 8}
                computer={props.isSearch ? 6 : 4}
                className="btn"
            >
                {props.isSearch ? (
                    <div className="filtering-container">
                        {/* { props.isSearch && <BackArrow linkBack={props.linkBack}/> } */}
                        {/* <div className="ui huge input"> */}
                            <Autocomplete
                                autoFocus
                                // readOnly={readOnly}
                                // autoComplete="off"
                                id="map-search-bar"
                                style={{ fontSize: "16px" }}
                                placeholder="Zadaj miesto..."
                                onPlaceSelected={props.onSelect}
                                // onClick={() => setReadOnly(false)}
                                types={[]}
                                componentRestrictions={{ country: ["sk", "cz"] }}
                            />
                        {/* </div> */}

                        <Button
                            type="button"
                            loading={props.searching}
                            disabled={props.searching}
                            title="Upresniť vyhľadávacie možnosti"
                            size="tiny"
                            onClick={props.searchConfig}
                            className="location-button"
                        >
                            <Icon
                                name="search"
                                style={{
                                    marginRight: "0",
                                    fontSize: "16px",
                                    marginLeft: "1px",
                                }}
                            />
                        </Button>

                        <Button
                            type="button"
                            onClick={props.searchMyLocation}
                            title="Nastaviť moju polohu"
                            className="location-search"
                            color="pink"
                        >
                            <Icon
                                name="crosshairs"
                                style={{
                                    marginRight: "0",
                                    fontSize: "16px",
                                    marginLeft: "1px",
                                }}
                            />
                        </Button>
                        <Dropdown
                            // text='Filter'
                            icon={
                                <Icon
                                    name="ellipsis horizontal"
                                    style={{
                                        marginRight: "0",
                                        fontSize: "16px",
                                        marginLeft: "1px",
                                        color: "black"
                                    }}
                                />
                            }
                            // floating
                            button
                            className="location-search"
                            style={{ border: "1px solid black", background: "transparent" }}
                        >
                            <Dropdown.Menu className="map-menu-dropdown">
                                <Dropdown.Item as={Link} to={"/login"}>Prihlásiť sa</Dropdown.Item>
                                <Dropdown.Item disabled>Skenovať QR bonus</Dropdown.Item>
                                <Dropdown.Item as={Link} to={"/apsolidarity"}>Registrovať mesto/obec</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <Button
                            type="button"
                            className="location-search"
                            style={{ border: "1px solid black", background: "transparent" }}
                        >
                            <Icon
                                name="ellipsis horizontal"
                                style={{
                                    marginRight: "0",
                                    fontSize: "16px",
                                    marginLeft: "1px",
                                    color: "black"
                                }}
                            />
                        </Button> */}
                    </div>
                ) : (
                    <div className="mobile-nav-block">
                        { !props.isSearch && 
                            <Link to={props.linkBack} style={{ marginTop: "0.2rem" }}>
                                <Icon
                                    name="arrow alternate circle left"
                                    size="big"
                                />
                            </Link>
                        }
                        <Link
                            to={routes.HOME}
                            className="btnSearch"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            Zadaj miesto
                        </Link>
                    </div>
                )}
            </Grid.Column>

            { !props.isSearch && 
                <Grid.Column
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    mobile={16}
                    tablet={6}
                    computer={5}
                    className={`icons ${props.isHome ? "" : "force-md-center"}`}
                >
                    {/* <LanguageSelector /> */}
                    {/* <a href="#currency">
                        <Icon name="euro" size="large" />
                    </a> */}
                    <Button as={Link} to={routes.AP_SOLIDARITY} size="large" style={{ background: "transparent" }} content={"Registrovať obec/mesto"}/>

                    <Link to={routes.LOGIN}>
                        <Icon name="user" size="large" />
                    </Link>

                </Grid.Column>
            }
        </Grid>
        <Sticky innerZ={10}>
            <BasketIcon />
        </Sticky>
        </>
    );
}

const styles = {
    navbar: {
        fontfamily: "Avenir Next LT Pro",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0.1rem",
    },
};

export default Navbar;
