// react
import React, { useState, Fragment } from "react";
// store
import { resetPasswordService } from "../../../store/services/ServiceResetPassword";
import {
    isEmpty,
    isPasswordError,
    isRepeatPasswordError,
} from "../../../store/services/helpers/validation";
// components
import ButtonSubmit from "../../../components/buttons/ButtonSubmit";
import ModalResponse from "../../../components/modals/ModalResponse";
import { Form, Grid, Header } from "semantic-ui-react";

// component
function ProfileChangePassword() {
    // state
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    /** component functions **/

    const openModal = (message, error) => {
        setModalMessage(message);
        setModalIsError(error);
        setIsLoading(false);
        handleModalOpen();
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        let completed = false;
        if (
            oldPassword !== "" &&
            !isRepeatPasswordError(password, repeatPassword) &&
            !isPasswordError(password)
        ) {
            const data = {
                new_password: password,
                current_password: oldPassword,
            };

            if (await resetPasswordService.setNewPassword(data)) {
                completed = true;
                openModal("Zmena hesla prebehla úspešne.", false);
                setOldPassword("");
                setPassword("");
                setRepeatPassword("");
            }
            if (!completed) {
                openModal("Počas zmeny hesla došlo k chybe!", true);
            }
        } else {
            setIsLoading(false);
        }
    };

    // template
    return (
        <Fragment>
            <Grid>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <Header as="h3">ZMENA HESLA</Header>
                    <Form onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Input
                                required
                                autoFocus
                                fluid
                                label="Staré heslo"
                                placeholder="Staré heslo"
                                type="password"
                                autoComplete="new-password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                required
                                fluid
                                label="Nové heslo"
                                placeholder="Nové heslo"
                                type="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={
                                    !isEmpty(password)
                                        ? isPasswordError(password)
                                        : false
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                required
                                fluid
                                label="Opakuj heslo"
                                placeholder="Opakuj heslo"
                                type="password"
                                autoComplete="new-password"
                                value={repeatPassword}
                                onChange={(e) =>
                                    setRepeatPassword(e.target.value)
                                }
                                error={
                                    !isEmpty(repeatPassword)
                                        ? isRepeatPasswordError(
                                              password,
                                              repeatPassword
                                          )
                                        : false
                                }
                            />
                        </Form.Field>

                        <ButtonSubmit
                            loading={isLoading}
                            disabled={
                                isLoading ||
                                isEmpty(oldPassword) ||
                                isEmpty(password) ||
                                isEmpty(repeatPassword)
                            }
                            text="Zmeniť heslo"
                        />
                    </Form>
                </Grid.Column>
            </Grid>

            <ModalResponse
                open={modalOpen}
                onClose={handleModalClose}
                icon={modalIsError ? "warning circle" : "checkmark"}
                header={
                    modalIsError
                        ? "Aktualizácia neúspešná"
                        : "Aktualizácia úspešná"
                }
                message={modalMessage}
                onClick={handleModalClose}
            />
        </Fragment>
    );
}

export default ProfileChangePassword;
