const languageReducer = (language, action) => {
    switch (action.type) {
        case "SET_LANGUAGE":
            return action.language;
        default:
            return "sk";
    }
};

export default languageReducer;
