// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { addressService } from "../../../../store/services/ServiceAddress";
import { LOADING_TIME } from "../../../../store/config";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import Map from "./Map";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function AddressEdit(props) {
    // state
    const [address, setAddress] = useState({});
    const [addressId] = useState(props.match.params.id);
    const [isLoading, setIsLoading] = useState(false);

    // google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await addressService.getAddressesById(addressId);
            setAddress(result[0]);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, [addressId]);

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HeaderTitle title="Upraviť adresu" />
                    <section>
                        <Map
                            center={{
                                lat: parseFloat(address.latitude),
                                lng: parseFloat(address.longitude),
                            }}
                            height="300px"
                            zoom={14}
                            isEdit={true}
                            tutorial={false}
                        />
                    </section>
                </>
            )}
        </Fragment>
    );
}

export default AddressEdit;
