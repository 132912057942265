// react
import React, { Fragment, useEffect, useState } from "react";
// store
import { PAGINATION_LIMIT } from "../../../../store/config";
import { paginationChangePage } from "../../../../store/services/helpers/functions";
// components
import DimmerLoader from "../../../../components/others/DimmerLoader";
import { Table, Header, Button, Pagination } from "semantic-ui-react";

// component
function PaymentTable({ payments, isLoading }) {
    // state
    const [paymentList, setPaymentList] = useState(
        payments.slice(0, PAGINATION_LIMIT)
    );

    // set data to table
    useEffect(() => {
        setPaymentList(payments.slice(0, PAGINATION_LIMIT));
    }, [payments]);

    // template
    return (
        <Fragment>
            <DimmerLoader active={isLoading} />
            <Table striped unstackable style={{ overflow: "auto" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Dátum úhrady</Table.HeaderCell>
                        <Table.HeaderCell>Zaplatené obdobie</Table.HeaderCell>
                        <Table.HeaderCell>Suma</Table.HeaderCell>
                        <Table.HeaderCell>Číslo karty</Table.HeaderCell>
                        <Table.HeaderCell>Zaplatené aktivity</Table.HeaderCell>
                        <Table.HeaderCell>Poskytovateľ</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {paymentList.map((payment, paymentId) => (
                        <Table.Row key={paymentId}>
                            <Table.Cell>
                                <Header as="h5">{payment.paid_date}</Header>
                            </Table.Cell>
                            <Table.Cell>{payment.date_to}</Table.Cell>
                            <Table.Cell>{payment.price}</Table.Cell>
                            <Table.Cell>{payment.credit_card}</Table.Cell>
                            <Table.Cell>
                                {payment.activities.map((activity, index) => (
                                    <Button
                                        key={index}
                                        basic
                                        size="mini"
                                        color="pink"
                                        style={{
                                            marginTop: "0.2rem",
                                            marginBottom: "0.2rem",
                                        }}
                                    >
                                        {activity.name}
                                    </Button>
                                ))}
                            </Table.Cell>
                            <Table.Cell>{payment.provider}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="6">
                            <Pagination
                                defaultActivePage={1}
                                totalPages={Math.ceil(
                                    payments.length / PAGINATION_LIMIT
                                )}
                                onPageChange={(
                                    event,
                                    data,
                                    setPaymentList,
                                    payments
                                ) => paginationChangePage}
                                boundaryRange={0}
                                siblingRange={0}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Fragment>
    );
}

export default PaymentTable;
