// react
import React, { useState, Fragment } from "react";
import { withRouter } from "react-router";
// store
import { categoryService } from "../../../../store/services/ServiceCategory";
import { LOADING_TIME } from "../../../../store/config";
import { isEmptyErrorList } from "../../../../store/services/helpers/validation";
import { activeOptions } from "../../../../store/services/helpers/settings";
import { routes } from "../../../../store/routes";
import {
    convertStringToTags,
    convertTagsToString,
} from "../../../../store/services/helpers/functions";
// components
import ButtonDelete from "../../../../components/buttons/ButtonDelete";
import ButtonSubmit from "../../../../components/buttons/ButtonSubmit";
import ModalConfirm from "../../../../components/modals/ModalConfirm";
import ModalResponse from "../../../../components/modals/ModalResponse";
import { Divider, Form, Header, Icon } from "semantic-ui-react";

// component
function CategoryForm({ data, isEdit, history }) {
    // state
    const [isEditForm] = useState(isEdit ? true : false);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [categoryId] = useState(data.id ? data.id : null);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error message");

    // form attributes
    const [nameSK, setNameSK] = useState(data.name || "");
    const [nameCZ, setNameCZ] = useState(data.name_cz || "");
    const [nameEN, setNameEN] = useState(data.name_en || "");
    const [nameDE, setNameDE] = useState(data.name_de || "");
    const [nameRU, setNameRU] = useState(data.name_ru || "");
    const [nameFR, setNameFR] = useState(data.name_fr || "");
    const [nameES, setNameES] = useState(data.name_es || "");
    const [status, setStatus] = useState(isEditForm ? data.status : true);
    // set activity tags
    let stringTags = convertTagsToString(data);
    const [tags, setTags] = useState(stringTags);

    /** component functions **/

    const serviceSuccess = () => {
        setTimeout(function () {
            setIsProcessing(false);
            history.push(routes.CATEGORIES);
        }, LOADING_TIME);
    };

    const serviceError = () => {
        setIsProcessing(false);
        setErrorMessage("Počas ukladania dát došlo k chybe!");
        handleErrorOpen();
    };

    const deleteCategory = () => {
        setIsDeleteConfirmed(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let tagsList = convertStringToTags(tags);

        // dataset
        const dataSubmit = {
            name: nameSK,
            name_cz: nameCZ,
            name_en: nameEN,
            name_de: nameDE,
            name_ru: nameRU,
            name_fr: nameFR,
            name_es: nameES,
            status: status,
            tags: tagsList,
        };

        setIsProcessing(true);
        if (isEditForm && categoryId !== null) {
            if (await categoryService.editCategory(dataSubmit, categoryId)) {
                serviceSuccess();
            } else {
                serviceError();
            }
        } else {
            if (await categoryService.saveCategory(dataSubmit)) {
                serviceSuccess();
            } else {
                serviceError();
            }
        }
    };

    const handleStatusChange = (e, data) => {
        setStatus(data.value);
    };

    const handleCancel = () => {
        setIsDeleteConfirmed(false);
    };

    const handleConfirm = async () => {
        setIsDeleteConfirmed(true);
        setIsProcessing(true);
        if (await categoryService.deleteCategory(categoryId)) {
            serviceSuccess();
        } else {
            serviceError();
            setIsDeleteConfirmed(false);
        }
    };

    const handleErrorOpen = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    // template
    return (
        <Fragment>
            <Form loading={isProcessing} onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Input
                        width={16}
                        required
                        autoFocus
                        fluid
                        label="Názov kategórie SK"
                        placeholder="Názov kategórie SK"
                        value={nameSK}
                        onChange={(e) => setNameSK(e.target.value)}
                    />
                </Form.Group>
                <Divider horizontal>
                    <Header as="h4" sub>
                        <Icon name="language" />
                        Preklady
                    </Header>
                </Divider>
                <Form.Group widths={2}>
                    <Form.Input
                        required
                        fluid
                        label="Názov kategórie CZ"
                        placeholder="Názov kategórie CZ"
                        value={nameCZ}
                        onChange={(e) => setNameCZ(e.target.value)}
                    />
                    <Form.Input
                        required
                        fluid
                        label="Názov kategórie EN"
                        placeholder="Názov kategórie EN"
                        value={nameEN}
                        onChange={(e) => setNameEN(e.target.value)}
                    />
                </Form.Group>
                <Form.Group widths={2}>
                    <Form.Input
                        required
                        fluid
                        label="Názov kategórie DE"
                        placeholder="Názov kategórie DE"
                        value={nameDE}
                        onChange={(e) => setNameDE(e.target.value)}
                    />
                    <Form.Input
                        required
                        fluid
                        label="Názov kategórie RU"
                        placeholder="Názov kategórie RU"
                        value={nameRU}
                        onChange={(e) => setNameRU(e.target.value)}
                    />
                </Form.Group>
                <Form.Group widths={2}>
                    <Form.Input
                        required
                        fluid
                        label="Názov kategórie FR"
                        placeholder="Názov kategórie FR"
                        value={nameFR}
                        onChange={(e) => setNameFR(e.target.value)}
                    />
                    <Form.Input
                        required
                        fluid
                        label="Názov kategórie ES"
                        placeholder="Názov kategórie ES"
                        value={nameES}
                        onChange={(e) => setNameES(e.target.value)}
                    />
                </Form.Group>
                <Divider horizontal>Doplňujúce informácie</Divider>
                <Form.Group>
                    <Form.Select
                        width={16}
                        required
                        fluid
                        label="Status"
                        placeholder="Status"
                        options={activeOptions}
                        value={status}
                        onChange={handleStatusChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        width={16}
                        required
                        fluid
                        label="Klúčové slová (zadaj slová oddelené čiarkou)"
                        placeholder="Klúčové slová"
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                    />
                </Form.Group>

                <Form.Group>
                    <ButtonSubmit
                        loading={isProcessing}
                        disabled={
                            isProcessing ||
                            !isEmptyErrorList([
                                nameSK,
                                nameCZ,
                                nameEN,
                                nameDE,
                                nameRU,
                                nameFR,
                                nameES,
                                tags,
                            ])
                        }
                        text={isEditForm ? "Upraviť" : "Vytvoriť kategóriu"}
                    />

                    {isEditForm ? (
                        <ButtonDelete
                            loading={isDeleteConfirmed}
                            disabled={isProcessing}
                            onClick={deleteCategory}
                            text="Vymazať"
                        />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <ModalConfirm
                    open={isDeleteConfirmed}
                    header="Vymazanie kategórie"
                    content="Si si istý(á), že chceš vymazať túto kategóriu?"
                    confirmButton="Áno"
                    cancelButton="Nie"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                />
            </Form>

            <ModalResponse
                open={errorOpen}
                onClose={handleErrorClose}
                icon="warning circle"
                header="Chyba"
                message={errorMessage}
                onClick={handleErrorClose}
            />
        </Fragment>
    );
}

export default withRouter(CategoryForm);
