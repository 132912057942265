import request from "umi-request";
import { APIRequest } from "./helpers/requests";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";

/*
 * Service functions
 */
export const companyService = {
    getCompanyInformation,
    updateCompanyInformation,
    getCoachNetwork,
    getBankAccountByID,
    createBankAccount,
    updateBankAccount,
};

async function getCompanyInformation() {
    let data;
    // create Async - Await request to get all active categories
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_COMPANY, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getCoachNetwork() {
    let data;
    // create Async - Await request to get all active categories
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_NETWORK, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function updateCompanyInformation(data) {
    let updated;
    let accessToken = await authService.getAccessToken();
    updated = await request(API.UPDATE_COMPANY, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}

async function getBankAccountByID(id){
    return await APIRequest({
        url: API.BANK_ACCOUNT + id,
        method: 'GET',
        private: true
    });
}

async function createBankAccount(data){
    return await APIRequest({
        url: API.BANK_ACCOUNT,
        method: 'POST',
        data: data,
        private: true
    });
}

async function updateBankAccount(data, id){
    return await APIRequest({
        url: API.BANK_ACCOUNT + id,
        method: 'PUT',
        data: data,
        private: true
    });
}
