// react
import React, { useState, useEffect } from "react";
// store
import { LOADING_TIME } from "../../../../store/config";
import { userService } from '../../../../store/services/ServiceUsers'
// components
import UsersTable from "./UsersTable";
import UsersForm from "./UsersForm"
import { Grid, Modal, Button, Icon } from "semantic-ui-react";

// component
function UsersSearch({setTotalProviders, totalProviders}) {
    // state
    const [open, setOpen] = useState(false)
    const [data, setData] = useState([]);
    const [tableIsLoading, setTableIsLoading] = useState(false);

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setTableIsLoading(true);
            let providers = await userService.getProviders();
            setData(providers.response)

            if( providers.response.length > 0 ){
                setTotalProviders("("+providers.response.length+")");
            }

            setTimeout(function () {
                setTableIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    const ModalForm = () => {
        return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={
                    <Button color="pink" icon>
                        <span>Pridať poskytovateľa </span>
                        <Icon name="plus" />
                    </Button>}>
                <Modal.Header>Pridať nového poskytovateľa</Modal.Header>
                <Modal.Content>
                    <UsersForm 
                        setOpen={setOpen} 
                        setData={setData} 
                        data={data}
                        setTotalProviders={setTotalProviders}
                        totalProviders={totalProviders}
                    />
                </Modal.Content>
            </Modal>
        )
    }
    // template
    return (
        <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Grid>
                    <Grid.Row style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <ModalForm />
                    </Grid.Row>
                </Grid>

                <UsersTable
                    setData={setData}
                    data={data ? data : []}
                    isLoading={tableIsLoading}
                />
            </Grid.Column>
        </Grid>
    );
}

export default UsersSearch;
