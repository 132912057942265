// react
import React from "react";
// components
import { Grid, Header } from "semantic-ui-react";

// component
function RequestDetailField({ header, value }) {
    // template
    return (
        <Grid.Column>
            <Header as="h3" sub>
                {header}
            </Header>
            <span style={{ fontWeight: "bold" }}>{value}</span>
        </Grid.Column>
    );
}

export default RequestDetailField;
