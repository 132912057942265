import request from "umi-request";
import { API } from "../config";
import { APIRequest } from "./helpers/requests";

// services
import { authService } from "./ServiceAuth";

/*
 * Service functions
 */
export const planningService = {
    getEvents,
    createEvent,
    cancelEvent,
    editEvent,
    cancelDayEvents,
    markAsHighlighted,
};

async function markAsHighlighted(event_id, method) {
    return await APIRequest({
        url: API.SET_HIGHLIGHTED + event_id,
        method: method,
        data: {},
        private: true,
    });
}

async function getEvents(params) {
    let data;
    // create Async - Await request
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_EVENTS + params, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function createEvent(data) {
    let created;
    // create Async - Await request
    let accessToken = await authService.getAccessToken();
    created = await request(API.CREATE_EVENT, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return created;
}

async function cancelEvent(data, id) {
    let canceled;

    let accessToken = await authService.getAccessToken();
    canceled = await request(API.EDIT_OR_CANCEL_EVENT + id, {
        method: "delete",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return canceled;
}

async function editEvent(data, id) {
    let updated;

    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_OR_CANCEL_EVENT + id, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}

async function cancelDayEvents(data) {
    let canceled;

    let accessToken = await authService.getAccessToken();
    canceled = await request(API.CANCEL_DAY_EVENTS, {
        method: "delete",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return canceled;
}
