// react
import React from "react";
import { Link } from "react-router-dom";
// components
import { Card, Icon, Header } from "semantic-ui-react";

// component
const ButtonCardLink = ({ to, text }) => {
    // to as link reference
    // bottom is for marginBottom

    // template
    return (
        <Card
            as={Link}
            to={to}
            fluid
            style={{
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Card.Content style={{ textAlign: "center" }}>
                <Icon
                    circular
                    size="big"
                    color="pink"
                    name="plus"
                    style={{ width: "100%" }}
                />
                <Header as="h3" style={{ marginTop: "1rem" }}>
                    {text}
                </Header>
            </Card.Content>
        </Card>
    );
};

export default ButtonCardLink;
