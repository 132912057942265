// react
import React, { useState, Fragment, useEffect } from "react";
// store
import { socialNetworkService } from "../../../store/services/ServiceSocials";
import { LOADING_TIME } from "../../../store/config";
// components
import ProfileSocialNetworkForm from "./ProfileSocialNetworksForm";
import Loading from "../../../components/others/Loading";
import ModalConfirm from "../../../components/modals/ModalConfirm";
import ProfileSocialNetworkItem from "./ProfileSocialNetworkItem";
import ModalResponse from "../../../components/modals/ModalResponse";
import { Button, Icon, Grid, List } from "semantic-ui-react";

// component
function ProfileSocialNetworks() {
    // state
    const [socialNetworks, setSocialNetworks] = useState([]);
    const [socialNetworkData, setSocialNetworkData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [modalFormOpen, setModalFormOpen] = useState(false);
    const [isModalEditForm, setIsModalEditForm] = useState(false);
    const [networksChange, setNetworksChange] = useState(false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
    const [confirmDeleteID, setConfirmDeleteID] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    // fetch data - profile social networks
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const data = await socialNetworkService.getSocialNetworks();
            // set data to state
            setSocialNetworks(data);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // refresh tab when there was a change in social networks
    useEffect(() => {
        if (networksChange) {
            const fetchData = async () => {
                setIsLoading(true);
                const data = await socialNetworkService.getSocialNetworks();
                // set data to state
                setSocialNetworks(data);

                // reset change state
                setNetworksChange(false);

                setTimeout(function () {
                    setIsLoading(false);
                }, LOADING_TIME);
            };

            fetchData();
        }
    }, [networksChange]);

    /** component functions **/

    const confirmDelete = (id) => {
        setIsConfirmDeleteOpen(true);
        setConfirmDeleteID(id);
    };

    const handleModalFormOpen = (isEditForm, socialNetworkCount) => {
        setIsModalEditForm(isEditForm);
        setSocialNetworkData(socialNetworks[socialNetworkCount]);
        setModalFormOpen(true);
    };

    const handleModalFormClose = () => {
        setModalFormOpen(false);
        setIsModalEditForm(false);
    };

    const handleNetworksChange = (change) => {
        setNetworksChange(change);
    };

    const changeModalMessage = (message) => {
        setModalMessage(message);
    };

    const changeModalIsError = (error) => {
        setModalIsError(error);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleConfirmDelete = () => {
        if (confirmDeleteID !== 0) {
            deleteSocialNetwork(confirmDeleteID).then(() =>
                setConfirmDeleteID(0)
            );
            setIsConfirmDeleteOpen(false);
        }
    };

    const handleConfirmCancel = () => {
        setIsConfirmDeleteOpen(false);
    };

    // delete selected social network
    const deleteSocialNetwork = async (id) => {
        if (await socialNetworkService.deleteSocialNetwork(id)) {
            setTimeout(function () {
                setModalMessage("Vymazanie sociálnej siete prebehlo úspešne.");
                setModalIsError(false);
                handleNetworksChange(true);
                handleModalOpen();
            }, LOADING_TIME);
        } else {
            setModalMessage("Počas vymazávania sociálnej siete došlo k chybe!");
            setModalIsError(false);
            handleModalOpen();
        }
    };

    // move selected network in order up for one place
    const moveOrderUp = async (socialNetworkCount) => {
        setIsProcessing(true);

        // if social network is not first in list
        if (socialNetworkCount > 0) {
            const dataNetworkUp = {
                icon: socialNetworks[socialNetworkCount].icon,
                name: socialNetworks[socialNetworkCount].name,
                link: socialNetworks[socialNetworkCount].link,
                order: socialNetworks[socialNetworkCount].order - 1,
            };

            const dataNetworkDown = {
                icon: socialNetworks[socialNetworkCount - 1].icon,
                name: socialNetworks[socialNetworkCount - 1].name,
                link: socialNetworks[socialNetworkCount - 1].link,
                order: socialNetworks[socialNetworkCount - 1].order + 1,
            };

            if (
                (await socialNetworkService.updateSocialNetwork(
                    dataNetworkUp,
                    socialNetworks[socialNetworkCount].id
                )) &&
                (await socialNetworkService.updateSocialNetwork(
                    dataNetworkDown,
                    socialNetworks[socialNetworkCount - 1].id
                ))
            ) {
                setTimeout(function () {
                    setIsProcessing(false);
                    handleNetworksChange(true);
                }, LOADING_TIME);
            }

            setIsProcessing(false);
        }
    };

    // move selected network in order down for one place
    const moveOrderDown = async (socialNetworkCount) => {
        setIsProcessing(true);

        // if social network is not last in list
        if (socialNetworkCount < socialNetworks.length - 1) {
            const dataNetworkUp = {
                icon: socialNetworks[socialNetworkCount + 1].icon,
                name: socialNetworks[socialNetworkCount + 1].name,
                link: socialNetworks[socialNetworkCount + 1].link,
                order: socialNetworks[socialNetworkCount + 1].order - 1,
            };

            const dataNetworkDown = {
                icon: socialNetworks[socialNetworkCount].icon,
                name: socialNetworks[socialNetworkCount].name,
                link: socialNetworks[socialNetworkCount].link,
                order: socialNetworks[socialNetworkCount].order + 1,
            };

            if (
                (await socialNetworkService.updateSocialNetwork(
                    dataNetworkUp,
                    socialNetworks[socialNetworkCount + 1].id
                )) &&
                (await socialNetworkService.updateSocialNetwork(
                    dataNetworkDown,
                    socialNetworks[socialNetworkCount].id
                ))
            ) {
                setTimeout(function () {
                    setIsProcessing(false);
                    handleNetworksChange(true);
                }, LOADING_TIME);
            }

            setIsProcessing(false);
        }
    };

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <Grid>
                    <Button
                        type="button"
                        floated="right"
                        icon
                        color="pink"
                        size="large"
                        labelPosition="left"
                        onClick={() => handleModalFormOpen(false)}
                        style={{
                            marginLeft: "1rem",
                            marginTop: "1rem",
                        }}
                    >
                        <Icon name="plus" /> Pridať sociálnu sieť
                    </Button>

                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <List
                            divided
                            verticalAlign="middle"
                            //relaxed="very"
                            style={{ marginTop: "2rem" }}
                        >
                            {socialNetworks.map(
                                (socialNetwork, socialNetworkCount) => (
                                    <ProfileSocialNetworkItem
                                        key={socialNetwork.id}
                                        socialNetwork={socialNetwork}
                                        socialNetworkCount={socialNetworkCount}
                                        isProcessing={isProcessing}
                                        socialNetworks={socialNetworks}
                                        moveOrderDown={moveOrderDown}
                                        moveOrderUp={moveOrderUp}
                                        handleModalFormOpen={
                                            handleModalFormOpen
                                        }
                                        confirmDelete={confirmDelete}
                                    />
                                )
                            )}
                        </List>
                    </Grid.Column>
                </Grid>
            )}

            <ProfileSocialNetworkForm
                modalFormOpen={modalFormOpen}
                handleModalFormClose={handleModalFormClose}
                isModalEditForm={isModalEditForm}
                socialNetworkData={socialNetworkData}
                socialNetworkList={socialNetworks}
                changeModalMessage={changeModalMessage}
                changeModalIsError={changeModalIsError}
                handleModalOpen={handleModalOpen}
                handleNetworksChange={handleNetworksChange}
            />

            <ModalConfirm
                open={isConfirmDeleteOpen}
                header="Vymazanie sociálnej siete"
                content="Si si istý(á), že chceš vymazať túto sociálnu sieť?"
                confirmButton="Áno"
                cancelButton="Nie"
                onCancel={handleConfirmCancel}
                onConfirm={handleConfirmDelete}
            />

            <ModalResponse
                open={modalOpen}
                onClose={handleModalClose}
                icon={modalIsError ? "warning circle" : "checkmark"}
                header={
                    modalIsError
                        ? "Aktualizácia neúspešná"
                        : "Aktualizácia úspešná"
                }
                message={modalMessage}
                onClick={handleModalClose}
            />
        </Fragment>
    );
}

export default ProfileSocialNetworks;
