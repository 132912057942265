// react
import React from "react";
import { Switch, Route } from "react-router-dom";
// store
import { authService } from "../../../../store/services/ServiceAuth";
import { routes } from "../../../../store/routes";
// components
import CoachNetwork from "./CoachNetwork";
import CoachNetworkRegister from "./CoachNetworkRegister";

// component
function CoachNetworkBase() {
    authService.useIsProvider();

    // template
    return (
        <Switch>
            <Route exact path={routes.COACH_NETWORK} component={CoachNetwork} />
            <Route
                exact
                path={routes.COACH_REGISTRATION}
                component={CoachNetworkRegister}
            />
        </Switch>
    );
}

export default CoachNetworkBase;
