// react
import React, { useState, useEffect, Fragment } from "react";
// store
import { addressService } from "../../store/services/ServiceAddress";
import { LOADING_TIME } from "../../store/config";
// components
import Loading from "../../components/others/Loading";
import Map from "../dashboard/provider/addresses/Map";

// component
function ActivityPlaceForm({ nextTutorial, backTutorial }) {
    // state
    const [isLoading, setIsLoading] = useState(false);
    const [center, setCenter] = useState({
        lat: 48.14696796312074,
        lng: 17.108176953442385,
    });
    const [addressID, setAddressID] = useState(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await addressService.getAddresses();

            if (result[0]) {
                setEdit(true);
                setCenter({
                    lat: parseFloat(result[0].latitude),
                    lng: parseFloat(result[0].longitude),
                });

                setAddressID(result[0].id);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Map
                        center={center}
                        height="300px"
                        zoom={14}
                        isEdit={edit}
                        tutorial={{
                            backTutorial: backTutorial,
                            nextTutorial: nextTutorial,
                            addressID: addressID,
                        }}
                    />
                </>
            )}
        </Fragment>
    );
}

export default ActivityPlaceForm;
