// react
import React from "react";
// store
import { routes } from "../../../../store/routes";
// components
import DimmerLoader from "../../../../components/others/DimmerLoader";
import DataTable from "../../../../components/tables/DataTable";
import ButtonIconEdit from "../../../../components/buttons/ButtonIconEdit";

// component
function ActivityTable({ data, isLoading }) {
    const orderedHeaders = [
        "id",
        "name",
        "tags",
        "status",
        "tableActions",
    ];

    const TableAction = ({ row }) => {
        return <ButtonIconEdit link={routes.ACTIVITIES_EDIT + row.id} />;
    };

    const headers = {
        id: {
            text: "ID",
            invisible: true,
        },
        name: {
            text: "Názov",
            filterable: true,
            sortable: true,
        },
        tags: {
            text: "Kľúčové slová",
            invisible: false,
            sortable: true,
        },
        status: {
            text: "Status",
            filterable: false,
            sortable: true,
            transform: (value) => `${value === true ? "Aktívny" : "Neaktívny"}`,
        },
        tableActions: {
            text: "Akcie",
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return <TableAction row={row} />;
            },
        },
    };

    // template
    return (
        <>
            <DimmerLoader active={isLoading} />

            <DataTable
                data={data}
                orderedHeaders={orderedHeaders}
                headers={headers}
                hideSearch={true}
            />
        </>
    );
}

export default ActivityTable;
