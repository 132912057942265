// react
import React, { Fragment, useEffect } from "react";
// store
import { authService } from "../../../store/services/ServiceAuth";
import { Role } from "../../../store/services/helpers/roles";
// components
import HeaderTitle from "../../../components/others/HeaderTitle";
import { adminPanes, providerPanes, userPanes } from "./ProfilePanes";
import { Tab } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../../index";

// component
function Profile() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Nastavenia profilu" />

            <section>
                <Tab
                    menu={{ fluid: true, style: { overflow: "auto" } }}
                    panes={
                        authService.useHasRole(Role.Admin)
                            ? adminPanes
                            : authService.useHasRole(Role.Provider)
                            ? providerPanes
                            : userPanes
                    }
                />
            </section>
        </Fragment>
    );
}

export default Profile;
