// API routes config
const origin = window.location.origin
const development = /*'http://localhost:8000/api'*/ 'https://dev.activitypoint.xyz/api'
const API_URL = origin === "http://localhost:3000" ? development : window.location.origin + '/api';


// for local testing purposes:
// const API_URL = "http://127.0.0.1:8000/api";

// for loading animation
export const LOADING_TIME = 250;

// tables pagination
export const PAGINATION_LIMIT = 10;

// cookies expiration in days
export const COOKIE_EXPIRATION = 30;

// export const googleMapsAPIKEY = "AIzaSyDeMwYICfoMpEB7VQRphfQiAAXt0HwiDCg"
export const googleMapsAPIKEY = "AIzaSyBkzqEbiNLSqnulRtInQZJDz7X-wbRWemo"

export const API = {
    LOGIN: API_URL + "/login/",
    REFRESH: API_URL + "/login/refresh/",
    REGISTER: API_URL + "/users/",
    REGISTER_REQUEST: API_URL + "/registrations/create",
    RESEND_ACTIVATION: API_URL + "/users/resend_activation/",
    RESET_PASSWORD: API_URL + "/users/reset_password/",
    RESET_CONFIRM: API_URL + "/users/reset_password_confirm/",
    SET_PASSWORD: API_URL + "/users/set_password/",

    // RegistrationRequests
    REGISTRATION_REQUEST_GET: API_URL + "/registrations/",
    REGISTRATION_REQUEST_APPROVE: API_URL + "/registrations/approve",
    REGISTRATION_REQUEST_DENY: API_URL + "/registrations/deny",

    // Bundles:
    GET_BUNDLES: API_URL + "/bundles/",
    CREATE_BUNDLE: API_URL + "/bundles/create",
    EDIT_OR_DELETE_BUNDLE: API_URL + "/bundle/", // + <id>

    // Activities:
    GET_ACTIVITIES: API_URL + "/activities/",
    CREATE_ACTIVITY: API_URL + "/activities/create",
    EDIT_OR_DELETE_ACTIVITY: API_URL + "/activity/", // + <id>

    // Categories:
    GET_ACTIVE_CATEGORIES: API_URL + "/categories/?status=true",
    GET_CATEGORIES: API_URL + "/categories/",
    CREATE_CATEGORY: API_URL + "/categories/create",
    EDIT_OR_DELETE_CATEGORY: API_URL + "/category/", // + <id>

    // Addresses:
    GET_ADDRESSES: API_URL + "/company/addresses/",
    CREATE_ADDRESS: API_URL + "/company/addresses/create",
    EDIT_OR_DELETE_ADDRESS: API_URL + "/company/address/", // + <id>
    BASE_ADDRESS: API_URL + "/common/base_address/",

    // Company:
    VERIFY_ACTIVITY: API_URL + "/company/activity/verify/",
    GET_COMPANY: API_URL + "/company/",
    UPDATE_COMPANY: API_URL + "/company/edit/",
    GET_COMPANY_ACTIVITIES: API_URL + "/company/offers/",
    DUPLICATE_COMPANY_OFFER: API_URL + "/company/offers/duplicate/", // + <id>
    CREATE_COMPANY_ACTIVITY: API_URL + "/company/offers/create",
    EDIT_OR_DELETE_COMPANY_ACTIVITY: API_URL + "/company/offer/", // + <id>
    COMPANY_IMAGES: API_URL + "/image/uploads/",
    GET_NETWORK: API_URL + "/coach/network/",
    BANK_ACCOUNT: API_URL + "/common/bank_account/",

    // User activation:
    GET_USER_ACTIVATION: API_URL + "/users/activate/", // + <uid> / + <token>

    // User profile:
    GET_USER_PROFILE: API_URL + "/user/profile/",
    EDIT_USER_PROFILE: API_URL + "/user/profile/edit/",

    // Countries
    GET_COUNTRIES: API_URL + "/countries/",
    EDIT_COUNTRY: API_URL + "/country/<id>",

    // Planning
    GET_EVENTS: API_URL + "/planning/calendar/events/",
    CREATE_EVENT: API_URL + "/planning/calendar/events/create",
    EDIT_OR_CANCEL_EVENT: API_URL + "/planning/calendar/events/edit/", // + <id>
    CANCEL_DAY_EVENTS: API_URL + "/planning/calendar/events/remove/",
    SET_HIGHLIGHTED: API_URL + "/planning/calendar/events/highlight/", // + id,

    // Future Events
    FUTURE_EVENTS: API_URL + "/planning/future_events/",

    // Search
    SEARCH: API_URL + "/search/v2/offers/", // + <id>
    BASKET: API_URL + "/basket/", // + <id>
    GET_SEARCH_ACTIVITY: API_URL + "/search/offer/", // + <id>
    GET_SEARCH_COMPANY: API_URL + "/search/company/", // + <id>
    GET_SEARCH_ADDRESS: API_URL + "/search/address/", // + <id>
    GET_EVENT: API_URL + "/search/event/", // + <id>

    // Social Networks
    GET_SOCIAL_NETWORKS: API_URL + "/socials/",
    CREATE_SOCIAL_NETWORK: API_URL + "/socials/create",
    EDIT_OR_DELETE_SOCIAL_NETWORK: API_URL + "/socials/", // + <id>

    VERIFY: API_URL + "/login/verify/",
    INVOICES: API_URL + "/invoices/",

    // Payments:
    PAYMENT_INIT_FOR_EVENTS: API_URL + "/payment-init/events/",
    PAYMENT_FINISH: API_URL + "/payment-finish/",
    GET_INVOICES_FOR_USERS: API_URL + "/invoices/user/",
    GET_DIGITAL_PRODUCTS_FOR_USER: API_URL + "/invoices/user/products/", // + <id>
    GET_INVOICE_BY_ID: API_URL + "/invoices/", // + <id>
    GET_INVOICES_FOR_PROVIDERS: API_URL + "/invoices/provider/",
    GET_DIGITAL_PRODUCTS_FOR_PROVIDERS: API_URL + "/invoices/provider/products/",
    GET_PROFITS: API_URL + "/invoices/profits/",
    GET_REWARDS: API_URL + "/invoices/rewards/",
    GENERATE_REWARDS: API_URL + "/invoices/rewards/generate/",

    // User Management
    GET_PROVIDERS: API_URL + "/providers/",
    GET_PROVIDERS_COMPANIES_AS_CHOICES: API_URL + "/providers/choices/companies/",
    GET_PROVIDERS_CREATE: API_URL + "/providers/create/",
    SET_PROVIDER_ACTIVE_STATUS: API_URL + "/providers/set-active-status/",
    SET_PROVIDER_TOWN_STATUS: API_URL + "/providers/set-town-status/",
    SET_PROVIDER_COACH_STATUS: API_URL + "/providers/set-coach-status/", // + id

    // Stats
    GET_STATS: API_URL + "/stats/",
    GET_STATS_USERS_BY_ACTIVITY: API_URL + "/stats/users/by-activities/",

    // Common
    GET_CHOICES: API_URL + "/common/choices/",
    SEND_CONTACT_EMAIL: API_URL + "/send_mail/contact/",
    SEND_TOWN_REGISTER_EMAIL: API_URL + "/send_mail/town-registration/",
};
