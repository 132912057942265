// react
import React, { useEffect, useState } from "react";
// store
import { socialNetworkOptions } from "../../../store/services/helpers/settings";
import { socialNetworkService } from "../../../store/services/ServiceSocials";
import { LOADING_TIME } from "../../../store/config";
import {
    isEmpty,
    isEmptyErrorList,
    isURLError,
} from "../../../store/services/helpers/validation";
// components
import ButtonSubmit from "../../../components/buttons/ButtonSubmit";
import { Form, Header, Modal, Dropdown } from "semantic-ui-react";

// component
function ProfileSocialNetworksForm({
    modalFormOpen,
    handleModalFormClose,
    isModalEditForm,
    socialNetworkData,
    socialNetworkList,
    changeModalMessage,
    changeModalIsError,
    handleModalOpen,
    handleNetworksChange,
}) {
    // state
    const [socialName, setSocialName] = useState("");
    const [socialIcon, setSocialIcon] = useState("");
    const [socialLink, setSocialLink] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (isModalEditForm) {
            setSocialName(socialNetworkData.name);
            setSocialIcon(socialNetworkData.icon);
            setSocialLink(socialNetworkData.link);
        }
    }, [socialNetworkData, isModalEditForm]);

    // close modal form
    const closeModalForm = () => {
        resetForm();
        handleModalFormClose();
    };

    // reset form
    const resetForm = () => {
        setSocialName("");
        setSocialIcon("");
        setSocialLink("");
    };

    // component handlers
    const handleSocialIconChange = (e, data) => {
        setSocialIcon(data.value);
    };

    // submit form - create or edit new social network
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        let formError = false;

        // validate form fields
        if (isURLError(socialLink)) {
            formError = true;
        }

        if (!formError) {
            const data = {
                icon: socialIcon,
                name: socialName,
                link: socialLink,
                order: isModalEditForm
                    ? socialNetworkData.order
                    : socialNetworkList.length + 1,
            };

            if (isModalEditForm) {
                // edit existing social network
                if (
                    await socialNetworkService.updateSocialNetwork(
                        data,
                        socialNetworkData.id
                    )
                ) {
                    setTimeout(function () {
                        setIsProcessing(false);
                        changeModalMessage(
                            "Uloženie sociálnej siete prebehlo úspešne."
                        );
                        changeModalIsError(false);
                        handleNetworksChange(true);
                        handleModalOpen();
                    }, LOADING_TIME);
                } else {
                    changeModalMessage(
                        "Počas ukladanie sociálnej siete došlo k chybe!"
                    );
                    changeModalIsError(true);
                    setIsProcessing(false);
                    handleModalOpen();
                }
            }
            // create new social network
            else {
                if (await socialNetworkService.createSocialNetwork(data)) {
                    changeModalMessage(
                        "Uloženie sociálnej siete prebehlo úspešne."
                    );
                    changeModalIsError(false);
                    setIsProcessing(false);
                    handleNetworksChange(true);
                    handleModalOpen();
                } else {
                    changeModalMessage(
                        "Počas ukladanie sociálnej siete došlo k chybe!"
                    );
                    changeModalIsError(true);
                    setIsProcessing(false);
                    handleModalOpen();
                }
            }

            closeModalForm();
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Modal
            open={modalFormOpen}
            onClose={closeModalForm}
            closeIcon
            closeOnDimmerClick={false}
            size="tiny"
        >
            <Header
                icon="globe"
                content={
                    isModalEditForm
                        ? "Upraviť sociálnu sieť"
                        : "Pridať novú sociálnu sieť"
                }
            />
            <Modal.Content>
                <Form onSubmit={handleSubmit}>
                    <Form.Input
                        required
                        autoFocus
                        fluid
                        label="Názov sociálnej siete"
                        placeholder="Názov sociálnej siete"
                        value={socialName}
                        onChange={(e) => setSocialName(e.target.value)}
                    />

                    <Form.Field>
                        <label>Ikona sociálnej siete</label>
                        <Dropdown
                            required
                            placeholder="Ikona sociálnej siete"
                            fluid
                            selection
                            options={socialNetworkOptions}
                            value={socialIcon}
                            onChange={handleSocialIconChange}
                        />
                    </Form.Field>

                    <Form.Input
                        required
                        type="url"
                        fluid
                        label="Odkaz sociálnej siete"
                        placeholder="http://www.mysite.com"
                        value={socialLink}
                        onChange={(e) => setSocialLink(e.target.value)}
                        error={
                            !isEmpty(socialLink)
                                ? isURLError(socialLink)
                                : false
                        }
                    />

                    <ButtonSubmit
                        loading={isProcessing}
                        disabled={
                            isProcessing ||
                            !isEmptyErrorList([
                                socialName,
                                socialIcon,
                                socialLink,
                            ])
                        }
                        text="Uložiť"
                    />
                </Form>
            </Modal.Content>
        </Modal>
    );
}

export default ProfileSocialNetworksForm;
