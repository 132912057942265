// react
import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
import { providerOptions } from "../../store/services/helpers/settings";
import {
    isEmpty,
    isPhoneError,
    isEmailError,
    isEmptyErrorList,
    isICOError,
} from "../../store/services/helpers/validation";
import { countryService } from "../../store/services/ServiceCountry";
import { LOADING_TIME } from "../../store/config";
import { authService } from "../../store/services/ServiceAuth";
import { setSelectOptionsCountries } from "../../store/services/helpers/functions";
// components
import Loading from "../../components/others/Loading";
import ButtonSubmit from "../../components/buttons/ButtonSubmit";
import ButtonBack from "../../components/buttons/ButtonBack";
import RegisterProviderActivation from "./RegisterProviderActivation";
import RegisterCheckbox from "./RegisterCheckbox";
import RegisterMessageLogin from "./RegisterMessageLogin";
import ModalResponse from "../../components/modals/ModalResponse";
import { Form, Divider } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../index";

// component
function RegisterProvider(props) {
    // state
    const [success, setSuccess] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [providerType, setProviderType] = useState("1");
    const [companyName, setCompanyName] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [nickname, setNickname] = useState("");
    const [country, setCountry] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [message, setMessage] = useState("");
    const [isCoach, setIsCoach] = useState(false);
    const [isCheckboxError, setIsCheckboxError] = useState(false);

    const user = useSelector((state) => state.user);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // fetch data - country select options
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const countriesData = await countryService.getCountries();
            setCountryList(countriesData);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // set country select options
    const countryOptions = setSelectOptionsCountries(countryList);

    /** component functions **/
    const handleOnChangeProviderType = (e, data) => {
        setProviderType(data.value);
    };

    const handleOnChangeCountry = (e, data) => {
        setCountry(data.value);
    };

    const handleOnChangeCheckbox = (e, data) => {
        if (isCheckboxError) {
            setIsCheckboxError(false);
        }
    };

    const handleOnChangeCheckboxCoach = (e, data) => {
        setIsCoach(data.checked);
    };

    const handleRegister = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        let formError = false;

        // validate form fields
        if (
            isEmailError(email) ||
            isPhoneError(phone) ||
            isICOError(idNumber)
        ) {
            formError = true;
        }
        // validate form checkbox
        if (!document.getElementById("checkbox-register-provider").checked) {
            setIsCheckboxError(true);
            formError = true;
        }

        // register user when form is valid, else show form error
        if (!formError) {
            const data = {
                providerType: parseInt(providerType),
                firstname: firstName,
                lastname: lastName,
                phone: phone,
                email: email,
                id_number: idNumber,
                company_name: companyName,
                nickname: nickname,
                country: country,
                message: message,
                is_coach: isCoach,
                registered_by: props.coach ? user.id : null,
            };

            let result = await authService.registerRequest(data);

            if (result.status !== 200) {
                if (result.response.email) {
                    setErrorMessage("Emailová adresa už je obsadená.");
                } else if (result.response.id_number) {
                    setErrorMessage("Zadané IČO už existuje!");
                } else {
                    setErrorMessage("Pri registrácii nastala chyba!");
                }

                setErrorOpen(true);
                setSuccess(false);
                setIsProcessing(false);
            } else {
                setSuccess(true);
                setIsProcessing(false);
            }
        }
        // show form error
        else {
            setSuccess(false);
        }
        setIsProcessing(false);
    };

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <Fragment>
                    <ButtonBack
                        link={
                            props.coach ? routes.COACH_NETWORK : routes.REGISTER
                        }
                    />
                    {!success ? (
                        <Fragment>
                            <Form
                                onSubmit={handleRegister}
                                className="attached fluid segment"
                            >
                                <Form.Group>
                                    <Form.Select
                                        style={{
                                            height: "3rem",
                                            paddingTop: "1rem",
                                        }}
                                        width={4}
                                        size="large"
                                        required
                                        fluid
                                        label="Typ poskytovateľa"
                                        placeholder="Typ poskytovateľa"
                                        options={providerOptions}
                                        value={providerType}
                                        onChange={handleOnChangeProviderType}
                                    />
                                    <Form.Input
                                        width={4}
                                        size="large"
                                        required
                                        fluid
                                        label="IČO"
                                        placeholder="IČO"
                                        value={idNumber}
                                        onChange={(e) =>
                                            setIdNumber(e.target.value)
                                        }
                                        error={
                                            !isEmpty(idNumber)
                                                ? isICOError(idNumber)
                                                : false
                                        }
                                    />
                                    <Form.Input
                                        width={8}
                                        size="large"
                                        required
                                        fluid
                                        label="Názov spoločnosti"
                                        placeholder="Názov spoločnosti"
                                        value={companyName}
                                        onChange={(e) =>
                                            setCompanyName(e.target.value)
                                        }
                                    />
                                </Form.Group>
                                <Divider horizontal>
                                    Kontaktné informácie
                                </Divider>
                                <Form.Group width={16} widths="equal">
                                    <Form.Input
                                        size="large"
                                        required
                                        fluid
                                        label="Meno"
                                        placeholder="Meno"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                    />
                                    <Form.Input
                                        size="large"
                                        required
                                        fluid
                                        label="Priezvisko"
                                        placeholder="Priezvisko"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                    />
                                </Form.Group>
                                <Form.Group width={16} widths="equal">
                                    <Form.Input
                                        size="large"
                                        required
                                        fluid
                                        label="Email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        error={
                                            !isEmpty(email)
                                                ? isEmailError(email)
                                                : false
                                        }
                                    />
                                    <Form.Input
                                        size="large"
                                        required
                                        fluid
                                        label="Telefónne číslo"
                                        placeholder="+421901234567"
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                        error={
                                            !isEmpty(phone)
                                                ? isPhoneError(phone)
                                                : false
                                        }
                                    />
                                </Form.Group>
                                <Divider horizontal>
                                    Doplňujúce informácie
                                </Divider>
                                <Form.Group width={16} widths="equal">
                                    <Form.Input
                                        size="large"
                                        fluid
                                        label="Prezývka"
                                        placeholder="Prezývka"
                                        value={nickname}
                                        onChange={(e) =>
                                            setNickname(e.target.value)
                                        }
                                    />
                                    <Form.Select
                                        size="large"
                                        fluid
                                        label="Krajina"
                                        placeholder="Krajina"
                                        options={countryOptions}
                                        value={country}
                                        onChange={handleOnChangeCountry}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.TextArea
                                        width={16}
                                        size="large"
                                        label="Správa k žiadosti"
                                        placeholder="Tu napíš jednoduchú správu, prečo sa chceš stať súčasťou komunity ActivityPoint"
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                    />
                                </Form.Group>

                                <RegisterCheckbox
                                    checkboxId="checkbox-register-provider"
                                    onChange={handleOnChangeCheckbox}
                                    isCheckboxError={isCheckboxError}
                                />

                                {true ? (
                                    ""
                                ) : (
                                    <Form.Checkbox
                                        id="checkbox-is-coach"
                                        label={{
                                            children: (
                                                <p>
                                                    Týmto žiadam o profil{" "}
                                                    <strong>kouča</strong> a
                                                    potvrdzujem, že som si
                                                    prečítal
                                                    <Link to="#">
                                                        {" "}
                                                        zmluvné podmienky
                                                    </Link>
                                                    .
                                                </p>
                                            ),
                                        }}
                                        onChange={handleOnChangeCheckboxCoach}
                                    />
                                )}

                                <ButtonSubmit
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        !isEmptyErrorList([
                                            providerType,
                                            firstName,
                                            lastName,
                                            email,
                                            phone,
                                            companyName,
                                            idNumber,
                                        ]) ||
                                        isICOError(idNumber) ||
                                        isEmailError(email) ||
                                        isPhoneError(phone)
                                    }
                                    text="Registrovať"
                                />
                            </Form>

                            {props.coach ? "" : <RegisterMessageLogin />}
                        </Fragment>
                    ) : (
                        <RegisterProviderActivation email={email} />
                    )}
                </Fragment>
            )}

            <ModalResponse
                open={errorOpen}
                onClose={() => setErrorOpen(false)}
                icon="warning circle"
                header="Chyba"
                message={errorMessage}
                onClick={() => setErrorOpen(false)}
            />
        </Fragment>
    );
}

export default RegisterProvider;
