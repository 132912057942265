// react
import React from "react";
// components
import ActivityDetailMap from "../activity-detail/ActivityDetailMap";
import { Header, Label, Grid, Divider, Button } from "semantic-ui-react";
// others
import moment from "moment";

// component
function ActivityTab({ activity, event }) {
    // template
    return (
        <>
            <Grid columns="equal">
                <Grid.Column width={8}>
                    <Grid columns="equal">
                        <Grid.Column width={16}>
                            <Label.Group
                                size="medium"
                                style={{
                                    fontAlign: "center",
                                    paddingTop: "5px",
                                }}
                            >
                                <Label color="pink" tag>
                                    {moment(event.starts).format(
                                        "DD.MM.YYYY HH:mm"
                                    )}
                                </Label>
                                <Label color="pink" tag>
                                    {`${activity.duration} min`}
                                </Label>
                            </Label.Group>
                            <Divider />
                            <Header as="h3">Popis</Header>
                            <p>{activity.description}</p>

                            <Header as="h3">Cena</Header>
                            <p>{activity.price} €</p>

                            <Header as="h3">Kapacita</Header>
                            <p>{activity.free_spots}</p>

                            <Button color="pink">Overiť dostupnosť</Button>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={8}>
                    <ActivityDetailMap
                        latitude={event.address.latitude}
                        longitude={event.address.longitude}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
}

export default ActivityTab;
