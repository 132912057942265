// react
import React from "react";
// components
import { Button, Icon } from "semantic-ui-react";

// component
function ButtonAddClick({ text, onClick }) {
    // template
    return (
        <Button
            type="button"
            floated="right"
            labelPosition="left"
            size="large"
            color="pink"
            icon
            style={{
                marginLeft: "1rem",
                marginTop: "1rem",
                marginBottom: "1rem",
            }}
            onClick={onClick}
        >
            <Icon name="plus" /> {text}
        </Button>
    );
}

export default ButtonAddClick;
