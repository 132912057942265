// validation input rules
export const isEmpty = (input) => {
    return input === "";
};

export const isNotEmpty = (input) => {
    return input !== "";
};

export const isEmptyErrorList = (input) => {
    return input.every(isNotEmpty);
};

export const isEmailError = (input) => {
    if (
        !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            input
        )
    ) {
        return {
            content: "Zadaná emailová adresa je uvedená v nesprávnom formáte",
        };
    }
    return false;
};

export const isPositive = (input) => {
    if (input < 0) {
        return {
            content: "Zadaj hodnotu väčšiu než 0",
        };
    }
    return false;
};

export const isHigher = (input, higher_value) => {
    if (input <= 0) {
        return {
            content: "Zadaj hodnotu väčšiu než 0",
        };
    }

    if (parseFloat(input) > parseFloat(higher_value)) {
        return {
            content: "Hodnota by mala byť nižšia než " + higher_value,
        };
    }
    return false;
};

export const isSpecialOfferError = (input, higher_value) => {
    if (!/^[0-9]+[.,][0-9]{2}$/.test(input)) {
        return {
            content: "Zadaná hodnota nie je platná",
        };
    }

    if (input < 0) {
        return {
            content: "Zadaj hodnotu väčšiu než 0",
        };
    }

    if (parseFloat(input) > parseFloat(higher_value)) {
        return {
            content: "Hodnota musí byť nižšia alebo rovná " + higher_value,
        };
    }
    return false;
};

// password rules: 1 big letter, 1 number, 8 characters minimum
export const isPasswordError = (input) => {
    if (!/^(?=.*\d)(?=.*[A-Z]).{8,}$/.test(input)) {
        return {
            content: "Zadané heslo nie je dostatočne silné",
        };
    }
    return false;
};

export const isRepeatPasswordError = (password, repeatPassword) => {
    if (password !== repeatPassword) {
        return {
            content: "Zadané heslá sa nezhodujú",
        };
    }
    return false;
};

export const isPhoneError = (input) => {
    if (!/^[+][0-9]{2,3}[0-9+]{10,15}$/.test(input)) {
        return {
            content: "Zadané číslo je uvedené v nesprávnom formáte",
        };
    }
    return false;
};

export const isNumberError = (input) => {
    if (!/^(0|[1-9][0-9]*)$/.test(input)) {
        return {
            content: "Celočíselná hodnota musí byť väčšia alebo rovná 0",
        };
    }
    return false;
};

export const isDateError = (input) => {
    if (
        !/(^(19|[2-9][0-9])\d\d[-](((0[1-9]|1[012])[-](0[1-9]|1[0-9]|2[0-8]))|((0[13578]|1[02])[-](29|30|31))|((0[469]|11)[-](29|30)))$)|(^(19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)[-]02[-]29$)/.test(
            input
        )
    ) {
        return {
            content: "Zadaný dátum je uvedený v nesprávnom formáte",
        };
    }
    return false;
};

export const isTimeError = (input) => {
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(input)) {
        return {
            content: "Zadaný čas je uvedený v nesprávnom formáte",
        };
    }
    return false;
};

export const isURLError = (input) => {
    if (
        !/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/.test(
            input
        )
    ) {
        return {
            content: "Zadaný odkaz je uvedený v nesprávnom formáte",
        };
    }
    return false;
};

export const isStreetNumberError = (input) => {
    if (!/^([1-9][0-9]*([/][1-9][0-9]*)?)$/.test(input)) {
        return {
            content: "Zadaná hodnota je uvedená v nesprávnom formáte",
        };
    }
    return false;
};

export const isICOError = (input) => {
    if (!/^([0-9]{8})$/.test(input)) {
        return {
            content: "Zadaná hodnota je uvedená v nesprávnom formáte",
        };
    }
    return false;
};

export const isDICError = (input) => {
    if (!/^([0-9]{10})$/.test(input)) {
        return {
            content: "Zadaná hodnota je uvedená v nesprávnom formáte",
        };
    }
    return false;
};

export const isICDPHError = (input) => {
    if (!/^SK([0-9]{10})$/.test(input)) {
        return {
            content: "Zadaná hodnota je uvedená v nesprávnom formáte",
        };
    }
    return false;
};

export const isPostCodeError = (input) => {
    if (!/^([0-9]{5})$/.test(input)) {
        return {
            content: "Zadaná hodnota je uvedená v nesprávnom formáte",
        };
    }
    return false;
};

export const isDurationError = (input) => {
    if (!/^([1-9][0-9]*)$/.test(input)) {
        return {
            content: "Celočíselná hodnota musí byť väčšia ako 0",
        };
    }
    return false;
};

export const isPriceError = (input) => {
    if (!/^[0-9]+[.,][0-9]{2}$/.test(input)) {
        return {
            content: "Zadaná hodnota nie je platná",
        };
    }
    return false;
};
