// react
import React from "react";
import { useHistory } from "react-router-dom";
// store
import { routes } from "../../../../store/routes";
import { truncateString, handleSourceReference } from "../../../../store/services/helpers/functions";
// components
import { Card, Button } from "semantic-ui-react";

// component
function MyActivityItem({ data, handleCopy }) {
    let history = useHistory();
    let image = null;

    for (let i = 0; i < data.gallery.length; i++) {
        if (data.gallery[i].id === data.mainImage) {
            image = data.gallery[i].source;
        }
    }

    const handleEdit = () => {
        history.push(routes.MY_ACTIVITIES_EDIT + data.id);
    };

    // template
    return (
        <Card color="pink" fluid style={{ marginBottom: "2rem" }}>
            <div
                style={{
                    width: "100%",
                    height: "165px",
                    backgroundImage: image
                        ? `url(${handleSourceReference(image)})`
                        : "url(/images/general/image_placeholder.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div>
            <Card.Content>
                <Card.Header title={data.name}>
                    {truncateString(data.name, 20)}
                </Card.Header>
                <Card.Meta>
                    <strong>{data.price} €</strong>
                </Card.Meta>
                <Card.Meta>Pridané {data.created}</Card.Meta>
            </Card.Content>
            <Card.Content extra>
                <Button.Group fluid>
                    <Button
                        size="tiny"
                        color="pink"
                        content="Upraviť"
                        onClick={handleEdit}
                        attached="left"
                        style={{ textAlign: "center" }}
                    />
                    <Button
                        size="tiny"
                        content="Kopírovať"
                        onClick={handleCopy}
                        attached="right"
                        style={{ textAlign: "center" }}
                    />
                </Button.Group>
            </Card.Content>
        </Card>
    );
}

export default MyActivityItem;
