// react
import React, { Fragment } from "react";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import RegisterProvider from "../../../register/RegisterProvider";

// component
function CoachNetworkRegister() {
    // template
    return (
        <Fragment>
            <HeaderTitle title="Kouč - sieť poskytovateľov" />
            <section>
                <RegisterProvider coach={true} />
            </section>
        </Fragment>
    );
}

export default CoachNetworkRegister;
