import request from "umi-request";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";

/*
 * Service functions
 */
export const userProfileService = {
    getUserProfile,
    updateUserProfile,
};

async function getUserProfile() {
    let data;
    // create Async - Await request
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_USER_PROFILE, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function updateUserProfile(data) {
    let updated;
    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_USER_PROFILE, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}
