import moment from 'moment';

function GetFormattedDate(date) {
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var year = date.getFullYear();
    var hour = ('0' + date.getHours()).slice(-2);
    var min = ('0' + date.getMinutes()).slice(-2);
    var seg = ('0' + date.getSeconds()).slice(-2);
    return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + seg;
}

export function utcToLocal(date) {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return GetFormattedDate(newDate);
}

export const isBeforeCurrentTime = (datetime) => {
    const format = "YYYY-MM-DD HH:mm";
    const toCompareCurrent = moment(moment(), format).format(format);
    const toCompareBefore = moment(datetime, format).format(format);

    return moment(toCompareCurrent, format).isBefore(toCompareBefore)
}
