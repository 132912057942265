// react
import React from "react";

// component
function HeaderTitle(props) {
    // template
    return (
        <header style={props.style}>
            <h1>{props.title}</h1>
        </header>
    );
}

export default HeaderTitle;
