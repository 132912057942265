// react
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
// store
import { googleMapsAPIKEY } from "../../store/config";
import { setSearchConfig } from "../../store/actions";
import { searchService } from "../../store/services/ServiceSearch";
// components
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import SearchMapOverlay from "./SearchMapOverlay";
import InfoWindowView from "./InfoWindowView";
import Loading from "../../components/others/Loading";
// others
import moment from "moment";
import uuid from 'react-uuid'
import "moment/min/locales.min";
import { GAPageView, initGA } from "../../index";
moment.locale("sk");

const mapStyles = {
    height: "100vh",
    width: "100%",
};

const libraries = ["places", "geometry", "drawing"];

const defaultOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    styles: [
        {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
        },
    ],
};
const marker = {
    url: "/images/icons/AP_Map_Pointer.svg",
    scaledSize: { width: 70, height: 70 },
    anchor: { x: 35, y: 35 },
};
const markerActivities = {
    url: "/images/icons/icon_map_activities.svg",
    scaledSize: { width: 35, height: 35 },
    anchor: { x: 17.5, y: 17.5 },
};

// component
function Search() {
    // redux stuff
    const searchData = useSelector((state) => state?.search);
    let dispatch = useDispatch();

    // helper data holding states
    const [response, setResponse] = useState(null);
    const [loadConfigSettings, setLoadConfigSettings] = useState(false);

    // search config
    const [activityTypes, setActivityTypes] = useState(searchData.activities);

    // states related to positions
    const [currentPosition, setCurrentPosition] = useState({
        lat: searchData.location.latitude,
        lng: searchData.location.longitude,
    });

    // states related to activities
    const [selectedAddress, setSelectedAddress] = useState({});
    const [foundActivities, setFoundActivities] = useState([]);
    const [visibleEvents, setVisibleEvents] = useState([]);

    // states related to filters
    const [distance, setDistance] = useState(searchData.distance || 10);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [time, setTime] = useState(moment().format('HH:00'));

    // states related to UI
    const [searching, setSearching] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [loadAtStart, setLoadAtStart] = useState(false);
    const [locationSetManually, setLocationSetManually] = useState(
        searchData.locationSetManually
    );

    // helper functions to set the current position
    const initCurrentPosition = useCallback(() => {
        if ("geolocation" in navigator) {
            if( navigator.permissions && navigator.permissions?.query ) {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then((result) => {
                        if (["granted", "prompt"].includes(result.state)) {
                            navigator.geolocation.getCurrentPosition(
                                getCurrentPositionCallback
                            );
                        }
                    });
            } else {
                navigator.geolocation.getCurrentPosition(
                    getCurrentPositionCallback
                );
            }
        }
    }, []);

    const getCurrentPositionCallback = (position) => {
        const coords = position?.coords;
        const { latitude, longitude } = coords;

        if( window?.document?.querySelector("#map-search-bar")?.length > 0 ){
            window.document.querySelector("#map-search-bar").value = "";
        }

        // set the current position
        setCurrentPosition({
            lat: latitude,
            lng: longitude,
        });
        setLocationSetManually(false);
    };

    useEffect(() => {
        initGA();
        GAPageView();
    }, [])

    useEffect(() => {
        // set the current location initially
        // if a user has already selected some specific location - use it, otherwise use his actual location
        // if (
        //     !searchData?.location?.longitude &&
        //     !searchData?.location?.latitude
        // ) {
            initCurrentPosition();
        // }
    }, [initCurrentPosition/*, searchData*/]);

    // function to handle searching - event should be null by default since we want to use this method
    // not onClick only
    // location should be also null since we want to search for the currentPosition initially
    const handleSearch = async (event = null, location = null) => {
        if (!mapLoaded) return;

        // if we don't have an event - don't call it - we are avoiding a potential error here
        if (event) event.preventDefault();

        // batch setStates - reset
        setResponse(null);
        setFoundActivities([]);
        setVisibleEvents([]);
        setSearching(true);
        setLoadConfigSettings(false);


        // set new search config to the redux store
        // TODO - Delete date and time saving ... unnecessary feature
        const data = {
            location: {
                latitude: parseFloat(location?.lat || currentPosition.lat),
                longitude: parseFloat(location?.lng || currentPosition.lng),
            },
            distance: distance,
            date: date,
            time: time,
            country: "SK",
            activities: activityTypes,
            locationSetManually: locationSetManually,
        };

        dispatch(setSearchConfig(data));

        const activities = await searchService.search(data);
        const newActivities = packDuplicates(activities);

        // end searching, set found activities and batch setStates
        setFoundActivities(newActivities);
        setVisibleEvents(newActivities)
        setSearching(false);
    };

    // search whenever the current position changes
    useEffect(() => {
        const search = async () => {
            await handleSearch(null, currentPosition);
        };

        search();

        // eslint-disable-next-line
    }, [currentPosition]);

    const packDuplicates = (activities) => {
        let packedActivites = []
        let alreadyPacked = []
        
        let remainingActivities = [...activities]
        for (let i = 0; i < activities.length; i++) {
            let current = activities[i]
            if( !alreadyPacked.includes(current.id) ){
                const matched = remainingActivities.filter(activity => compareAddressLocation(current, activity) && activity.id !== current.id)
                let packedList = matched.length > 0 ? [current, ...matched] : [current]
    
                alreadyPacked = [...alreadyPacked, ...packedList.map(item => item.id)]
                remainingActivities= remainingActivities.filter(activity => !packedList.map(item => item.id).includes(activity.id))
                packedActivites.push(packedList)
            }
        }

        return packedActivites
    };

    const searchConfig = () => {
        setLoadConfigSettings(true);
    };

    // select new positions
    const onPlaceSelected = (place) => {
        if (place?.geometry) {
            setLocationSetManually(true);
            setCurrentPosition({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            });
        }
    };

    // select new location whenever the marker is dragged
    const markerDragged = (event) => {
        let newLat = event.latLng.lat();
        let newLng = event.latLng.lng();

        setLocationSetManually(true);

        // handleSearch(null, {
        //     lat: newLat,
        //     lng: newLng,
        // });

        setCurrentPosition({
            lat: newLat,
            lng: newLng,
        });
    };

    const compareAddressLocation = (a, b) => {
        if( (a?.address?.latitude === b?.address?.latitude) && (a?.address?.longitude === b?.address?.longitude) ){
            return true
        }
        
        return false
    }

    const compareLocation = (a, b) => {
        if( (a?.address?.latitude === b?.latitude) && (a?.address?.longitude === b?.longitude) ){
            return true
        }
        
        return false
    }

    // selecting the specific event
    const onSelectMarker = (event) => {
        setSelectedAddress({});
        setResponse(null);
        setVisibleEvents(foundActivities.filter(events => compareLocation(events?.[0], event) ))
        setSelectedAddress(event);
    };

    // template
    return (
        <>
            <LoadScript
                googleMapsApiKey={ googleMapsAPIKEY }
                libraries={libraries}
                loadingElement={<Loading />}
                preventGoogleFontsLoading={false}
            >
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={11.8}
                    center={currentPosition}
                    options={defaultOptions}
                    onLoad={() => {
                        setMapLoaded(true);
                    }}
                    onClick={() => {
                        setResponse(null);
                        setSelectedAddress({});
                        setVisibleEvents(foundActivities)
                    }}
                    onTilesLoaded={async () => {
                        if (!loadAtStart) {
                            await handleSearch(null, currentPosition);

                            setLoadAtStart(true);
                        }
                    }}
                >
                    {selectedAddress?.id ? (
                        <>
                            {selectedAddress.latitude &&
                                currentPosition.lat &&
                                response == null && (
                                    <DirectionsService
                                        options={{
                                            destination: {
                                                lat: parseFloat(
                                                    selectedAddress.latitude
                                                ),
                                                lng: parseFloat(
                                                    selectedAddress.longitude
                                                ),
                                            },
                                            origin: currentPosition,
                                            travelMode: "DRIVING",
                                        }}
                                        callback={(response) => {
                                            setResponse(response);
                                        }}
                                    />
                                )}
                            {response !== null && (
                                <DirectionsRenderer
                                    options={{
                                        directions: response,
                                    }}
                                />
                            )}
                        </>
                    ) : null}

                    {currentPosition?.lat ? (
                        <Marker
                            style={{ zIndex: 10000 }}
                            icon={marker}
                            position={currentPosition}
                            draggable={true}
                            onDragEnd={(event) => markerDragged(event)}
                            title="Aktuálna poloha"
                        />
                    ) : null}


                    {foundActivities.map((activity, idx) => (
                        <React.Fragment key={uuid()}/*key={idx + Math.floor(Math.random() * 30)}*/>
                            {activity.length > 0 && (
                                <Marker
                                    zIndex={activity.filter(item => item.is_highlighted).length > 0 && 9999}
                                    key={uuid()}/*key={idx + Math.floor(Math.random() * 30)}*/
                                    title={activity?.[0].address?.name}
                                    label={
                                        activity.length > 1
                                            ? {
                                                  text: `${activity?.length}`,
                                                  color: activity.filter(item => item.is_highlighted).length > 0 ? "black" : "white",
                                              }
                                            : ""
                                    }
                                    icon={{
                                        ...markerActivities,
                                        url:
                                            activity?.[0].event_type ===
                                            "future"
                                                ? "images/icons/AP_Map_Pointer_future_terms.svg"
                                                : activity.filter(item => item.event_type === "solidarity").length > 0
                                                ? activity.filter(item => item.is_highlighted).length > 0 ? "images/icons/Activitypoint_solidarity_map_pointer_special.svg" : "images/icons/AP_Map_Pointer_solidarity_terms.svg"
                                                : "images/icons/icon_map_activities.svg",
                                        scaledSize:
                                            activity?.[0].event_type ===
                                            "business"
                                                ? { width: 35, height: 35 }
                                                : { width: 40, height: 40 },
                                        anchor:
                                            activity?.[0].event_type ===
                                            "business"
                                                ? { x: 17.5, y: 17.5 }
                                                : { x: 20.0, y: 20.0 },
                                    }}
                                    clickable={true}
                                    position={{
                                        lat: parseFloat(
                                            activity?.[0].address?.latitude
                                        ),
                                        lng: parseFloat(
                                            activity?.[0].address?.longitude
                                        ),
                                    }}
                                    onClick={() =>
                                        onSelectMarker(activity?.[0].address)
                                    }
                                >
                                    { (selectedAddress.latitude ===  activity?.[0].address?.latitude && selectedAddress.longitude ===  activity?.[0].address?.longitude) && 
                                        <InfoWindow>
                                            <InfoWindowView event={activity?.[0]} userPosition={currentPosition} mapLoaded={mapLoaded}/>
                                        </InfoWindow>
                                    }
                                </Marker>
                            )}

                        </React.Fragment>
                    ))}

                    <SearchMapOverlay
                        selectedMarker={selectedAddress}
                        usersPosition={currentPosition}
                        mapLoaded={mapLoaded}
                        autocomplete={onPlaceSelected}
                        searchMyLocation={initCurrentPosition}
                        searchFunc={handleSearch}
                        searchConfig={searchConfig}
                        loadConfigSettings={loadConfigSettings}
                        handleSearch={handleSearch}
                        searching={searching}
                        foundActivities={visibleEvents}
                        config={{
                            distance,
                            date,
                            time,
                            activityTypes,
                        }}
                        setDistanceFunc={setDistance}
                        setDateFunc={setDate}
                        setTimeFunc={setTime}
                        setActivityTypesFunc={setActivityTypes}
                        uuid={uuid}
                    />
                </GoogleMap>
            </LoadScript>
        </>
    );
}

export default Search;
