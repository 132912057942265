// react
import React, { Fragment, useEffect, useState } from "react";
// store
import { bundleService } from "../../../../store/services/ServiceBundle";
import { LOADING_TIME } from "../../../../store/config";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import BundleForm from "./BundleForm";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function BundleDetail(props) {
    // state
    const [bundle, setBundle] = useState({});
    const [bundleId] = useState(props.match.params.id);
    const [isLoading, setIsLoading] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await bundleService.getBundleById(bundleId);
            setBundle(result[0]);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, [bundleId]);

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HeaderTitle title={`Balíček: ${bundle.name}`} />
                    <section className="bundles">
                        <BundleForm data={bundle} />
                    </section>
                </>
            )}
        </Fragment>
    );
}

export default BundleDetail;
