// react
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { createBrowserHistory } from "history";
// store
import { persistor, store } from "./store";
// other
import * as serviceWorker from "./serviceWorker";
import ReactGa from "react-ga"; // Google analytics Tracker
import ReactPixel from 'react-facebook-pixel'; // Facebook Pixel Tracker
// import ReactGA from 'react-ga';

// components
import App from "./App";

// Google analytics initialization
export const initGA = () => {
    if( window.location.origin !== "http://localhost:3000" ){
        let match = document.cookie.match(new RegExp('(^| )APcookie=([^;]+)'));
        if (match) {
            if(match[2]){
                ReactGa.initialize(
                    [
                      {
                        trackingId: 'UA-36106839-25',
                        gaOptions: {
                          name: 'tracker1',
                        }
                      },
                      {
                        trackingId: 'G-Y0690G3RBM',
                        gaOptions: { name: 'tracker2' }
                      }
                    ],
                    { debug: false, alwaysSendToDefaultTracker: false }
                );
    
                ReactPixel.init('418062788353277');
            }
        }
    }
};
// Google analytics page view
export const GAPageView = () => {
    if( window.location.origin !== "http://localhost:3000" ){
        let match = document.cookie.match(new RegExp('(^| )APcookie=([^;]+)'));
        if (match) {
            if(match[2]){
                ReactGa.set({ page: window.location.pathname }, ['tracker1', 'tracker2']);
                ReactGa.pageview(window.location.pathname, ['tracker1', 'tracker2']);
                ReactPixel.pageView();
            }
        }
    }
};

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <Suspense fallback={null}>
                    <App />
                </Suspense>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
