// react
import React, { /*useState,*/ useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// components
// import Galery from "react-photo-gallery";
import Slider from "react-slick";
import { /*Button,*/ Grid, Container, Image, Header } from "semantic-ui-react";
// others
import "../../styles/gallery.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { addToBasket, removeFromBasket } from "../../store/actions";

// component
function Gallery(props) {
    const { photos } = props;
    // eslint-disable-next-line
    // const [show, setShow] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnDotsHover: true,
        pauseOnFocus: true
    };

    // const dispatch = useDispatch();
    // const basket = useSelector((state) => state.basket);

    // function ShowMore() {
    //     setShow((showPrev) => !showPrev);
    // }

    useEffect(() => {
        for (let i = 0; i < photos.length; i++){
            photos[i].src = photos[i].src.replace("http", "https")
        }

    }, [photos])

    // template
    return (
        <>
            <Container className="ap-slider" style={{ width: "75%", paddingLeft: "1rem", paddingRight: "1rem" }}>
                <div>
                    {/* {photos.length > 1 ? (
                        <Galery
                            margin={0}
                            photos={show ? photos : photos.slice(0, 3)}
                        />
                    ) : photos.length === 1 ? (
                        <>
                            <Image src={photos[0].src} style={{ width: "100%" }}/>
                            <div
                            style={{
                                width: "100%",
                                height: "500px",
                                backgroundImage: "url(" + photos[0].src + ")",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                            }}
                            />
                            </>
                        ) : ("")} */}
                    {/* <Galery margin={0} photos={photos} showNav={true}/> */}
                    <Slider {...settings}>
                        { photos.map(item => (
                            <Image src={item.src} style={{ width: "100%" }}/>
                        )) }
                    </Slider>
                </div>

                {/* <div className="back">
                    <div className="activity-name">
                        <span>{props.activityName}</span>
                    </div>
                    {props.selected.id &&
                        (basket.future_events.indexOf(props.selected.id) === -1 &&
                        basket.events.indexOf(props.selected.id) === -1 ? (
                            (props.timetable?.length === 1 && props.timetable[0]?.event_type !== "solidarity") ?
                            <Button
                                onClick={(e) =>
                                    dispatch(addToBasket(props.selected))
                                }
                                className="btn"
                            >
                                { (props.timetable?.length === 1 && props.timetable[0]?.event_type === "solidarity") ? "PRISPIEŤ" : "PRIDAŤ DO KOŠÍKA" }
                            </Button>
                            : ""
                        ) : (
                            <Button
                                onClick={(e) =>
                                    dispatch(
                                        removeFromBasket(props.selected)
                                    )
                                }
                                className="btn"
                            >
                                ODOBRAŤ Z KOŠÍKA
                            </Button>
                        ))}
                </div> */}

                

                {/* <Grid>
                    <Grid.Row columns={1} centered>
                        <Grid.Column mobile={15} tablet={15} computer={12}>
                            <div className="btn2">
                                <div>
                                    {photos.length > 3 && (
                                        <Button
                                            id="hide"
                                            className="btnS"
                                            onClick={ShowMore}
                                        >
                                            {show
                                                ? "ZOBRAZIŤ MENEJ"
                                                : "ZOBRAZIŤ VIAC"}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid> */}
            </Container>
            <Grid>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column centered>
                        <Header className="activity-name-header" as="h1" content={props.activityName} style={{ textAlign: "center", marginTop: "2rem", fontSize: "3rem" }}>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default Gallery;
