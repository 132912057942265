// react
import React from "react";
// components
import { Button } from "semantic-ui-react";

// component
function ButtonAction({ text, onClick }) {
    // template
    return (
        <Button
            type="button"
            style={{
                marginTop: "2rem",
                marginLeft: "auto",
                marginRight: "auto",
            }}
            color="pink"
            onClick={onClick}
        >
            {text}
        </Button>
    );
}

export default ButtonAction;
