// react
import React from "react";
// components
import { Button, Header, Icon, Modal } from "semantic-ui-react";

// component
function PlanningModalMessage({
    modalMessageOpen,
    handleModalMessageClose,
    modalMessageText,
}) {
    // template
    return (
        <Modal
            open={modalMessageOpen}
            onClose={handleModalMessageClose}
            closeOnDimmerClick={false}
            size="mini"
        >
            <Header icon="calendar" content="Plánovanie udalostí" />
            <Modal.Content>
                <p>{modalMessageText}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button color="pink" onClick={handleModalMessageClose}>
                    <Icon name="checkmark" /> Rozumiem
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default PlanningModalMessage;
