// react
import React, { useState, Fragment } from "react";
import { withRouter } from "react-router";
// store
import { activeOptions } from "../../../../store/services/helpers/settings";
import { activityService } from "../../../../store/services/ServiceActivity";
import { LOADING_TIME } from "../../../../store/config";
import { isEmptyErrorList } from "../../../../store/services/helpers/validation";
import { routes } from "../../../../store/routes";
import {
    convertStringToTags,
    convertTagsToString,
} from "../../../../store/services/helpers/functions";
// components
import ButtonDelete from "../../../../components/buttons/ButtonDelete";
import ButtonSubmit from "../../../../components/buttons/ButtonSubmit";
import ModalConfirm from "../../../../components/modals/ModalConfirm";
import ModalResponse from "../../../../components/modals/ModalResponse";
import { Form, Header, Icon, Divider } from "semantic-ui-react";

// component
function ActivityForm({ data, categoriesProps, history }) {
    // state
    const [isEditForm] = useState(data.id ? true : false);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
    const [categories] = useState(categoriesProps);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error message");

    // form attributes
    const [nameSK, setNameSK] = useState(data.name || "");
    const [nameCZ, setNameCZ] = useState(data.name_cz || "");
    const [nameEN, setNameEN] = useState(data.name_en || "");
    const [nameDE, setNameDE] = useState(data.name_de || "");
    const [nameRU, setNameRU] = useState(data.name_ru || "");
    const [nameFR, setNameFR] = useState(data.name_fr || "");
    const [nameES, setNameES] = useState(data.name_es || "");
    const [category, setCategory] = useState(
        data.category ? data.category : ""
    );
    const [status, setStatus] = useState(isEditForm ? data.status : true);
    // set activity tags
    let stringTags = convertTagsToString(data);
    const [tags, setTags] = useState(stringTags);

    /** component functions **/

    const serviceSuccess = () => {
        setTimeout(function () {
            setIsProcessing(false);
            history.push(routes.ACTIVITIES);
        }, LOADING_TIME);
    };

    const serviceError = () => {
        setIsProcessing(false);
        setErrorMessage("Počas ukladania dát došlo k chybe!");
        handleErrorOpen();
    };

    const deleteActivity = () => {
        setIsDeleteConfirmed(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let tagsList = convertStringToTags(tags);

        // dataset
        const dataSubmit = {
            name: nameSK,
            name_cz: nameCZ,
            name_en: nameEN,
            name_de: nameDE,
            name_ru: nameRU,
            name_fr: nameFR,
            name_es: nameES,
            status: status,
            category: category,
            tags: tagsList,
        };

        // check if form is edit or create/add form
        setIsProcessing(true);
        if (isEditForm) {
            if (await activityService.editActivity(dataSubmit, data.id)) {
                serviceSuccess();
            } else {
                serviceError();
            }
        } else {
            if (await activityService.saveActivity(dataSubmit)) {
                serviceSuccess();
            } else {
                serviceError();
            }
        }
    };

    const handleCancel = () => {
        setIsDeleteConfirmed(false);
    };

    const handleConfirm = async () => {
        setIsDeleteConfirmed(true);
        setIsProcessing(true);
        if (await activityService.deleteActivity(data.id)) {
            serviceSuccess();
        } else {
            serviceError();
            setIsDeleteConfirmed(false);
        }
    };

    const handleStatusChange = (e, data) => {
        setStatus(data.value);
    };

    const handleCategoryChange = (e, data) => {
        setCategory(data.value);
    };

    const handleErrorOpen = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    // template
    return (
        <Fragment>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Input
                        width={10}
                        required
                        autoFocus
                        fluid
                        label="Názov aktivity"
                        placeholder="Názov aktivity"
                        value={nameSK}
                        onChange={(e) => {
                            setNameSK(e.target.value)
                            setNameEN(e.target.value)
                            setNameCZ(e.target.value)
                            setNameDE(e.target.value)
                            setNameRU(e.target.value)
                            setNameFR(e.target.value)
                            setNameES(e.target.value)
                        }}
                    />
                    <Form.Select
                        required
                        width={6}
                        label="Status"
                        options={activeOptions}
                        value={status}
                        onChange={handleStatusChange}
                        placeholder="Status"
                    />
                </Form.Group>
                <Divider horizontal style={{ display: "none" }}>
                    <Header as="h4" sub>
                        <Icon name="language" />
                        Preklady
                    </Header>
                </Divider>
                <Form.Group widths="two" style={{ display: "none" }}>
                    <Form.Input
                        fluid
                        label="Názov aktivity CZ"
                        placeholder="Názov aktivity CZ"
                        value={nameCZ}
                        onChange={(e) => setNameCZ(e.target.value)}
                    />
                    <Form.Input
                        fluid
                        label="Názov aktivity EN"
                        placeholder="Názov aktivity EN"
                        value={nameEN}
                        onChange={(e) => setNameEN(e.target.value)}
                    />
                </Form.Group>
                <Form.Group widths="two" style={{ display: "none" }}>
                    <Form.Input
                        fluid
                        label="Názov aktivity DE"
                        placeholder="Názov aktivity DE"
                        value={nameDE}
                        onChange={(e) => setNameDE(e.target.value)}
                    />
                    <Form.Input
                        fluid
                        label="Názov aktivity RU"
                        placeholder="Názov aktivity RU"
                        value={nameRU}
                        onChange={(e) => setNameRU(e.target.value)}
                    />
                </Form.Group>
                <Form.Group widths="two" style={{ display: "none" }}>
                    <Form.Input
                        fluid
                        label="Názov aktivity FR"
                        placeholder="Názov aktivity FR"
                        value={nameFR}
                        onChange={(e) => setNameFR(e.target.value)}
                    />
                    <Form.Input
                        fluid
                        label="Názov aktivity ES"
                        placeholder="Názov aktivity ES"
                        value={nameES}
                        onChange={(e) => setNameES(e.target.value)}
                    />
                </Form.Group>
                <Divider horizontal style={{ display: "none" }}>Doplňujúce informácie</Divider>
                <Form.Group widths={2} style={{ display: "none" }}>
                    <Form.Select
                        fluid
                        label="Kategória"
                        options={categories}
                        onChange={handleCategoryChange}
                        value={category}
                        placeholder="Kategória"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        width={16}
                        required
                        fluid
                        label="Klúčové slová (zadaj slová oddelené čiarkou, napr: futbal, lopta, brankár, ihrisko)"
                        placeholder="Klúčové slová"
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <ButtonSubmit
                        loading={isProcessing}
                        disabled={
                            isProcessing ||
                            !isEmptyErrorList([
                                nameSK,
                                nameCZ,
                                nameEN,
                                nameDE,
                                nameRU,
                                nameFR,
                                nameES,
                                tags,
                            ])
                        }
                        text={isEditForm ? "Upraviť" : "Vytvoriť aktivitu"}
                    />
                    {isEditForm ? (
                        <ButtonDelete
                            loading={isDeleteConfirmed}
                            disabled={isProcessing}
                            onClick={deleteActivity}
                            text="Vymazať"
                        />
                    ) : (
                        ""
                    )}
                </Form.Group>

                <ModalConfirm
                    open={isDeleteConfirmed}
                    header="Vymazanie aktivity"
                    content="Si si istý(á), že chceš vymazať túto aktivitu?"
                    confirmButton="Áno"
                    cancelButton="Nie"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                />
            </Form>

            <ModalResponse
                open={errorOpen}
                onClose={handleErrorClose}
                icon="warning circle"
                header="Chyba"
                message={errorMessage}
                onClick={handleErrorClose}
            />
        </Fragment>
    );
}

export default withRouter(ActivityForm);
