import React from 'react';

const FlexTable = ({ children, stripped, hoverable }) => {
    return (
        <div className={"flex-table" + (stripped || stripped === undefined ? " stripped" : "") + (hoverable || hoverable === undefined ? " hoverable" : "")}>
            { children }
        </div>
    );
};

const FlexRow = ({ children, padding, bold, fontSize, background, color, borders, pointer, ...rest }) => { 
    return (
        <div
            {...rest}
            className="flex-row"
            style={{ 
                display: "flex", 
                alignItems: "center",
                flexDirection:"row",
                width: "100%",
                padding: padding ? padding : "1.2rem",
                fontWeight: bold ? "bold" : "normal",
                fontSize: fontSize ? fontSize : "1.2rem",
                color: color ? color : "var(--dark)", 
                background: background ? background : "var(--white)", 
                borderBottom: borders ? "1px solid var(--light)" : "none", 
                borderTop: borders ? "1px solid var(--light)" : "none",
                cursor: pointer ? "pointer" : "normal"
            }}>
            { children }
        </div>
    )
}

const FlexHeader = ({ content, basis, bold }) => {
    return (
        <span className="flex-header" style={{ flexBasis: basis ? basis: "100%", fontWeight: bold || bold === undefined ? "bold" : "normal"}}>
            { content }
        </span>
    )
}

const FlexItem = ({ content, basis, bold, children, textAlign }) => {
    return (
        <span className="flex-item" style={{ flexBasis: basis ? basis: "100%", fontWeight: bold ? "bold" : "normal", textAlign: textAlign ? textAlign : "left"}}>
            { content }
            { children }
        </span>
    )
}

export {
    FlexTable,
    FlexRow,
    FlexHeader,
    FlexItem
};
