// react
import React, { Fragment, useEffect, useState } from "react";
// store
import { authService } from "../../../../store/services/ServiceAuth";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import BillingForm from "./BillingForm";
import ProviderBank from "./ProviderBank";
import { Grid } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function BillingInformation() {
    authService.useIsProvider();

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [bank, setBank] = useState({});

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Tvoje fakturačné údaje" />
            <section>
                <Grid>
                    <Grid.Column computer={10} tablet={16} mobile={16}>
                        <BillingForm setBank={setBank} setIsLoadingData={setIsLoadingData}/>
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={16} mobile={16}>
                        <ProviderBank bank={bank} setBank={setBank} isLoadingData={isLoadingData}/>
                    </Grid.Column>
                </Grid>
            </section>
        </Fragment>
    );
}

export default BillingInformation;
