// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { bundleService } from "../../../../store/services/ServiceBundle";
import { LOADING_TIME } from "../../../../store/config";
import { routes } from "../../../../store/routes";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import BundleItem from "./BundleItem";
import Loading from "../../../../components/others/Loading";
import ButtonAdd from "../../../../components/buttons/ButtonAdd";
import { Grid } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function BundleList() {
    // state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await bundleService.getBundles();
            setData(result);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HeaderTitle title="Administrácia balíčkov" />
                    <section className="bundles" style={{ marginTop: "3px" }}>
                        <Grid>
                            <ButtonAdd
                                link={routes.BUNDLES_ADD}
                                text="Pridať balíček"
                            />

                            <Grid.Row columns={4}>
                                {data.map((bundle) => (
                                    <Grid.Column
                                        mobile={16}
                                        table={8}
                                        computer={4}
                                        key={bundle.id}
                                    >
                                        <BundleItem
                                            data={bundle}
                                            key={bundle.id}
                                        />
                                    </Grid.Column>
                                ))}
                            </Grid.Row>
                        </Grid>
                    </section>
                </>
            )}
        </Fragment>
    );
}

export default BundleList;
