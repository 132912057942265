// react
import React, { useState } from "react";
// components
import Loading from "../others/Loading";
import SmartDataTable from "react-smart-data-table";
import {
    Container,
    Pagination,
    Search,
    Grid,
    Header,
    Form,
} from "semantic-ui-react";
//others
import "react-smart-data-table/dist/react-smart-data-table.css";

// component
const DataTable = ({ data, headers, orderedHeaders, hideSearch}) => {
    hideSearch = hideSearch || false
    const [query, setQuery] = useState("");
    const [perPage, setPerPage] = useState(10);

    const perPageOptions = [
        { key: "10", value: "10", text: "10 na stranu" },
        { key: "25", value: "25", text: "25 na stranu" },
        { key: "50", value: "50", text: "50 na stranu" },
        { key: "100", value: "100", text: "100 na stranu" },
        {
            key: "all",
            value: data.length.toString(),
            text: "Všetky záznamy",
        },
    ];

    const onSearch = (e, data) => {
        setQuery(data.value);
    };

    const setDataPerPage = (e, value) => {
        setPerPage(parseInt(value));
    };

    const CustomPagination = ({ activePage, totalPages, onPageChange }) => (
        <Grid style={{ padding: "1rem" }}>
            <Grid.Column
                computer={8}
                tablet={8}
                mobile={16}
                style={{ padding: "0" }}
            >
                <Pagination
                    defaultActivePage={activePage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                />
            </Grid.Column>

            <Grid.Column
                computer={8}
                tablet={8}
                mobile={16}
                style={{
                    padding: "0.5rem",
                    textAlign: "right",
                }}
            >
                <Header as="h4">Celkový počet záznamov: {data.length}</Header>
            </Grid.Column>
        </Grid>
    );

    return (
        <Container fluid>
            {data.length ? (
                <Grid style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Grid.Column
                        computer={8}
                        table={8}
                        mobile={16}
                        style={{ padding: "0", marginBottom: "1rem" }}
                    >
                        <Form.Select
                            style={{
                                borderRadius: 0,
                            }}
                            onChange={(e, data) =>
                                setDataPerPage(e, data.value)
                            }
                            defaultValue={perPage.toString()}
                            options={perPageOptions}
                        />
                    </Grid.Column>
                    <Grid.Column
                        computer={4}
                        table={4}
                        mobile={16}
                        style={{ padding: "0" }}
                    />
                    <Grid.Column
                        computer={4}
                        table={4}
                        mobile={16}
                        style={{ padding: "0" }}
                    >
                        <Search
                            style={{ marginBottom: "1rem", display: hideSearch ? "none" : "block" }}
                            className="table-search"
                            fluid
                            placeholder="Vyhľadať"
                            onSearchChange={onSearch}
                            value={query}
                            open={false}
                        />
                    </Grid.Column>
                </Grid>
            ) : (
                ""
            )}

            <SmartDataTable
                data={data}
                dataKey=""
                headers={headers}
                orderedHeaders={orderedHeaders}
                //hideUnordered={props.hideUnordered}
                name="employees-table"
                className="ui striped selectable unstackable table"
                filterValue={query}
                perPage={perPage}
                sortable
                //withToggles
                withLinks
                withHeader
                loader={<Loading />}
                parseBool={{
                    yesWord: "Áno",
                    noWord: "Nie",
                }}
                dynamic
                paginator={CustomPagination}
                emptyTable={
                    <div className="ui message" style={{ textAlign: "center" }}>
                        Nie sú dostupné žiadne údaje pre zobrazenie.
                    </div>
                }
            />
        </Container>
    );
};

export default DataTable;
