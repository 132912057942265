// react
import React from "react";
// components
import { Form } from "semantic-ui-react";

// component
function TutorialButtonBack({ disabled, onClick }) {
    // template
    return (
        <Form.Button
            type="button"
            size="big"
            color="grey"
            style={{ marginTop: "1rem" }}
            disabled={disabled}
            onClick={onClick}
        >
            Späť
        </Form.Button>
    );
}

export default TutorialButtonBack;
