// react
import React from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
// components
import { Carousel } from "react-responsive-carousel";
import { Button } from "semantic-ui-react";
// others
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// component
function HomePageCarousel() {
    //const { t } = useTranslation();

    // template
    return (
        <div className="slider">
            <Carousel
                autoPlay={true}
                showArrows={false}
                infiniteLoop={true}
                interval={7000}
                showThumbs={false}
                showStatus={false}
                stopOnHover={false}
                showIndicators={false}
            >
                <div>
                    <img
                        className="image"
                        src="/images/public/home/slider1.jpg"
                        alt="logo"
                    />
                </div>
                <div>
                    <img
                        className="image"
                        src="/images/public/home/slider2.jpg"
                        alt="logo"
                    />
                </div>
                <div>
                    <img
                        className="image"
                        src="/images/public/home/slider3.jpg"
                        alt="logo"
                    />
                </div>
            </Carousel>

            <div className="back">
                <div className="text">
                    <span>NEBUĎTE LENIVÝ A BUĎTE AKTÍVNY UŽ DNES.</span>
                </div>
                <Link to={routes.SEARCH}>
                    <Button className="btnSearch">VYHĽADAŤ AKTIVITY</Button>
                </Link>
            </div>
        </div>
    );
}

export default HomePageCarousel;
