import React, { useEffect, useState } from 'react';
// store
import { LOADING_TIME } from '../../../../store/config';
import { activityService } from '../../../../store/services/ServiceActivity';
import { isEmptyErrorList } from '../../../../store/services/helpers/validation';
import { futureEventService } from '../../../../store/services/ServiceFutureEvents';
// components
import ButtonSubmit from '../../../../components/buttons/ButtonSubmit';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import { Divider, Form, Button, Checkbox } from 'semantic-ui-react';

const FutureEventForm = ({ onCreate, onUpdate, data, onClose }) => {
    const [event, setEvent] = useState({
        id: data.id ? data.id : 0,
        activity: data.activity ? data.activity : "",
        address: data.address ? data.address : "",
        starts: data.starts ? data.starts : "",
        ends: data.ends ? data.ends : "",
        expiration: data.expiration ? data.expiration : "",
        note: data.note ? data.note : "",
        is_discounted: data.is_discounted ? data.is_discounted : false
    })

    // form states
    const [isProcessing, setIsProcessing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [activities, setActivities] = useState([]);
    const activitiesOptions = [];
    const [placesOptions, setPlacesOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const allActivities = await activityService.getCompanyActivities("");
            setActivities(allActivities);

            if( event.id !== 0 ){
                const activityInfo = allActivities.find((act) => act.id === event.activity);
                const newOptions = [];
                for (let i = 0; i < activityInfo.addresses.length; i++) {
                    let option = {
                        key: activityInfo.addresses[i].id,
                        text: activityInfo.addresses[i].name,
                        value: activityInfo.addresses[i].id,
                    };
                    newOptions.push(option);
                }
                setPlacesOptions(newOptions);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };
        fetchData();

        // eslint-disable-next-line
    }, []);

    // create options for select input from fetched data
    const setSelectOptions = (fetchedData, options) => {
        for (let i = 0; i < fetchedData.length; i++) {
            let option = {
                key: fetchedData[i].id,
                text: fetchedData[i].name,
                value: fetchedData[i].id,
            };
            options.push(option);
        }
    };

    // select input options - activities
    setSelectOptions(activities, activitiesOptions);

     /** component handlers **/
     const handleOnChangeActivityId = (e, data) => {
        setEvent({...event, activity: data.value});
        const activityInfo = activities.find((act) => act.id === data.value);
        
        // set places options when activity id is changed
        // setSelectOptions(activityInfo.addresses, placesOptions);
        const newOptions = [];
        for (let i = 0; i < activityInfo.addresses.length; i++) {
            let option = {
                key: activityInfo.addresses[i].id,
                text: activityInfo.addresses[i].name,
                value: activityInfo.addresses[i].id,
            };
            newOptions.push(option);
        }
        setPlacesOptions(newOptions);
    };

    const handleSubmit = async () => {
        setIsProcessing(true)

        if(event.id === 0){
            const result = await futureEventService.createEvent(event)
    
            if( result.status === 201 ){
                onCreate(result.response)
                setIsProcessing(false)
                onClose()
            }
        } else {
            const result = await futureEventService.updateEvent(event, event.id)
    
            if( result.status === 200 ){
                onUpdate(result.response)
                setIsProcessing(false)
                onClose()
            }
        }
    }

    return (
        <Form loading={isLoading} onSubmit={handleSubmit}>
            <Form.Field>
                <Form.Select
                    required
                    label="Vyber aktivitu"
                    placeholder="Vyber aktivitu"
                    options={activitiesOptions}
                    value={event.activity}
                    onChange={handleOnChangeActivityId}
                />
            </Form.Field>
            <Form.Field>
                <Form.Select
                    required
                    disabled={ event.activity === "" }
                    label="Vyber adresu"
                    placeholder="Vyber adresu"
                    value={event.address}
                    options={placesOptions}
                    onChange={ (e, {value}) => setEvent({
                        ...event,
                        address: value
                    }) }
                />
            </Form.Field>

            <Form.Group widths={2}>
                <DateTimeInput
                    dateFormat="YYYY-MM-DD"
                    required
                    disabled={ event.activity === "" }
                    closable
                    hideMobileKeyboard
                    label="Dostupné od"
                    value={event.starts}
                    onChange={(e, {value}) => setEvent({
                        ...event,
                        starts: value
                    }) }
                />
                <DateTimeInput
                    dateFormat="YYYY-MM-DD"
                    required
                    disabled={ event.activity === "" }
                    closable
                    hideMobileKeyboard
                    label="Dostupné do"
                    value={event.ends}
                    onChange={(e, {value}) => setEvent({
                        ...event,
                        ends: value
                    }) }
                />
            </Form.Group>
            <Form.Field style={{ marginTop: "0.2rem" }}>
                <small><strong>Hint:</strong> Dostupnosť definuje ako dlho sa má tento budúci termín zobrazovať na mape alebo v detaile aktivity</small>
            </Form.Field>

            <DateTimeInput
                dateFormat="YYYY-MM-DD"
                required
                disabled={ event.activity === "" }
                closable
                hideMobileKeyboard
                label="Dátum expirácie"
                value={event.expiration}
                onChange={(e, {value}) => setEvent({
                    ...event,
                    expiration: value
                }) }
            />

            <Form.Field>
                <Checkbox 
                    disabled={ event.activity === "" }
                    label="Aplikuj zľavu na tento termín"
                    checked={event.is_discounted}
                    onChange={() => setEvent({
                        ...event,
                        is_discounted: !event.is_discounted
                    })}
                />
            </Form.Field>

            <Divider/>
            <Form.Field style={{ float: "right", marginBottom: "1rem" }}>
                <Button type="button" onClick={onClose} basic size="big">
                    Zatvoriť
                </Button>
                <ButtonSubmit
                    loading={isProcessing}
                    disabled={isProcessing || !isEmptyErrorList([
                        event.activity,
                        event.address,
                        event.starts,
                        event.ends,
                        event.expiration
                    ])}
                    text="Uložiť"
                />
            </Form.Field>
        </Form>
    );
};

export default FutureEventForm;