import React, { Fragment, useEffect, useState } from "react";
// store
import { PAGINATION_LIMIT } from "../../../../store/config";
// components
import { Table, Pagination, Label, Popup, Icon, Header, Button } from "semantic-ui-react";

// component
function ProviderProfitsTable({ profits, setProfits,  filters, setFilters,  isLoading }) {
    // Zisk pre AP - 12,5% + DPH (15%)
    // Zisk pre Couch sieť: **3%**

    // eslint-disable-next-line
    const [ready, setReady] = useState(false)
    // eslint-disable-next-line
    const [tableData, setTableData] = useState(
        profits.providers.slice(0, PAGINATION_LIMIT)
    );

    // set data to table
    useEffect(() => {
        setTableData(profits.providers.slice(0, PAGINATION_LIMIT));
        setReady(true)
    }, [profits]);

    useEffect(() => {
        let filteredProviders = []
        for (let i = 0; i < profits.providers.length; i++) {
            // apply filters by type
            let isRendered = false
            if( (filters.type === profits.providers[i].type || filters.type === "") ){
                isRendered = true
            }

            filteredProviders.push({
                ...profits.providers[i], 
                isRendered: isRendered
            })
        }

        setTableData(filteredProviders.slice(0, PAGINATION_LIMIT));
        // eslint-disable-next-line
    }, [filters.type])

    const handleChangePage = (event, data) => {
        setReady(false)
        setTableData(
            profits.providers.slice(
                data.activePage * PAGINATION_LIMIT - PAGINATION_LIMIT,
                data.activePage * PAGINATION_LIMIT
            )
        );
        setReady(true)
    };

    // template
    return (
        <Fragment>
            <Button.Group size="small" basic>
                <Button 
                    style={{ fontWeight: "bold" }} 
                    active={filters.type === ""}
                    onClick={ () => setFilters({...filters, type: ""}) }
                >
                    Všetky ({(profits.total_providers_business+profits.total_providers_town+profits.total_providers_coach)})
                </Button>
                <Button 
                    style={{ fontWeight: "bold" }} 
                    active={filters.type === "provider"}
                    onClick={ () => setFilters({...filters, type: "provider"}) }
                >
                    #APbusiness ({profits.total_providers_business})
                </Button>
                <Button 
                    style={{ fontWeight: "bold" }} 
                    active={filters.type === "town"}
                    onClick={ () => setFilters({...filters, type: "town"}) }
                >
                    #APsolidarity ({profits.total_providers_town})
                </Button>
                <Button 
                    style={{ fontWeight: "bold" }} 
                    active={filters.type === "coach"}
                    onClick={ () => setFilters({...filters, type: "coach"}) }
                >
                    Coach ({profits.total_providers_coach})
                </Button>
            </Button.Group>
            <Table striped unstackable style={{ overflow: "auto" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Poskytovateľ</Table.HeaderCell>
                        <Table.HeaderCell>Typ poskytovateľa</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">Zárobok</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">Vrátené</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">Zisk</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Predané aktivity</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Vratené aktivity</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    { tableData.length === 0 && 
                        <Table.Row> 
                            <Table.Cell colSpan={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                                Neboli najdené žiadne záznamy
                            </Table.Cell>
                        </Table.Row>
                    }
                    {tableData.map((provider) => (
                        <>
                        { provider.isRendered &&
                        <Table.Row>
                            <Table.Cell><strong>{ provider.name }</strong></Table.Cell>
                            <Table.Cell>
                                { provider.type === "provider" && <Label size="tiny" style={{ borderRadius: 0, background: "rgb(238, 77, 186)", color: "var(--white)" }}>#APbusiness</Label> }
                                { provider.type === "town" && <Label size="tiny" style={{ borderRadius: 0, background: "rgb(33, 133, 208)", color: "var(--white)" }}>#APsolidarity</Label> }
                                { provider.type === "coach" && <Label size="tiny"style={{ borderRadius: 0, background: "var(--dark)", color: "var(--white)" }}>Coach</Label> }
                            </Table.Cell>
                            <Table.Cell textAlign="left" style={{ color: "green" }}>{ provider.earned } €</Table.Cell>
                            <Table.Cell textAlign="left" style={{ color: "red" }}>{ provider.refunded } €</Table.Cell>
                            <Table.Cell textAlign="left">
                                { (provider.earned - provider.refunded) > 0 
                                    ? <span style={{ color: "green" }}> +{(provider.earned - provider.refunded)} { " €" }</span>
                                    : (provider.earned-provider.refunded) === 0 
                                        ? <span>0 { " €" }</span>
                                        : <span style={{ color: "red" }}> -{(provider.earned - provider.refunded)} { " €" }</span>
                                }
                                { provider.type === "coach" && 
                                    <Popup 
                                        trigger={
                                            <Label size="tiny" style={{ marginLeft: "1rem", cursor: "pointer" }} basic>
                                                <Icon 
                                                    name={provider.coach_network === null ? "warning circular" : "info circular" } 
                                                    size="small"
                                                    style={{ 
                                                        color: provider.coach_network === null ? "red" : "var(--dark)", 
                                                        marginRight: 0,
                                                    }} 
                                                />
                                                { provider.coach_network !== null && 
                                                    <span style={{ marginLeft: "0.5rem" }}>
                                                        <span style={{ fontWeight: "bold" }}>Sieť: </span>
                                                        { (provider.coach_network.earned - provider.coach_network.refunded) > 0 
                                                            ? <span style={{ color: "green" }}> +{(provider.coach_network.earned - provider.coach_network.refunded)} { " €" }</span>
                                                            : (provider.coach_network.earned-provider.coach_network.refunded) === 0 
                                                                ? <span>0 { " €" }</span>
                                                                : <span style={{ color: "red" }}> -{(provider.coach_network.earned - provider.coach_network.refunded)} { " €" }</span>
                                                        }
                                                    </span>
                                                }
                                            </Label>
                                        }
                                        content={
                                            <> 
                                                { provider.type === "coach" && provider.coach_network !== null 
                                                    ? <span>
                                                        { `Veľkosť siete: ${provider.coach_network.network_size}` } <br/>
                                                        { `Zárobok siete: ${provider.coach_network.earned} €` } <br/>
                                                        { `Z toho vrátených: ${provider.coach_network.refunded} €` } <br/>
                                                        { `Predané aktivíty: ${provider.coach_network.activity_sold}` } <br/>
                                                        { `Vratené aktivíty: ${provider.coach_network.activity_refunded}` } <br/>

                                                        <Header as="h4">
                                                        Zisk siete: {" "}
                                                        { (provider.coach_network.earned - provider.coach_network.refunded) > 0 
                                                            ? <span style={{ color: "green" }}> +{(provider.coach_network.earned - provider.coach_network.refunded)} { " €" }</span>
                                                            : (provider.coach_network.earned-provider.coach_network.refunded) === 0 
                                                                ? <span>0 { " €" }</span>
                                                                : <span style={{ color: "red" }}> -{(provider.coach_network.earned - provider.coach_network.refunded)} { " €" }</span>
                                                        }
                                                        </Header>
                                                    </span>
                                                    : "Coach nemá vytvorenú žiadnu sieť poskytovateľov."
                                                }
                                            </>
                                        }
                                    />
                                }
                            </Table.Cell>
                            <Table.Cell textAlign="center">{ provider.activity_sold }</Table.Cell>
                            <Table.Cell textAlign="center">{ provider.activity_refunded }</Table.Cell>
                        </Table.Row>
                        }
                        </>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="8">
                            <Pagination
                                defaultActivePage={1}
                                totalPages={Math.ceil(
                                    profits.providers.length / PAGINATION_LIMIT
                                )}
                                onPageChange={handleChangePage}
                                boundaryRange={0}
                                siblingRange={0}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Fragment>
    );
}

export default ProviderProfitsTable;
