// react
import React, { Fragment } from "react";
// components
import { Card, Header, Grid, Modal, Button, Icon } from "semantic-ui-react";

// component
function PlanningAddEventType({ eventType, changeEventType, nextModalStep }) {
    //template
    return (
        <Fragment>
            <Modal.Content>
                <Header>Vyber typ aktivity</Header>
                <Grid columns={2} divided centered>
                    <Grid.Row>
                        <Grid.Column>
                            <Card
                                centered
                                className={
                                    eventType === "exactTime"
                                        ? "event-type-active"
                                        : ""
                                }
                                width={8}
                                onClick={
                                    eventType !== "exactTime"
                                        ? () => changeEventType("exactTime")
                                        : null
                                }
                            >
                                <Card.Content>
                                    <Card.Header>Konkrétny čas</Card.Header>
                                    <Card.Description>
                                        <Header as="h5">
                                            Plánovanie na konkrétny čas
                                        </Header>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column>
                            <Card
                                centered
                                className={
                                    eventType === "interval"
                                        ? "event-type-active"
                                        : ""
                                }
                                width={8}
                                onClick={
                                    eventType !== "interval"
                                        ? () => changeEventType("interval")
                                        : null
                                }
                            >
                                <Card.Content>
                                    <Card.Header>Časový interval</Card.Header>
                                    <Card.Description>
                                        <Header as="h5">
                                            Plánovanie v rámci intervalu
                                        </Header>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color="pink"
                    labelPosition="right"
                    onClick={nextModalStep}
                    icon
                >
                    Pokračovať
                    <Icon name="right chevron" />
                </Button>
            </Modal.Actions>
        </Fragment>
    );
}

export default PlanningAddEventType;
