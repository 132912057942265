// react
import React, { Fragment, useEffect, useState } from "react";
// store
import { authService } from "../../../../store/services/ServiceAuth";
import { statsService } from "../../../../store/services/ServiceStats";
import { LOADING_TIME } from "../../../../store/config";
import { providerRoleOptions } from "../../../../store/services/helpers/settings";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Loading from "../../../../components/others/Loading";
import AccordionColumn from "../../../../components/others/AccordionColumn";
import ButtonGroupSearch from "../../../../components/buttons/ButtonGroupSearch";
import UsersActivityTable from "./UsersActivityTable";
import { Form, Grid, Segment } from "semantic-ui-react";

// component
const UsersActivity = () => {
    // check user
    authService.useIsAdmin();

    // state
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [providerType, setProviderType] = useState("");

    const fetchData = async (params) => {
        setIsLoading(true);
        let result = await statsService.getUsersActivity(params);
        setData(result.response);

        setTimeout(function () {
            setIsLoading(false);
        }, LOADING_TIME);
    };

    // fetch data
    useEffect(() => {
        fetchData("?is_provider=true");
    }, []);

    /** component functions **/
    const handleOnChangeProviderType = (e, data) => {
        setProviderType(data.value);
    };

    const handleSubmit = () => {
        let params = "?is_provider=true";

        if (providerType !== "") {
            if (providerType === "1") {
                params = "?is_provider=true";
            } else if (providerType === "2") {
                params = "?is_coach=true";
            } else if (providerType === "3") {
                params = "?is_town=true";
            }
            fetchData(params);
        }
    };

    const resetForm = () => {
        if (providerType !== "") {
            setProviderType("");
            fetchData("?is_provider=true");
        }
    };

    // search form inputs
    const panels = [
        {
            key: "details",
            title: "Kritéria vyhľadávania",
            content: {
                content: (
                    <div>
                        <Form
                            id="form-search-users-activity"
                            onSubmit={handleSubmit}
                        >
                            <Form.Group widths="equal">
                                <Form.Select
                                    fluid
                                    label="Typ používateľa"
                                    placeholder="Typ používateľa"
                                    options={providerRoleOptions}
                                    value={providerType}
                                    onChange={handleOnChangeProviderType}
                                />
                            </Form.Group>
                        </Form>

                        <ButtonGroupSearch
                            form="form-search-users-activity"
                            resetDisabled={providerType === ""}
                            resetOnClick={resetForm}
                        />
                    </div>
                ),
            },
        },
    ];

    return (
        <Fragment>
            <HeaderTitle title="Aktivita používateľov" />
            <section>
                <Grid>
                    <AccordionColumn panels={panels} />
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <Segment>
                                <UsersActivityTable
                                    data={data ? data : []}
                                    isLoading={isLoading}
                                />
                            </Segment>
                        )}
                    </Grid.Column>
                </Grid>
            </section>
        </Fragment>
    );
};

export default UsersActivity;
