// react
import React, { useState } from "react";
import { Link } from "react-router-dom";
// store
import { isEmptyErrorList } from "../../store/services/helpers/validation";
import { commonService } from "../../store/services/ServiceCommon";
// components
import { Button, Form, Grid, Message, TextArea } from "semantic-ui-react";

// component
export default function ContactForm() {
    // state
    const [checked, setChecked] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [positive, setPositive] = useState(false);
    const [isSended, setIsSended] = useState(false);

    const initFormData = {
        name: "",
        email: "",
        subject: "",
        message: "",
    };

    const [formData, setFormData] = useState(initFormData);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        const data = {
            subject: formData.subject,
            context: formData.message + "\n\nS pozdravom " + formData.name + "\n" + formData.email,
            email_from: formData.email,
            emails_to: [],
        };

        const result = await commonService.sendEmail(data);

        if (result.status === 201) {
            setPositive(true);
            setIsSended(true);
            setFormData(initFormData);
        } else {
            setIsSended(true);
        }
        setProcessing(false);
    };

    // template
    return (
        <Grid.Column mobile={14} tablet={14} computer={6}>
            <div className="invisible-box">
                <h2>Kontakt</h2>

                <Form onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Meno"
                            value={formData.name}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: e.target.value,
                                })
                            }
                        />
                        <Form.Input
                            fluid
                            required
                            label="Email"
                            type="email"
                            value={formData.email}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    email: e.target.value,
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            label="Predmet"
                            value={formData.subject}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    subject: e.target.value,
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            required
                            control={TextArea}
                            label="Správa"
                            rows="6"
                            value={formData.message}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    message: e.target.value,
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        label={{
                            children: (
                                <p>
                                    {`Súhlasím a bol som oboznámený so `}
                                    <Link
                                        to="/privacy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        spracovaním osobných údajov
                                    </Link>
                                    .
                                </p>
                            ),
                        }}
                    />
                    <Message
                        onDismiss={() => setIsSended(false)}
                        positive={positive && isSended}
                        negative={!positive && isSended}
                        hidden={!isSended}
                        visible={isSended}
                        content={
                            positive && isSended
                                ? "Správa bola úspešne odoslaná."
                                : !positive && isSended
                                ? "Chyba pri odosielaní správy."
                                : ""
                        }
                    />
                    <Button
                        loading={processing}
                        disabled={
                            !isEmptyErrorList([
                                formData.name,
                                formData.email,
                                formData.message,
                                formData.subject,
                            ]) ||
                            !checked ||
                            processing
                        }
                        size="huge"
                        color="pink"
                    >
                        Odoslať správu
                    </Button>
                </Form>
            </div>
        </Grid.Column>
    );
}
