// react
import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// store
import { userProfileService } from "../../../store/services/ServiceUserProfile";
import { languageOptions } from "../../../store/services/helpers/settings";
import { LOADING_TIME } from "../../../store/config";
import { addUser, setLanguage } from "../../../store/actions";
import { isEmpty } from "../../../store/services/helpers/validation";
import { useTranslation } from "react-i18next";
// components
import ButtonSubmit from "../../../components/buttons/ButtonSubmit";
import ModalResponse from "../../../components/modals/ModalResponse";
import { Form, Grid, Header } from "semantic-ui-react";

// component
function ProfileLanguage() {
    const { i18n } = useTranslation();

    // state
    const [language, setLanguageState] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalIsError, setModalIsError] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    // getting user from Redux Store
    const user = useSelector((state) => state.user);
    let dispatch = useDispatch();

    // fetch data - profile language
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const data = await userProfileService.getUserProfile();
            // set language to state
            if (data[0]) {
                setLanguageState(data[0].language);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    /** component functions **/

    const handleLanguageChange = (e, data) => {
        setLanguageState(data.value);
    };

    const openModal = (message, error) => {
        setModalMessage(message);
        setModalIsError(error);
        setIsProcessing(false);
        handleModalOpen();
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    // submit form
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        const updatedLanguage = {
            language: language,
        };

        if (await userProfileService.updateUserProfile(updatedLanguage)) {
            // perform redux dispatch action to update user state
            user.language = language;
            dispatch(addUser(user));

            // update redux global language state
            dispatch(setLanguage(updatedLanguage.language));

            // force browser to change language
            i18n.changeLanguage(updatedLanguage.language);

            openModal("Zmena jazyka aplikácie prebehla úspešne.", false);
        } else {
            openModal("Počas zmeny jazyka aplikácie došlo k chybe!", true);
        }
    };

    // template
    return (
        <Fragment>
            <Grid>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <Header as="h3">ZMENA JAZYKA APLIKÁCIE</Header>
                    <Form loading={isLoading} onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Select
                                required
                                fluid
                                label="Jazyk aplikácie"
                                placeholder="Jazyk aplikácie"
                                options={languageOptions}
                                onChange={handleLanguageChange}
                                value={language}
                            />
                        </Form.Field>

                        <ButtonSubmit
                            loading={isProcessing}
                            disabled={isProcessing || isEmpty(language)}
                            text="Uložiť"
                        />
                    </Form>
                </Grid.Column>
            </Grid>

            <ModalResponse
                open={modalOpen}
                onClose={handleModalClose}
                icon={modalIsError ? "warning circle" : "checkmark"}
                header={
                    modalIsError
                        ? "Aktualizácia neúspešná"
                        : "Aktualizácia úspešná"
                }
                message={modalMessage}
                onClick={handleModalClose}
            />
        </Fragment>
    );
}

export default ProfileLanguage;
