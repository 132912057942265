// react
import React, { Fragment, useState, useEffect } from "react";
// store
import { activityService } from "../../../../store/services/ServiceActivity";
import { addressService } from "../../../../store/services/ServiceAddress";
import { userProfileService } from "../../../../store/services/ServiceUserProfile";
import { LOADING_TIME } from "../../../../store/config";
import {
    setSelectOptions,
    setSelectOptionsActivities,
} from "../../../../store/services/helpers/functions";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import ActivityForm from "./ActivityForm";
import Loading from "../../../../components/others/Loading";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function ActivityEdit(props) {
    // state
    const [isLoading, setIsLoading] = useState(false);
    const [activities, setActivities] = useState([]);
    const [data, setData] = useState([]);
    const [activityID] = useState(props.match.params.id);
    const [addresses, setAddresses] = useState([]);
    const [country, setCountry] = useState([]);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const resultActivities = await activityService.getActivities(
                "?status=true"
            );
            setActivities(resultActivities.response);

            const resultAddresses = await addressService.getAddresses();
            setAddresses(resultAddresses);

            const resultData = await activityService.getCompanyActivities(
                "?id=" + activityID
            );
            setData(resultData[0]);

            const profile = await userProfileService.getUserProfile();
            if (profile[0]) {
                setCountry(profile[0].country);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, [activityID]);

    // set options for dropdown form field - activities, addresses
    const activitiesOptions = setSelectOptionsActivities(activities, country);
    const addressesOptions = setSelectOptions(addresses);

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HeaderTitle title="Upraviť aktivitu" />
                    <section>
                        <ActivityForm
                            data={data}
                            activities={activitiesOptions}
                            addresses={addressesOptions}
                            country={country}
                        />
                    </section>
                </>
            )}
        </Fragment>
    );
}

export default ActivityEdit;
