import React, { useEffect, useState } from 'react'
// components
import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
// import Banner from "../../components/general/Banner";
import APSolidarityInfo from "./APSolidarityInfo";
import APSolidarityForm from "./APSolidarityForm";
import { Button } from "semantic-ui-react";
import { Link } from "react-scroll";

// others
import { GAPageView, initGA } from "../../index";
// import { routes } from '../../store/routes';


export default function APSolidarity() {
    const [contacting, setContacting] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    return (
       <div className="website">
            <Navbar linkBack="/" />
            <div className="banner-wrapper">
                <div
                    className="banner-container"
                    style={{ backgroundImage: `url(/images/public/activity/activitypoint-hero-apsolidarity.jpg)`, backgroundPosition: 'center 40%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', 
                        height: "80vH",
                    }}
                />

                <div className="back" style={{ maxWidth: "45rem" }}>
                    <div className="text" style={{ paddingBottom: "0.5rem" }}>
                        <span style={{ fontSize: "2.5rem" }}>Prínos pre mestá a obce</span>
                    </div>
                    {/* <p>Jednoduchý spôsob na zviditeľnenie vášho mesta či obce <br/> a na získanie finančných príspevkov na jeho budovanie.</p> */}
                    <ul style={{ paddingLeft: "1rem", marginBottom: "1.5rem" }}>
                        <li>Unikátny spôsob zobrazenia zaujímavých miest v meste či obci, s jedinečnou možnosťou výberu dobrovoľných príspevkov pre potreby údržby a rozvoja.</li>
                        <li>Plnohodnotne nahrádza informačné centrá.</li>
                        <li>Naviguje.</li>
                        <li>Samospráva nezaťažená prácou, celú agendu spracuje team AP</li>
                    </ul>
                    <Link
                        to="about-activitypoint"
                        spy={true}
                        smooth={true}
                        offset={-20}
                        duration={500}
                    >
                        <Button style={{ padding: "1rem" }} className="btnSearch">Ako funguje ActivityPoint?</Button>
                    </Link>
                    <Button 
                        onClick={() => setContacting(true)}
                        style={{ 
                            padding: "1rem", 
                            marginLeft: "1rem", 
                            background: "none",
                            color: "var(--dark)"
                        }}
                    >
                        Registrovať obec/mesto
                    </Button>
                </div>
            </div>
            <APSolidarityInfo setContacting={setContacting}/>
            <APSolidarityForm 
                opened={contacting}
                setOpenedFunc={setContacting}
            />
            <Footer />
        </div>
    )
}
