// react
import React from "react";
import { Switch, Route } from "react-router-dom";
// store
import { authService } from "../../../../store/services/ServiceAuth";
import { routes } from "../../../../store/routes";
// components
import BundleList from "./BundleList";
import BundleDetail from "./BundleDetail";
import BundleAdd from "./BundleAdd";

// components
function Bundles() {
    authService.useIsAdmin();

    // template
    return (
        <Switch>
            <Route exact path={routes.BUNDLES} component={BundleList} />
            <Route exact path={routes.BUNDLES_ADD} component={BundleAdd} />
            <Route
                exact
                path={routes.BUNDLES_EDIT + ":id"}
                component={BundleDetail}
            />
        </Switch>
    );
}

export default Bundles;
