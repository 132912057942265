// react
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// store
import { providerOptions } from "../../../../store/services/helpers/settings";
import { routes } from "../../../../store/routes";
// components
import { Icon } from "semantic-ui-react";

// component
function BundleItem({ data }) {
    // state
    const [provider, setProvider] = useState("");

    // get proper data value from settings
    useEffect(() => {
        for (let i = 0; i < providerOptions.length; i++) {
            if (parseInt(providerOptions[i].value) === data.providerType) {
                setProvider(providerOptions[i].text);
                break;
            }
            continue;
        }
    }, [data.providerType]);

    // template
    return (
        <Link to={routes.BUNDLES_EDIT + data.id}>
            <div className="item-label">
                <Icon name="tags" />
                <h1 style={{ marginTop: "0rem" }}>{data.name}</h1>
                <span>{provider}</span>
                <br />
                <br />
                <span className="tag">{data.activityCount} Aktivít</span>
                <span className="tag price">{data.price} € </span>
            </div>
        </Link>
    );
}

export default BundleItem;
