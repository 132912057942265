// react
import React from "react";
// components
import { Button } from "semantic-ui-react";

// component
function ButtonIconDetail({ title, onClick }) {
    // template
    return (
        <Button
            type="button"
            size="mini"
            circular
            color="black"
            icon="info"
            title={title}
            onClick={onClick}
        />
    );
}

export default ButtonIconDetail;
