// react
import React from "react";
import { googleMapsAPIKEY } from '../../../../store/config';
// components
import AddressForm from "./AddressForm";
// others
import {
    withGoogleMap,
    GoogleMap,
    withScriptjs,
    Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { Grid } from "semantic-ui-react";
Geocode.setApiKey(googleMapsAPIKEY);
Geocode.enableDebug();

// component
class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            street: "",
            street_nr: "",
            city: "",
            zip_code: "",
            position: {
                lat: this.props.center.lat,
                lng: this.props.center.lng,
            },
        };
    }

    /**
     * Get the current address from the default map position and set those values in the state
     */

    componentDidMount() {
        Geocode.fromLatLng(
            this.state.position.lat,
            this.state.position.lng
        ).then(
            (response) => {
                let name = response.results[0].formatted_address;
                let city = this.getCity(response.results[0].address_components);
                let zip_code = this.readAddressComponent(
                    response.results[0].address_components,
                    "postal_code"
                );
                let street_nr = this.getStreet_nr(
                    response.results[0].address_components
                );
                let street = this.getStreet(
                    response.results[0].address_components
                );

                this.setState({
                    name: name ? name : "",
                    city: city ? city : "",
                    zip_code: zip_code ? zip_code : "",
                    street: street ? street : "",
                    street_nr: street_nr ? street_nr : "",
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.state.position.lat !== this.props.center.lat ||
            //this.state.name !== nextState.name ||
            this.state.city !== nextState.city ||
            this.state.street !== nextState.street ||
            this.state.street_nr !== nextState.street_nr ||
            this.state.zip_code !== nextState.zip_code
        ) {
            return true;
        } else if (this.props.center.lat === nextProps.center.lat) {
            return false;
        }
    }

    getCity = (addressArray) => {
        let city = "";
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if (
                        "sublocality_level_1" === addressArray[i].types[j] ||
                        "locality" === addressArray[i].types[j]
                    ) {
                        city = addressArray[i].long_name;
                        return city;
                    }
                }
            }
        }
    };

    getStreet = (addressArray) => {
        let street = "";
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if (
                        "route" === addressArray[i].types[j] ||
                        "street" === addressArray[i].types[j] ||
                        "sublocality_level_1" === addressArray[i].types[j] ||
                        "locality" === addressArray[i].types[j]
                    ) {
                        street = addressArray[i].long_name;
                        return street;
                    }
                }
            }
        }
    };

    getStreet_nr = (addressArray) => {
        let street_nr = "";
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                if (
                    "street_number" === addressArray[i].types[0] ||
                    "premise" === addressArray[i].types[0] ||
                    "route" === addressArray[i].types[0]
                ) {
                    street_nr = addressArray[i].long_name;
                    return street_nr;
                }
            }
        }
    };

    readAddressComponent = (component, type) => {
        let information = "";

        for (let i = 0; i < component.length; i++) {
            if (component[i].types[0] && component[i].types[0] === type) {
                information = component[i].long_name;
                break;
            }
        }

        return information;
    };

    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = (place) => {
        const latValue = place.geometry.location.lat();
        const lngValue = place.geometry.location.lng();

        let name = place.formatted_address;
        let city = this.getCity(place.address_components);
        let zip_code = this.readAddressComponent(
            place.address_components,
            "postal_code"
        );
        let street_nr = this.getStreet_nr(place.address_components);
        let street = this.getStreet(place.address_components);

        this.setState({
            name: name ? name : "",
            city: city ? city : "",
            zip_code: zip_code ? zip_code : "",
            street: street ? street : "",
            street_nr: street_nr ? street_nr : "",

            position: {
                lat: latValue,
                lng: lngValue,
            },
        });
    };

    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat();
        let newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            (response) => {
                let name = response.results[0].formatted_address;
                let city = this.getCity(response.results[0].address_components);
                let zip_code = this.readAddressComponent(
                    response.results[0].address_components,
                    "postal_code"
                );
                let street_nr = this.getStreet_nr(
                    response.results[0].address_components
                );
                let street = this.getStreet(
                    response.results[0].address_components
                );

                this.setState({
                    name: name ? name : "",
                    city: city ? city : "",
                    zip_code: zip_code ? zip_code : "",
                    street: street ? street : "",
                    street_nr: street_nr ? street_nr : "",

                    position: {
                        lat: newLat,
                        lng: newLng,
                    },
                });
            },
            (error) => {
                console.error(error);
            }
        );
    };

    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap((props) => (
                <GoogleMap
                    defaultZoom={this.props.zoom}
                    defaultCenter={{
                        lat: this.state.position.lat,
                        lng: this.state.position.lng,
                    }}
                >
                    <Autocomplete
                        style={{
                            width: "100%",
                            height: "40px",
                            paddingLeft: "16px",
                            position: "relative",
                            top: "-350px",
                        }}
                        placeholder="Vyhľadať lokalitu..."
                        onPlaceSelected={this.onPlaceSelected}
                        types={[]}
                    />
                    <Marker
                        name={"Dolores park"}
                        draggable={true}
                        onDragEnd={this.onMarkerDragEnd}
                        position={{
                            lat: this.state.position.lat,
                            lng: this.state.position.lng,
                        }}
                    />
                </GoogleMap>
            ))
        );

        let map;

        if (this.props.center.lat !== undefined) {
            map = (
                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <AsyncMap
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKEY}&libraries=places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={
                                <div style={{ height: this.props.height }} />
                            }
                            mapElement={
                                <div
                                    style={{
                                        height: `100%`,
                                        marginTop: `50px`,
                                    }}
                                />
                            }
                        />
                        {!this.props.isEdit ? (
                            <p>
                                Ak si želáte zmeniť lokalitu{" "}
                                <strong>vyhľadajte novú</strong> alebo{" "}
                                <strong>premiestnite červenú značku</strong> na
                                požadované miesto na Google mape.

                                <br /><br />
                                Automaticky doplnená lokalita:{" "}
                                <strong>Bratislava</strong><br/>
                            </p>
                            
                        ) : (
                            ""
                        )}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <AddressForm
                            tutorial={this.props.tutorial}
                            data={this.state}
                            isEdit={this.props.isEdit}
                        />
                    </Grid.Column>
                </Grid>
            );
        } else {
            map = <div style={{ height: this.props.height }} />;
        }
        return map;
    }
}

export default Map;
