import request from "umi-request";
import Cookies from "js-cookie";
import { API } from "../config";
import { routes } from "../routes";

// redux, router
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Role } from "./helpers/roles";

// actions
import { addUser, logoutUser, setLanguage } from "../actions";
import { APIRequest } from './helpers/requests';

/*
 * Service functions
 */
export const authService = {
    login,
    logout,
    refreshLogin,
    useHasRole,
    useIsAdmin,
    useIsProvider,
    useIsUser,
    register,
    resendActivation,
    useUserLanguage,
    useUserTutorial,
    getAccessToken,
    registerRequest,
    validateToken,
};

function setAuthTokens(response) {
    let tmpExpiration = response.expiresIn;
    let expiresIn = new Date(new Date().getTime() + tmpExpiration * 1000);

    if (document.location.protocol === "https:") {
        Cookies.set("accessToken", response.access, {
            expires: expiresIn,
            secure: true,
        });
        Cookies.set("refreshToken", response.refresh, { secure: true });
    } else {
        Cookies.set("accessToken", response.access, { expires: expiresIn });
        Cookies.set("refreshToken", response.refresh);
    }
}

/* Auth Services */
async function login(email, password, dispatch, history, i18n) {
    // Perform user login and on success store access & refresh token in Cookies or LocalStorage
    let result = await request
        .post(API.LOGIN, {
            data: {
                email: email,
                password: password,
            },
        })
        .then(function (response) {
            setAuthTokens(response);

            // store user data to Redux Store using dispatch and set him to authorized
            dispatch(addUser(response.user));
            dispatch(setLanguage(response.user.language));
            i18n.changeLanguage(response.user.language);

            return response.user;
        })
        .catch(function (error) {
            return false;
        });

    return result;
}

async function refreshLogin(refreshToken) {
    // Extend user session based on refreshToken if exist else redirect user to login
    let result = await request
        .post(API.REFRESH, {
            data: {
                refresh: refreshToken,
            },
        })
        .then(function (response) {
            // store OLD Access & Refresh Token from Cookies,
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");

            setAuthTokens(response);

            return true;
        })
        .catch(function (error) {
            return false;
        });

    return result;
}

function logout(dispatch) {
    // Perform user logout and remove user from redux store and cookies
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    dispatch(logoutUser());
}


async function validateToken(){
    const token = Cookies.get("accessToken");
    let response = {};

    if( token ){
        response = await APIRequest({
            url: API.VERIFY, 
            method: 'POST',
            data: {
                token: token
            },
            private: false
        });
    }

    if( response && response.status === 200 ){
        return token;
    } else {
        const refresh = Cookies.get("refreshToken");
        // attempt to refreshLogin with refresh token, if true then return new access token
        if( refresh ){
            const result = await refreshLogin(refresh);
            if (result) {
                return Cookies.get("accessToken");
            }
        }
        
        return false;
    }
}

async function getAccessToken() {
    return await validateToken();
}


// async function getAccessToken() {

//     let access = Cookies.get("accessToken");
//     let refresh = Cookies.get("refreshToken");

//     // if access token expired
//     if (access === undefined) {
//         // if refresh token exists
//         if (refresh !== undefined) {
//             // attempt to refreshLogin with refresh token, if true then return new access token
//             let result = await refreshLogin(refresh);
//             if (result) {
//                 return Cookies.get("accessToken");

//                 // return false if refreshLogin failed
//             } else {
//                 return false;
//             }

//             // if refresh token doesn't exist
//         } else {
//             return false;
//         }

//         // if access token exist
//     } else {
//         return access;
//     }
// }

/* Register */
async function register(data) {
    return await APIRequest({
        url: API.REGISTER, 
        method: 'POST',
        data: data,
        private: false
    });
}

async function registerRequest(data) {
    return await APIRequest({
        url: API.REGISTER_REQUEST, 
        method: 'POST',
        data: data,
        private: false
    });
}

async function resendActivation(data) {
    let sended;
    sended = await request(API.RESEND_ACTIVATION, {
        method: "post",
        data: data,
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return sended;
}

function useHasRole(role) {
    const userRole = useSelector((state) => state.user.role);

    return userRole === role;
}

function useUserLanguage() {
    return useSelector((state) => state.user.language);
}

function useUserTutorial() {
    return useSelector((state) => state.user.tutorial);
}

/* Helper Hooks for pushing user to general dashboard if they doesn't have correct access rights */
function useIsAdmin() {
    let history = useHistory();
    if (!useHasRole(Role.Admin)) {
        history.push(routes.DASHBOARD);
    }
}

function useIsProvider() {
    let history = useHistory();
    if (!useHasRole(Role.Provider)) {
        history.push(routes.DASHBOARD);
    }
}

function useIsUser() {
    let history = useHistory();
    if (!useHasRole(Role.User)) {
        history.push(routes.DASHBOARD);
    }
}
