// react
import React, { Fragment, useEffect } from "react";
// components
import HeaderTitle from "../../../../components/others/HeaderTitle";
import Map from "./Map";
// others
import { GAPageView, initGA } from "../../../../index";

// component
function AddressAdd() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <Fragment>
            <HeaderTitle title="Pridať adresu" />
            <section>
                <Map
                    center={{ lat: 48.14696796312074, lng: 17.108176953442385 }}
                    height="300px"
                    zoom={14}
                    isEdit={false}
                    tutorial={false}
                />
            </section>
        </Fragment>
    );
}

export default AddressAdd;
