import React, { useEffect } from 'react'

// components
import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import Banner from "../../components/general/Banner";
import { Grid } from "semantic-ui-react";
// eslint-disable-next-line
import APBusinessInfo from "./APBusinessInfo";

// others
import { GAPageView, initGA } from "../../index";
import { routes } from '../../store/routes';


export default function APBusiness() {

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    return (
       <div className="website">
            <Navbar linkBack="/" />
            <Banner src="/images/public/activity/banner_business_kosice.jpg"
                badge={{
                    text: "NEBUĎTE LENIVÝ A BUĎTE AKTÍVNY UŽ DNES.",
                    link: routes.SEARCH,
                    btnText: "ZADAJ MIESTO"
                }}
                height="80vH"
            />
            <Grid className="section" relaxed>
                <Grid.Row columns={1} centered style={{ marginTop: "3rem" }}>
                    <h3 style={styles.hashtag}>
                        #APbusiness
                    </h3>
                    <h2 className="big-h1">
                    V príprave
                    </h2>
                </Grid.Row>
            </Grid>
            {/* <APBusinessInfo /> */}
            <Footer />
        </div>
    )
}

const styles = {
    hashtag: {
        color: '#EE4DBA', 
        fontSize: '1.6rem',
        marginBottom: '0', 
        fontWeight: "bold",
    }
}
