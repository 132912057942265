// react
import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
// store
import { addUser, setLanguage } from "../../store/actions";
import { companyService } from "../../store/services/ServiceCompany";
import { countryService } from "../../store/services/ServiceCountry";
import { userProfileService } from "../../store/services/ServiceUserProfile";
import { LOADING_TIME } from "../../store/config";
import { providerOptions } from "../../store/services/helpers/settings";
// components
import ButtonSubmit from "../../components/buttons/ButtonSubmit";
import { Form, Grid } from "semantic-ui-react";
// others
import { useTranslation } from "react-i18next";
import { setSelectOptionsCountries } from "../../store/services/helpers/functions";
import Loading from "../../components/others/Loading";

// component
function ProfileSetup({ nextTutorial }) {
    const user = useSelector((state) => state.user);
    const { i18n } = useTranslation();
    let dispatch = useDispatch();

    // state
    const [nickname, setNickname] = useState("");
    const [providerType, setProviderType] = useState("");
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);

    // load data if exist for this user
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const data = await companyService.getCompanyInformation();
            const countriesData = await countryService.getCountries();
            const profile = await userProfileService.getUserProfile();

            if (profile[0]) {
                setCountry(profile[0].country.id);
            }

            // set state
            if (data[0]) {
                setNickname(data[0].nickname);
                setProviderType(data[0].providerType.toString());
            }
            setCountries(countriesData);

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
    }, []);

    // set country select options
    const countriesOptions = setSelectOptionsCountries(countries);

    /** component functions **/

    const handleRegister = async (event) => {
        event.preventDefault();
        setProcessing(true);

        if (nickname === "") {
            setError("Prezývka nebola vyplnená");
            setProcessing(false);
        } else {
            setError(null);

            const data = {
                nickname: nickname,
                providerType: parseInt(providerType),
            };

            const profileData = {
                status: true,
                language: countries.find(
                    (countryItem) => countryItem.id === country
                ).language,
                country: country,
            };

            if (await companyService.updateCompanyInformation(data)) {
                if (await userProfileService.updateUserProfile(profileData)) {
                    user.language = profileData.language;
                    dispatch(addUser(user));

                    // update global language state in redux store
                    dispatch(setLanguage(profileData.language));

                    // change language in i18n library to load language literals:
                    i18n.changeLanguage(profileData.language);
                    nextTutorial();
                }
            }
            setProcessing(false);
        }
    };

    const handleProviderTypeChange = (e, data) => {
        setProviderType(data.value);
    };

    const handleCountryChange = (e, data) => {
        setCountry(data.value);
    };

    // template
    return (
        <Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={6}>
                        <Form onSubmit={handleRegister}>
                            <Form.Select
                                required
                                fluid
                                label="Typ používateľa"
                                placeholder="Typ používateľa"
                                options={providerOptions}
                                onChange={handleProviderTypeChange}
                                value={providerType}
                            />

                            <Form.Select
                                required
                                fluid
                                label="Vyberte krajinu"
                                placeholder="Vyberte krajinu"
                                options={countriesOptions}
                                onChange={handleCountryChange}
                                value={country}
                            />

                            <Form.Input
                                required
                                autoFocus
                                fluid
                                label="Prezývka"
                                placeholder="Prezývka"
                                value={nickname}
                                onChange={(e) => setNickname(e.target.value)}
                                error={error}
                            />

                            <ButtonSubmit
                                loading={processing}
                                disabled={processing}
                                text="Pokračovať"
                            />
                        </Form>
                    </Grid.Column>
                </Grid>
            )}
        </Fragment>
    );
}

export default ProfileSetup;
