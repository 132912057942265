import userReducer from "./user";
import languageReducer from "./language";
import { combineReducers } from "redux";
import searchReducer from "./searchReducer";
import basketReducer from "./basket";
import basketConfigurationReducer from "./basketConfiguration";


const allReducers = combineReducers({
    user: userReducer,
    language: languageReducer,
    search: searchReducer,
    basket: basketReducer,
    basket_config: basketConfigurationReducer
});

export default allReducers;
