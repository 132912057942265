import { APIRequest } from "./helpers/requests";
import { API } from "../config";

export const futureEventService = {
    getFutureEvents,
    createEvent,
    updateEvent,
};

async function getFutureEvents() {
    return await APIRequest({
        url: API.FUTURE_EVENTS,
        method: "GET",
        private: true,
    });
}

async function createEvent(data) {
    return await APIRequest({
        url: API.FUTURE_EVENTS,
        method: "POST",
        data: data,
        private: true,
    });
}

async function updateEvent(data, id) {
    return await APIRequest({
        url: API.FUTURE_EVENTS + id,
        method: "PUT",
        data: data,
        private: true,
    });
}
