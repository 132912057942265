// react
import React from "react";
import { Grid, Header, Icon, Container } from "semantic-ui-react";

// component
function GeneralConditionsText() {
    // template
    return (
        <Grid className="section">
            <Grid.Row columns={1} centered>
                <Header as="h2" style={{ color: "var(--primary)" }}>
                    VŠEOBECNÉ PODMIENKY POUŽÍVANIA
                </Header>
                <Header as="h4" style={{ marginTop: "0.5rem" }}>
                    ActivityPoint
                </Header>
                <Icon name="angle down" size="big" color="pink" />
            </Grid.Row>

            <Grid.Row columns={1} centered width={10}>
                <Container text style={{ textAlign: "justify" }}>
                    <Header as="h3" style={{ textAlign: "center" }}>
                        I. Úvod
                    </Header>
                    <ol>
                        <li>
                            <p>
                            Obchodná spoločnosť <b>AB & Partners, s.r.o.</b>, so sídlom: Námestie slobody 4, 066 01  Humenné,
                            IČO: 50 711 946, zapísaná v Obchodnom registri vedeným Okresným súdom Prešov,
                            v oddiele: Sro, vložke číslo: 34078/P (ďalej len „prevádzkovateľ“) 
                            je spoločnosť založená a existujúca podľa právneho poriadku Slovenskej 
                            republiky, ktorá je prevádzkovateľom a správcom webovej stránky www.activitypoint.xyz
                            a mobilnej aplikácie ActivityPoint.   
                            Prevádzkovateľ je zároveň jediným vlastníkom, autorom,  
                            ako aj správcom a prevádzkovateľom mobilnej aplikácie ActivityPoint, 
                            jediným držiteľom aj vlastníkom všetkých duševných, autorských, priemyselných 
                            a iných majetkových práv  spojených s touto aplikáciou a teda je 
                            jediným subjektom oprávneným na udeľovanie akýchkoľvek (všetkých) súhlasov 
                            a povolení pre užívanie a nakladanie s aplikáciou.
                            </p>
                            <br/>
                        </li>
                        <li>
                            <p>
                            Prevádzkovateľ prostredníctvom www.activitypoint.xyz 
                            umožňuje za podmienok stanovených v týchto 
                            Všeobecných podmienkach používania  (v ďalšom texte ako „VPP“) 
                            vopred neurčenému počtu subjektov, ponúkajúcich aktivity 
                            rôzneho druhu (najmä aktivity športové, pohybové, kultúrne, 
                            voľnočasové, rodinné, ekologické, a podobne) - ako ich zadávateľom,  
                            takéto aktivity :
                            </p>
                            <ol type="a">
                                <li>
                                    prezentovať a zviditeľniť pre širokú verejnosť 
                                    v mobilnej aplikácii ActivityPoint,
                                </li>
                                <li>
                                    prostredníctvom internetových služieb prevádzkovateľa 
                                    ich ponúkať (odplatne alebo bezodplatne) pre subjekty 
                                    vyhľadávajúce takéto aktivity ako záujemcov o aktivity,
                                </li>
                            </ol>
                            <p>
                                čím prevádzkovateľ umožňuje súčasne zadávateľom prezentovať a ponúkať 
                                nimi ponúkajúce a zadefinované aktivity a adresátom týchto ponúk (záujemcom o aktivity)
                                umožňuje vyhľadať informácie o type, miestach a časoch takto ponúkaných aktivít zadávateľmi.
                                Takto sa pomocou aplikácie ActivityPoint vytvára samotná možnosť prepojenia, 
                                ako aj reálne priame prepojenie, ponuky aktivít (zadávateľmi) a 
                                dopytu po takýchto aktivitách (záujemcami).
                            </p>
                        </li>
                        <li>
                            <p>
                                Každý subjekt (fyzická osoba, alebo právnická osoba, 
                                bez ohľadu na miesto bydliska alebo registrované sídlo subjektu) 
                                využívajúci možnosti prezentovania a ponuky svojich aktivít – označovaný ako Zadávateľ - 
                                prostredníctvom www.activitypoint.xyz sa zaväzuje dodržiavať tieto VPP 
                                momentom jeho platnej registrácie na webovej stránke www.activitypoint.xyz 
                                a týmto momentom registrácie je viazaný týmito VPP. 
                                Vykonaním úkonov smerujúcich k registrácii osoby ako Zadávateľa vyjadruje 
                                konkrétny subjekt plný súhlas s celým obsahom VPP, ako aj to že 
                                sa ešte pred svojou registráciou s týmito VPP oboznámil takým spôsobom, 
                                že im porozumel v celom rozsahu a rešpektuje ich záväznosť pre vzťahy 
                                medzi ním a Prevádzkovateľom.  
                            </p>
                        </li>
                        <li>
                            <p>
                                Každý subjekt (fyzická osoba, alebo právnická osoba, bez ohľadu 
                                na miesto bydliska alebo registrované sídlo subjektu), ktorý 
                                vyhľadáva aktivity ponúkané v aplikácii ActivityPoint – 
                                označovaný ako Záujemca o aktivity - sa zaväzuje dodržiavať tieto 
                                všeobecné podmienky používania a je viazaný týmito 
                                VPP momentom stiahnutia (alebo inej formy aktivovania a používania) 
                                aplikácie ActivityPoint do ním využívaného elektronického zariadenia 
                                (napr. mobilný telefón, tablet, počítač). Vykonaním úkonov 
                                smerujúcich k stiahnutiu (alebo inej formy aktivovania a používania) 
                                aplikácie ActivityPoint vyjadruje konkrétny subjekt plný súhlas 
                                s celým obsahom VPP, ako aj to že sa ešte pred svojou registráciou s 
                                týmito VPP oboznámil takým spôsobom, že im porozumel v celom rozsahu a rešpektuje 
                                ich záväznosť pre vzťahy medzi ním a Prevádzkovateľom.
                            </p>
                        </li>
                        <li>
                            <p>
                                Tieto všeobecné podmienky používania bližšie špecifikujú práva a 
                                povinnosti subjektov využívajúcich webovú stránku www.activitypoint.xyz 
                                a aplikácie ActivityPoint. Tieto VPP  sú k dispozícii na 
                                prehliadanie aj stiahnutie na webovej stránke www.activitypoint.xyz, 
                                čím sa zabezpečuje možnosť ich slobodného preskúmania a vyhodnotenia každému 
                                subjektu, ktorý sa pripojí k stránke www.activitypoint.xyz a to aj 
                                subjektom, ktoré nie sú registrované ani inak evidované v aplikácii ActivityPoint.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        II. Vymedzenie základných pojmov
                    </Header>

                    <ol>
                        <li>
                            <p>
                                <em>„Aktivitou“</em> sa pre účely týchto VPP rozumie akákoľvek aktívna fyzická činnosť, 
                                pasívna fyzická činnosť, prípadne duševná činnosť, ktorú ponúka zadávateľ aktivity 
                                pre záujemcov o aktivity, najmä  aktivity športové, pohybové, kultúrne, voľnočasové, 
                                rodinné, ekologické, a podobne.   Cez aplikáciu ActivityPoint sa neponúkajú (sú zakázané) 
                                aktivity ani ponuky, ktoré porušujú akékoľvek právne predpisy  platné na 
                                území Slovenskej republiky,  smerujú alebo nabádajú k ohrozovaniu života, 
                                zdravia, majetku alebo iných práv fyzických alebo právnických osôb, smerujú k 
                                erotickým alebo iným nemravným činnostiam alebo cieľom, propagujú 
                                násilie alebo nabádajú k porušovaniu ľudských práv, alebo majú politický obsah (cieľ).
                            </p>
                            <p>
                                V rámci VPP a aplikácie  ActivityPoint sa rozlišujú Aktivity do skupín : 
                            </p>
                            <ol type="1">
                                <li>
                                    <em>„bezplatná aktivita“</em> ktorou sa pre účely týchto VPP rozumie aktivita, 
                                    s ktorej objednávkou a následným využitím nie je spojená 
                                    povinnosť platby zo strany Záujemcu o aktivitu;
                                </li>
                                <li>
                                    <em>„odplatná aktivita“</em> ktorou sa pre účely týchto VPP rozumie aktivita, 
                                    s ktorej objednávkou a následným využitím je spojená povinnosť platby zo strany 
                                    Záujemcu o aktivitu, a to v sume a v čase definovanom 
                                    Prevádzkovateľom na web stránke www.activitypoint.xyz alebo v aplikácii.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                <em>„Zadávateľom aktivity“</em> sa pre účely týchto VPP rozumie subjekt (fyzická osoba – podnikateľ /podnikajúci na 
                                základe živnostenského alebo iného ako živnostenského oprávnenia/, alebo právnická osoba, bez ohľadu na miesto 
                                bydliska alebo registrované sídlo subjektu)  ponúkajúci Aktivitu, ktorý sa príslušným prevádzkovateľom 
                                zadefinovaným spôsobom zaregistroval na webovej stránke www.activitypoint.xyz a ponúkanú aktivitu 
                                špecifikoval podľa rozsahu vyžadovaným pre úspešné evidovanie, najmä  jej druhom a miestom jej 
                                realizácie. Zadávateľ aktivity zadáva prevádzkovateľovi svoje údaje v rozsahu špecifikovanom v 
                                registračnom formulári, pričom pre úspešnú aktiváciu registrácie je potrebné jeho vyjadrenie súhlasu 
                                s týmito VPP. Prevádzkovateľ posudzuje individuálne každý vyplnený registračný formulár od osoby, 
                                ktorá vyplnila registračný formulár, pričom účet Zadávateľa aktivity sa aktivuje až po schválení zo 
                                strany Prevádzkovateľa. Prevádzkovateľ nie je povinný schváliť každý vyplnený registračný formulár, 
                                pričom na schválenie registračného formulára neexistuje právny nárok. V prípade neschválenia registračného 
                                formulára Prevádzkovateľom, bude neschválenému subjektu zaslané oznámenie o neúspešnej registrácii do aplikácie ActivityPoint.
                            </p>
                            <p>
                                V rámci VPP a aplikácie  ActivityPoint sa 
                                rozlišujú Zadávatelia aktivity do skupín : 
                            </p>
                            <ol type="1">
                                <li>
                                    <em>„Zadávateľ samospráva“</em> ktorým sa pre účely týchto VPP rozumie subjekt -  jednotka územnej samosprávy,  
                                    ktorým je obec, mesto alebo samosprávny kraj; pričom špecifickou podmienkou pre registráciu 
                                    tohto typu Zadávateľa aktivity je to, že tento typ Zadávateľa aktivity do aplikácie 
                                    registruje výlučne Prevádzkovateľ , ktorý po vytvorení účtu zašle Zadávateľovi aktivity 
                                    elektronickú (emailovú) správu, na ktorú odpovedá Zadávateľ zaslaním potrebných údajov o 
                                    subjekte (v rozsahu požadovanom Prevádzkovateľom). Po doplnení príslušných údajov o subjekte 
                                    Zadávateľa je jeho účet aktívny, čím tento typ Zadávateľa získava aj osobitné možnosti pre zobrazovanie 
                                    solidárnych aktivít v rámci aplikácie ActivityPoint. Prvotným podkladom pre vytvorenie účtu pre prípravnú 
                                    registráciu subjektu tohto typu Zadávateľa je vždy výlučne osobitná písomná zmluva uzavretá medzi takýmto 
                                    subjektom a Prevádzkovateľom. 
                                </li>
                                <li>
                                    <em>„Zadávateľ business“</em> ktorým sa pre účely týchto VPP rozumie subjekt s podnikateľským oprávnením, 
                                    zapísaný do obchodného registra, živnostenského registra, alebo osoba 
                                    podnikajúca na základe iného ako živnostenského oprávnenia;
                                </li>
                                <li>
                                    <em>„Zadávateľ Couch“</em> ktorým sa pre účely týchto VPP rozumie subjekt s podnikateľským oprávnením, 
                                    zapísaný do obchodného registra, živnostenského registra, alebo osoba podnikajúca 
                                    na základe iného ako živnostenského oprávnenia; pričom špecifickou 
                                    podmienkou pre registráciu tohto typu Zadávateľa aktivity je to, že tento typ 
                                    Zadávateľa aktivity do aplikácie registruje výlučne Prevádzkovateľ , ktorý po 
                                    vytvorení účtu zašle Zadávateľovi aktivity elektronickú (emailovú) správu, 
                                    na ktorú odpovedá Zadávateľ zaslaním potrebných údajov o subjekte (v rozsahu požadovanom Prevádzkovateľom). 
                                    Po doplnení príslušných údajov o subjekte Zadávateľa je jeho účet aktívny. 
                                    Prvotným podkladom pre vytvorenie účtu pre prípravnú registráciu subjektu tohto typu 
                                    Zadávateľa je vždy výlučne osobitná písomná zmluva uzavretá medzi takýmto subjektom a Prevádzkovateľom.   
                                </li>
                            </ol>
                            <p>
                                Zaradenie Zadávateľa aktivity do niektorej vyššie uvedenej skupiny
                                zabezpečuje Prevádzkovateľ samostatne, s ohľadom na informácie a ich 
                                povahu získaných  od samotných Zadávateľov aktivity.
                            </p>
                        </li>
                        <li>
                            <p>
                                <em>„Záujemcom o aktivitu“</em> sa pre účely týchto VPP rozumie subjekt (fyzická osoba, bez ohľadu 
                                na miesto bydliska subjektu)  aktívne hľadajúci aktivitu, 
                                ktorý si príslušným prevádzkovateľom zadefinovaným spôsobom stiahol aplikáciu 
                                ActivityPoint a prostredníctvom nej a ňou zadaných kritérií vyhľadáva konkrétny 
                                druh aktivity, na konkrétnom mieste a jeho okolí. Záujemca o aktivitu 
                                zadáva prevádzkovateľovi svoje údaje v rozsahu: meno, priezvisko, e-mail, 
                                heslo, pričom pre úspešnú aktiváciu registrácie je potrebné jeho vyjadrenie súhlasu s týmito VPP. 
                            </p>
                        </li>
                        <li>
                            <p>
                                <em>„Webová stránka www.activitypoint.xyz“</em>{" "}
                                je web vlastnený a prevádzkovaný Prevádzkovateľom, prostredníctvom ktorého  
                                sa plnia príslušné úlohy a ciele definované v týchto VPP, za používania 
                                ktorého po splnení podmienok registrácie  je Zadávateľ aktivity oprávnený 
                                ponúkať príslušné aktivity pre záujemcov,  ktoré si Záujemca o aktivity môže 
                                vyhľadať podľa ním zadaných kritérií v aplikácii ActivityPoint. 
                            </p>
                        </li>
                        <li>
                            <p>
                                <em>„Aplikácia ActivityPoint“</em> je aplikácia, ktorú po stiahnutí do príslušného zariadenia môžu 
                                využívať záujemcovia o aktivitu a prostredníctvom ktorej záujemcovia o aktivitu na základe nimi 
                                uvedených kritérií majú možnosť vyhľadávať aktivity ponúkané zadávateľmi aktivít.  
                                Aplikácia je vlastnená a prevádzkovaná Prevádzkovateľom.  
                                Aplikácia funguje na bežne dostupných platformách, a pre jej úspešné používanie 
                                sa vyžadujú príslušné technické zariadenia (napr. mobilný telefón, tablet, počítač).  
                            </p>
                        </li>
                        <li>
                            <p>
                                <em>„Bankou“</em> sa rozumie  Tatra banka, a.s., so sídlom: Hodžovo námestie 3, 
                                Bratislava, IČO: 00686930, ktorá podľa osobitnej zmluvy a osobitne 
                                dohodnutých podmienok s Prevádzkovateľom je správcom platobných 
                                systémov používaných webovou stránkou www.activitypoint.xyz a aplikáciou  ActivityPoint.
                            </p>
                        </li>
                    </ol>
                    <Header as="h3" style={{ textAlign: "center" }}>
                        III. Podmienky registrácie a ponuky aktivít Zadávateľmi
                        aktivít
                    </Header>
                    <ol>
                        <li>
                            <p>
                            Zadávateľ aktivity, ktorý má záujem ponúknuť ním vykonávanú (alebo zabezpečovanú) 
                            aktivitu rôznym záujemcom o takúto aktivitu, ktorí si ju majú možnosť vyhľadať 
                            prostredníctvom aplikácie ActivityPoint,  je povinný sa zaregistrovať na webovej 
                            stránke www.activitypoint.xyz.   Bez vykonania príslušnej registrácie sa žiaden subjekt 
                             nemôže domáhať oprávnenia ponúkať svoje aktivity  prostredníctvom aplikácie ActivityPoint.
                            </p>
                        </li>
                        <li>
                            <p>
                                Registrácia sa vykonáva prostredníctvom odkazu „Registrovať ako poskytovateľ“, pričom po potvrdení 
                                predmetného odkazu vyplní zadávateľ aktivity prvotné údaje spočívajúce v zadaní e-mailového kontaktu 
                                zadávateľa aktivity, typu používateľa, IČO, názov spoločnosti a ostatných povinných údajov, hesla a 
                                opakovaného hesla,  ktoré musí byť totožné so zadaným heslom. Po riadnom zadaní prvotných údajov zadávateľ 
                                aktivity na ním uvedenom maile potvrdí ním vytvorený účet, ktorým je registrácia vytvorená. 
                                Potvrdením aktivovaného účtu prostredníctvom odkazu „potvrdiť účet“ je zadávateľ aktivity presmerovaný 
                                na webovú stránku www.activitypoint.xyz, kde sa môže prihlásiť do ním aktivovaného účtu prostredníctvom
                                zadania svojej emailovej adresy a hesla, ktoré zadal pri registrácii. Prevádzkovateľ si vyhradzuje 
                                právo odmietnuť registráciu Zadávateľa aktivity bez udania akéhokoľvek dôvodu. Na uzatvorenie 
                                spolupráce medzi Prevádzkovateľom a Zadávateľom aktivity neexistuje právny nárok. Pokiaľ Prevádzkovateľ 
                                vykoná potvrdenie žiadosti Zadávateľa aktivity, tak potvrdením aktivovaného účtu je zadávateľ aktivity
                                zaregistrovaný, samotným potvrdením registrácie zároveň prejavil súhlas s týmito VPP a je nimi viazaný. 
                            </p>
                        </li>
                        <li>
                            <p>
                                Po prvotnom prihlásení do svojho účtu je zadávateľ aktivity oprávnený editovať svoj profil a zadávať ním vytvorené aktivity. 
                                Zadávateľ aktivity si vytvorí svoju identitu (zahŕňajúcu aj meno v aplikácii), prostredníctvom ktorej bude zadávať a prezentovať 
                                svoje aktivity. Zadaná prezývka nesmie obsahovať vulgárne alebo inak nevhodné výrazy a rovnako tak ani slovné spojenia, prostredníctvom 
                                ktorých by mohla zasiahnuť do autorských práv, práv duševného vlastníctva alebo iných osobnostných práv tretích osôb. Po zadaní prezývky 
                                vyplní zadávateľ aktivity miesto a adresu poskytovanej aktivity a následne názov aktivity, kategóriu, typ aktivity, bližšiu špecifikáciu činnosti 
                                v rámci aktivity, dobu jej trvania a cenu, za ktorú takúto aktivitu ponúka, prípadne ďalšie povinné informácie. Zadávateľ aktivity zodpovedá za aktuálnosť 
                                a reálnosť ním zadaných údajov. V prípade, ak takéto údaje sú zjavne nereálne a nepravdivé, resp. nevhodné, resp. ak sa nepravdivosť a nereálnosť takýchto 
                                údajov preukáže neskôr, je prevádzkovateľ oprávnený vyzvať zadávateľa aktivity na bezodkladnú nápravu a úpravu ním zadaných údajov a v prípade, ak k náprave bezodkladne 
                                nedôjde, je prevádzkovateľ oprávnený aktivitu alebo zadávateľa aktivity „zneviditeľniť“ v aplikácii ActivityPoint alebo účet zadávateľa aktivity blokovať v používaní účtu 
                                Zadávateľa aktivity.Zadávateľ aktivity v takomto prípade nemá nárok na žiadnu kompenzáciu. 
                            </p>
                        </li>
                        <li>
                            <p>
                                Zadávateľ aktivity je oprávnený bezplatne ponúknuť prostredníctvom aplikácie ActivityPoint záujemcom o 
                                aktivity jednu aktivitu. V prípade záujmu ponúkať viac aktivít je zadávateľ aktivity oprávnený vykonať 
                                výber z viacerých balíkov služieb (pre Zadávateľov aktivity). Prevádzkovateľ ponúka Zadávateľom viaceré
                                možnosti využívania jeho účtu, ktoré sú rozdielne najmä podľa časového trvania aktivít, množstva zúčastnených osôb, 
                                ako aj podľa iných obmedzujúcich podmienok pre konkrétny typ balíka. Prevádzkovateľ zverejní aktuálne možnosti (aktuálne balíky a ich vlastnosti) 
                                vhodným spôsobom na webovej stránke www.activitypoint.xyz.
                            </p>
                        </li>
                        <li>
                            <p>
                                Zadávateľ aktivity registráciou berie na vedomie a potvrdzuje, že preberá 
                                zodpovednosť za reálnosť a pravdivosť ním zadaných aktivít a údajov a v prípade 
                                vzniku škody tretej osobe spôsobenej nepravdivosťou a nereálnosťou ním zadaných údajov za 
                                takúto škodu v plnej miere zodpovedá. Prevádzkovateľ  nevykonáva automatické overenie pravdivosti 
                                alebo reálnosti údajov zadaných samotným Zadávateľom aktivity, ani nevykonáva pokusné využívanie 
                                takto zverejnených aktivít.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        IV. Vyhľadávanie aktivít Záujemcami o aktivity
                    </Header>
                    <ol>
                        <li>
                            <p>
                                Záujemcovia o aktivity sú oprávnení prostredníctvom aplikácie ActivityPoint vyhľadávať aktivity ponúkané 
                                Zadávateľmi aktivity na základe kritérií zadaných do aplikácie ActivityPoint.  
                                Záujemca o aktivity je plne oprávnený slobodne sa rozhodnúť, či sa na vyhľadanej 
                                aktivite zúčastní, a takisto samostatne rozhoduje o tom, aké aktivity si vyhľadá a zrealizuje ich,  
                                pričom samostatne v plnom rozsahu nesie zodpovednosť za vhodnosť a primeranosť ním vyhľadanej a následne 
                                zrealizovanej aktivite na svoj vek, zdravotný stav, fyzickú kondíciu. Nevyhnutnou podmienkou pre zakúpenie 
                                aktivy Zaújemcom je vykonanie registrácie a súhlas s VPP.
                            </p>
                        </li>
                        <li>
                            <p>
                                Zadanými kritériami na vyhľadanie príslušnej aktivity sú predovšetkým poloha, vzdialenosť 
                                od aktuálnej polohy záujemcu o aktivity, dátum a čas vyhľadávanej aktivity prípadne kategórie aktivity. 
                                Po zadaní príslušných kritérií a ich potvrdení sa v aplikácii ActivityPoint zobrazia záujemcovi o 
                                aktivity ponúkané zadávateľom aktivity. 
                            </p>
                        </li>
                        <li>
                            <p>
                                Záujemca o aktivity berie na vedomie, že za aktuálnosť a reálnosť ponúkaných aktivít 
                                nenesie prevádzkovateľ žiadnu zodpovednosť, nakoľko zadávanie jednotlivých aktivít a 
                                ich špecifikácia je vo výlučnej pôsobnosti Zadávateľa aktivít. 
                                Prevádzkovateľ  nevykonáva automatické overenie pravdivosti alebo reálnosti 
                                údajov zadaných samotným Zadávateľom aktivity, ani nevykonáva pokusné 
                                využívanie takto zverejnených aktivít.
                            </p>
                        </li>
                        <li>
                            <p>
                                V prípadoch ak Záujemca o aktivity  zistí, že Aktivita zadaná Zadávateľom aktivity   
                                nebola  v uvedených podmienkach (mieste, čase, vlastnostiach, kvalite) dostupná,  
                                je oprávnený o tejto skutočnosti informovať Prevádzkovateľa. Takéto oznámenie nie je možné vykonať 
                                prostredníctvom samotnej aplikácie ActivityPoint. Prevádzkovateľ o takýchto informáciách 
                                od záujemcov bude informovať dotknutých Zadávateľov aktivít bez zbytočného odkladu.
                            </p>
                        </li>
                        <li>
                            <p>
                                Záujemca o aktivitu podľa podmienok a informácií ohľadne konkrétnej zverejnenej a ním zvolenej 
                                aktivity berie na vedomie rozdielnosť aktivít, z ktorých niektoré sú bezplatné a niektoré sú odplatné. 
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        V. Program AP Solidarity
                    </Header>
                    <ol>
                        <li>
                            <p>
                                V rámci aplikácie ActivityPoint sa realizuje aj Program AP Solidarity, ktorý predstavuje možnosť finančnej 
                                podpory Zadávateľov aktivít z kategórie „Zadávateľ samospráva“ zo strany Záujemcov o aktivity. 
                                Cieľom programu AP Solidarity je podpora Zadávateľov aktivít z tejto kategórie, priamo osobami, 
                                ktoré si vyhľadali a využili aktivitu  ponúkanú takýmto zadávateľom.
                            </p>
                        </li>
                        <li>
                            <p>
                                Záujemca o aktivitu, ktorý si vyhľadal a prípadne aj využil aktivitu, je oprávnený 
                                slobodne podľa vlastného rozhodnutia podporiť finančne konkrétneho Zadávateľa aktivity. 
                                Platba na tento účel sa považuje za finančný dar v prospech Zadávateľa aktivity 
                                (v zmysle § 628 a nasl. Občianskeho zákonníka,  zákona č. 40/1964 Zb. v zn.n.p.).  
                                Záujemca o aktivitu sa vzdáva práva možnosti žiadať o vrátenie daru, z dôvodu že na 
                                tento vzťah darovania je vylúčená možnosť použitia ustanovenia § 630 Občianskeho zákonníka,  
                                pretože Záujemca a Zadávateľ aktivity  nevstupujú do osobného kontaktu a Zadávateľ-samospráva  
                                je jednotkou územnej samosprávy (teda subjekt, ktorý nemôže sa správať voči Záujemcovi alebo k členom 
                                jeho rodiny tak, aby tým hrubo porušil dobré mravy). 
                            </p>
                        </li>
                        <li>
                            <p>
                                Prevádzkovateľ zhromažďuje platby v rámci Programu AP Solidarity na svojom bankovom účte v Banke, 
                                a následne tieto platby poukazuje konkrétnym Zadávateľom-samospráva,   
                                vždy  na základe osobitnej písomnej zmluvy medzi Prevádzkovateľom a Zadávateľom-samospráva. 
                                V osobitnej písomnej zmluve sa dohodnú všetky konkrétne práva a povinnosti, ako aj podmienky spolupráce, 
                                medzi Prevádzkovateľom a Zadávateľom-samospráva.   
                            </p>
                        </li>
                        <li>
                            <p>
                                Do programu AP Solidarity sa môže zapojiť každý Záujemca o aktivity, bez ohľadu na to, 
                                či vyhľadané Aktivity aj naozaj v skutočnosti využil alebo navštívil.  
                                Zapojenie sa do programu AP Solidarity vykoná Záujemca o aktivity realizovaním platby (v Záujemcom zvolenej výške) 
                                prostredníctvom aplikácie ActivityPoint, po zadaní príslušných údajov vyžadovaných aplikáciou.
                            </p>
                        </li>
                        <li>
                            <p>
                                Každý Zadávateľ-samospráva sa zaväzuje, že finančné prostriedky prijaté v rámci programu AP Solidarity použije s 
                                touto účelovou viazanosťou:  rozvoj, údržba a zlepšovanie kvality hmotných podmienok podporenej Aktivity 
                                (najmä opravy, údržba a zveľaďovanie príslušných technických zariadení, stavieb, pozemkov, technických pomôcok).  
                                Zadávateľ-samospráva vhodným spôsobom prostriedky prijaté v rámci tohto programu kumuluje tak, aby bolo použitie  
                                týchto prostriedkov efektívne a racionálne.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        VI. Program AP Business
                    </Header>
                    <ol>
                        <li>
                            <p>
                                V rámci aplikácie ActivityPoint sa realizuje aj Program AP Business, 
                                ktorý predstavuje možnosť podnikateľskej prezentácie.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        VII. Program AP Future
                    </Header>

                    <ol>
                        <li>
                            <p>
                                V rámci aplikácie ActivityPoint sa realizuje aj Program AP Future, ktorý predstavuje možnosť podnikateľskej prezentácie.
                            </p>
                        </li>
                        <li>
                            <p>
                                V rámci programu AP Future môžu zadávatelia vykonávať zakúpenie budúcich termínov, ktoré sú zadané Zadávateľmi aktivity.
                            </p>
                        </li>
                        <li>
                            <p>
                                Podmienky čerpania zakúpenej aktivity Záujemcom je možné na základe publikovaných informácií, ktoré zadal Zadávateľ v popise zadanej aktivity.
                            </p>
                        </li>
                        <li>
                            <p>
                                Podmienkou zakúpenia budúcej aktivity v rámci programu AP Future je vykonanie platby.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        VIII. Ochrana osobných údajov
                    </Header>
                    <ol>
                        <li>
                            <p>
                                Prevádzkovateľ ako prevádzkovateľ webovej
                                stránky www.activitypoint.xyz a aplikácie
                                ActivityPoint spracúva osobné údaje fyzických
                                osôb – používateľov webovej stránky
                                www.activitypoint.xyz a aplikácie ActivityPoint
                                (ďalej len „dotknuté osoby“) ako prevádzkovateľ
                                v zmysle článku 4 ods. 7 Nariadenia Európskeho
                                parlamentu a Rady č. 2016/679 o ochrane
                                fyzických osôb pri spracúvaní osobných údajov a
                                o voľnom pohybe takýchto údajov (v ďalšom texte
                                ako „Nariadenie GDPR“) a v zmysle zákona č.
                                18/2018 Z.z. o ochrane osobných údajov a o zmene
                                a doplnení niektorých zákonov.
                            </p>
                        </li>
                        <li>
                            <p>
                                Aby mohol zadávateľ aktivity ponúkať aktivity
                                prostredníctvom aplikácie ActivityPoint je
                                povinný sa zaregistrovať prostredníctvom webovej
                                stránky www.activitypoint.xyz a zadať potrebné
                                osobné údaje, najmä IČO (používané pri podnikaní
                                alebo inej činnosti Zadávateľa aktivity),
                                mailovú adresu, prípadne meno a priezvisko
                                dotknutej osoby a telefónne číslo.
                            </p>
                        </li>
                        <li>
                            <p>
                                Za týmto účelom spracúva prevádzkovateľ osobné
                                údaje dotknutých osôb v nasledovnom rozsahu:
                                e-mailová adresa, meno a priezvisko, telefónne
                                číslo, online identifikátory (cookies, FB pixel
                                kódy, Google Adwords kódy).
                            </p>
                        </li>
                        <li>
                            <p>
                                Prevádzkovateľ spracúva osobné údaje dotknutých
                                osôb na základe svojho oprávneného záujmu v
                                zmysle článku 6 ods. 1 písm. f) Nariadenia GDPR.
                                Oprávneným záujmom je ponúka aktivít zadávateľmi
                                aktivít prostredníctvom webovej stránky
                                www.activitypoint.xyz a aplikácie ActivityPoint
                                a aj vykonávanie marketingu.
                            </p>
                        </li>
                        <li>
                            <p>
                                Spracúvané osobné údaje uchováva prevádzkovateľ
                                po dobu oprávneného záujmu, t. j. kým záujmy
                                alebo základné práva a slobody dotknutých osôb
                                neprevážia nad takýmito záujmami. Zákonná
                                povinnosť uchovávania údajov v lehote určenej
                                osobitnými predpismi tým nie je dotknutá.{" "}
                            </p>
                        </li>
                        <li>
                            <p>
                                Poučenie o právach dotknutej osoby: Dotknutá
                                osoba má právo získať od prevádzkovateľa
                                potvrdenie o tom, či sa spracúvajú osobné údaje,
                                ktoré sa jej týkajú. Ak prevádzkovateľ takéto
                                osobné údaje spracúva, dotknutá osoba má právo
                                získať prístup k týmto osobným údajom a
                                informácie o účele spracúvania osobných údajov;
                                kategórii spracúvaných osobných údajov;
                                identifikácii príjemcu alebo o kategórii
                                príjemcu, ktorému boli alebo majú byť osobné
                                údaje poskytnuté, najmä o príjemcovi v tretej
                                krajine alebo o medzinárodnej organizácii; ak je
                                to možné, o dobe uchovávania osobných údajov a
                                ak to nie je možné, informáciu o kritériách jej
                                určenia; práve požadovať od prevádzkovateľa
                                opravu osobných údajov týkajúcich sa dotknutej
                                osoby, ich vymazanie alebo obmedzenie ich
                                spracúvania, alebo o práve namietať spracúvanie
                                osobných údajov; práve podať sťažnosť dozornému
                                orgánu; zdroji osobných údajov, ak sa osobné
                                údaje nezískali od dotknutej osoby; existencii
                                automatizovaného individuálneho rozhodovania
                                vrátane profilovania. Dotknutá osoba má právo na
                                to, aby prevádzkovateľ bez zbytočného odkladu
                                opravil nesprávne osobné údaje, ktoré sa jej
                                týkajú. So zreteľom na účel spracúvania osobných
                                údajov má dotknutá osoba právo na doplnenie
                                neúplných osobných údajov. Dotknutá osoba má
                                právo na to, aby prevádzkovateľ bez zbytočného
                                odkladu vymazal osobné údaje, ktoré sa jej
                                týkajú, ak: osobné údaje už nie sú potrebné na
                                účel, na ktorý sa získali alebo inak spracúvali;
                                dotknutá osoba odvolá súhlas, na základe ktorého
                                sa spracúvanie osobných údajov vykonáva, a
                                neexistuje iný právny základ pre spracúvanie
                                osobných údajov; dotknutá osoba namieta
                                spracúvanie osobných údajov a neprevažujú žiadne
                                oprávnené dôvody na spracúvanie osobných údajov;
                                osobné údaje sa spracúvajú nezákonne. Dotknutá
                                osoba má právo na to, aby prevádzkovateľ
                                obmedzil spracúvanie osobných údajov, ak:
                                dotknutá osoba namieta správnosť osobných
                                údajov, a to počas obdobia umožňujúceho
                                prevádzkovateľovi overiť správnosť osobných
                                údajov; spracúvanie osobných údajov je nezákonné
                                a dotknutá osoba namieta vymazanie osobných
                                údajov a žiada namiesto toho obmedzenie ich
                                použitia; prevádzkovateľ už nepotrebuje osobné
                                údaje na účel spracúvania osobných údajov, ale
                                potrebuje ich dotknutá osoba na uplatnenie
                                právneho nároku, alebo dotknutá osoba namietala
                                voči spracúvaniu osobných údajov. Dotknutá osoba
                                má právo získať osobné údaje, ktoré sa jej
                                týkajú a ktoré poskytla prevádzkovateľovi, v
                                štruktúrovanom, bežne používanom a strojovo
                                čitateľnom formáte a má právo preniesť tieto
                                osobné údaje ďalšiemu prevádzkovateľovi, ak je
                                to technicky možné. Právo na prenosnosť sa
                                nevzťahuje na spracúvanie osobných údajov
                                nevyhnutné na splnenie úlohy realizovanej vo
                                verejnom záujme alebo pri výkone verejnej moci
                                zverenej prevádzkovateľovi. Dotknutá osoba má
                                právo namietať spracúvanie jej osobných údajov z
                                dôvodu týkajúceho sa jej konkrétnej situácie
                                vykonávané v rámci oprávneného záujmu
                                prevádzkovateľa, vrátane profilovania založeného
                                na týchto ustanoveniach. Prevádzkovateľ nesmie
                                ďalej spracúvať osobné údaje, ak nepreukáže
                                nevyhnutné oprávnené záujmy na spracúvanie
                                osobných údajov, ktoré prevažujú nad právami
                                alebo záujmami dotknutej osoby, alebo dôvody na
                                uplatnenie právneho nároku. Dotknutá osoba má
                                právo namietať spracúvanie osobných údajov,
                                ktoré sa jej týkajú, na účel priameho marketingu
                                vrátane profilovania v rozsahu, v akom súvisí s
                                priamym marketingom. Ak dotknutá osoba namieta
                                spracúvanie osobných údajov na účel priameho
                                marketingu, prevádzkovateľ ďalej osobné údaje na
                                účel priameho marketingu nesmie spracúvať.
                                Dotknutá osoba má právo na to, aby sa na ňu
                                nevzťahovalo rozhodnutie, ktoré je založené
                                výlučne na automatizovanom spracúvaní osobných
                                údajov vrátane profilovania a ktoré má právne
                                účinky, ktoré sa jej týkajú alebo ju obdobne
                                významne ovplyvňujú pokiaľ sa to netýka osobných
                                údajov nevyhnutných na uzavretie zmluvy alebo
                                plnenie zmluvy medzi dotknutou osobou a
                                prevádzkovateľom. Dotknutá osoba pri podozrení,
                                že jej osobné údaje sú spracúvané neoprávnene,
                                môže podať sťažnosť Úradu na ochranu osobných
                                údajov SR.
                            </p>
                        </li>
                    </ol>
                    <p>
                        <b>
                            Tieto všeobecné podmienky užívania nadobúdajú
                            platnosť a účinnosť dňa 5.3.2020.
                        </b>
                    </p>
                    <p>
                        Prevádzkovateľ je oprávnený tieto  VPP  upravovať a meniť, pričom  na web stránke www.activitypoint.xyz  
                        a v aplikácii ActivityPoint  zverejní vždy posledné aktuálne znenie VPP, so zapracovaním prípadných zmien 
                        a doplnení samotného textu VPP.   Každá osoba používajúca  a aplikujúca  VPP je vždy povinná oboznámiť 
                        sa s obsahom aktuálneho znenia VPP. 
                    </p>
                    <p>
                        Práva a povinnosti osôb zúčastnených a používajúcich web stránku www.activitypoint.xyz  
                        a aplikáciu ActivityPoint  sa riadia VPP platnými a účinnými v čase vzniku právneho vzťahu dotknutej 
                        osoby s tým, že následné prípadné zmeny VPP sa vzťahujú  aj na práva a 
                        povinnosti osôb zúčastnených a používajúcich web stránku www.activitypoint.xyz  
                        a aplikáciu ActivityPoint  odo dňa ich prijatia a zverejnenia  samotným Prevádzkovateľom. 
                    </p>
                </Container>
            </Grid.Row>
        </Grid>
    );
}

export default GeneralConditionsText;
