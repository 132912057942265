import { PAGINATION_LIMIT } from "../../config";

export function convertToType(type, value){
    if (type === 1){
        return value / 1440
    } else if (type === 2){
        return value / 43800
    } else if (type === 3){
        return value / 525600
    } else if (type === 4){
        return value / 60
    } 

    return value
}

export function truncateString(str, size) {
    return str.length > size ? str.substring(0, size - 3) + "..." : str;
}

// pagination change page
export const paginationChangePage = (event, data, setFunction, list) => {
    setFunction(
        list.slice(
            data.activePage * PAGINATION_LIMIT - PAGINATION_LIMIT,
            data.activePage * PAGINATION_LIMIT
        )
    );
};

// set select options for dropdown form field
export const setSelectOptions = (fetchedData) => {
    let options = [];
    for (let i = 0; i < fetchedData.length; i++) {
        let option = {
            key: fetchedData[i].id,
            text: fetchedData[i].name,
            value: fetchedData[i].id,
        };
        options.push(option);
    }

    return options;
};

// set activities options for dropdown form field
export const setSelectOptionsActivities = (fetchedData, country) => {
    let options = [];

    for (let i = 0; i < fetchedData.length; i++) {
        let name = fetchedData[i].name;
        if (country.language === "cz") {
            name = fetchedData[i].name_cz;
        } else if (country.language === "en") {
            name = fetchedData[i].name_en;
        } else if (country.language === "de") {
            name = fetchedData[i].name_de;
        } else if (country.language === "ru") {
            name = fetchedData[i].name_ru;
        } else if (country.language === "fr") {
            name = fetchedData[i].name_fr;
        } else if (country.language === "es") {
            name = fetchedData[i].name_es;
        }
        let option = {
            key: fetchedData[i].id,
            text: name,
            value: fetchedData[i].id,
        };

        options.push(option);
    }

    return options;
};

// set countries options for dropdown form field
export const setSelectOptionsCountries = (countryList) => {
    let options = [];

    for (let i = 0; i < countryList.length; i++) {
        let option = {
            key: countryList[i].id,
            text:
                countryList[i].language === "en"
                    ? "United Kingdom"
                    : countryList[i].language === "cz"
                    ? "Czech Republic"
                    : countryList[i].name,
            value: countryList[i].id,
            flag:
                countryList[i].language === "en"
                    ? "uk"
                    : countryList[i].language,
        };

        options.push(option);
    }

    return options;
};

// set select options for dropdown form field
export const setSelectCountriesOptions = (fetchedData) => {
    let options = [];

    for (const [key, value] of Object.entries(fetchedData)) {
        let option = {
            key: key,
            text: value,
            value: key,
        };
        options.push(option);
    }

    return options;
};

// convert object full of tags into string
export const convertTagsToString = (data) => {
    let stringTags = "";
    if (data.tags) {
        let isFirst = true;
        for (let i = 0; i < data.tags.length; i++) {
            if (isFirst) {
                stringTags = stringTags + data.tags[i].name;
                isFirst = false;
            } else {
                stringTags = stringTags + ", " + data.tags[i].name;
            }
        }
    }

    return stringTags;
};

// convert string based on comma into ArrayList of tags
/**
 tags = [
    { name: "tagName" }, ...
 ]
 */
export const convertStringToTags = (data) => {
    let tagsList = [];
    let splitTagsArray = data.split(",");
    for (let i = 0; i < splitTagsArray.length; i++) {
        let tag = {
            // using trim to remove spaces before and after tag
            name: splitTagsArray[i].trim(),
        };
        // append new tag object
        tagsList.push(tag);
    }

    return tagsList;
};

export const handleSourceReference = (source) => {
    const origin = window.location.origin
    if (origin !== "http://localhost:3000" && origin !== "http://127.0.0.1:3000") {
        return source.replace('http', "https")
    }

    return source
}
