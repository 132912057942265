// react
import React from "react";
// components
import { Button, Icon } from "semantic-ui-react";

// component
function ButtonDelete({ loading, disabled, onClick, text }) {
    // template
    return (
        <Button
            type="button"
            size="big"
            color="pink"
            inverted
            style={{ marginTop: "1rem" }}
            loading={loading}
            disabled={disabled}
            onClick={onClick}
        >
            <Icon name="trash" /> {text}
        </Button>
    );
}

export default ButtonDelete;
