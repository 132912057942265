/* USER */
export const addUser = (user) => ({
    type: "ADD_USER",
    user,
});

export const logoutUser = () => ({
    type: "LOGOUT_USER",
});

export const setLanguage = (language) => ({
    type: "SET_LANGUAGE",
    language,
});

export const setSearchConfig = (config) => ({
    type: "SET_SEARCH_CONFIG",
    config,
});

export const addToBasket = (product) => ({
    type: "ADD_TO_BASKET",
    product,
});

export const addBasketConfiguration = (productList) => ({
    type: "ADD_BASKET_CONFIGURATION",
    productList,
});

export const addProductToBasketConfiguration = (product) => ({
    type: "ADD_PRODUCT_TO_BASKET_CONFIGURATION",
    product,
});

export const removeProductFromBasketConfiguration = (product) => ({
    type: "REMOVE_PRODUCT_FROM_BASKET_CONFIGURATION",
    product,
});

export const removeFromBasket = (product) => ({
    type: "REMOVE_FROM_BASKET",
    product,
});

export const removeAllFromBasket = () => ({
    type: "REMOVE_ALL_FROM_BASKET"
});

export const emptyBasketConfiguration = () => ({
    type: "EMPTY_BAKSET_CONFIGURATION"
});
