// react
import React/*, { useState }*/ from "react";
import { Link } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// store
// eslint-disable-next-line
import { routes } from "../../store/routes";
import { truncateString } from "../../store/services/helpers/functions";
// components
// import { DistanceMatrixService } from "@react-google-maps/api";
import { Card, /*Icon,*/ Label, Grid } from "semantic-ui-react";
// others
import moment from "moment";

// component
function InfoCard(props) {
    // eslint-disable-next-line
    const basket = useSelector((state) => state.basket);

    // states related to positions
    // const [usersPosition] = useState(props.usersPosition);
    // const [eventPosition] = useState({
    //     lat: parseFloat(props.event.address.latitude),
    //     lng: parseFloat(props.event.address.longitude),
    // });

    // states related to calculations
    // const [travelDistance, setTravelDistance] = useState("");
    // const [walkDuration, setWalkDuration] = useState("");
    // const [carDuration, setCarDuration] = useState("");

    // const distanceCalculationCar = (response) => {
    //     if (response !== null) {
    //         if (response.rows[0].elements[0].distance.text !== travelDistance)
    //             setTravelDistance(response.rows[0].elements[0].distance.text);

    //         if (response.rows[0].elements[0].duration.text !== carDuration)
    //             setCarDuration(response.rows[0].elements[0].duration.text);
    //     }
    // };

    // const distanceCalculationWalk = (response) => {
    //     if (response !== null)
    //         if (response.rows[0].elements[0].duration.text !== walkDuration)
    //             setWalkDuration(response.rows[0].elements[0].duration.text);
    // };

    const eventType = (eventType) => {
        switch (eventType) {
            case "business": {
                return "#APbusiness";
            }
            case "future": {
                return "#APfuture";
            }
            default: {
                return props.event.is_highlighted ? "#APsolidarity - event" : "#APsolidarity";
            }
        }
    };

    // template
    return (
        <>
            <Card
                as={Link}
                style={{ /*boxShadow: "none",*/ marginTop: "0.1rem", maxWidth: "200px" }}
                centered
                className={`cardhover cardOverlaying ${
                    props.selectedMarker.id === props.event.id &&
                    "highlight-card"
                }`}
                to={`/activity/${props.event.activity.id}/detail?event=` + props.event.id}
            >

                <div
                    style={{
                        width: "100%",
                        height: "85px",
                        backgroundImage: props.event.activity.mainImage > 0 
                            ? "url("+ window.location.protocol + "//" + window.location.host + props.event.activity.gallery.find(image => image.id === props.event.activity.mainImage).source +")" 
                            : "url(/images/general/image_placeholder.jpg)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />

                <Card.Content>
                    <Card.Header>
                        <Grid columns={2}>
                            <Grid.Row style={{ paddingTop: "0rem" }}>
                                <Grid.Column
                                    style={{ paddingLeft: 0 }}
                                    width="16"
                                    >
                                    {truncateString(props.event.activity.name, 20)}
                                </Grid.Column>
                                <Grid.Column style={{ fontSize: "1rem", paddingLeft: 0 }}>
                                    <small>
                                        { props.event?.event_type === "future" && moment(props.event.expiration).format("DD.MM.YYYY HH:mm") }
                                        { props.event?.event_type !== "future" && moment(props.event.starts).format("DD.MM.YYYY HH:mm") + " - " + moment(props.event.ends).format("HH:mm")}
                                    </small>
                                </Grid.Column>
                                {/* <Grid.Column width={4}>
                                    {
                                        props.normalPrice !== props.actualPrice && <div style={{ textDecoration: "line-through" }}><b>{props.normalPrice}</b></div>
                                    }
                                </Grid.Column> */}
                                <Grid.Column
                                    style={{ paddingLeft: 0 }}
                                    width="16"
                                >
                                    <div style={{ color: "#d60c8c" }}>
                                        <b>{props.event.activity.price} €</b>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Header>
                    {/* <Card.Description style={{ marginTop: 0 }}>
                        <small
                            style={{
                                display: "block",
                                width: "100%",
                                fontSize: "0.7rem",
                                textAlign: "center",
                            }}
                        >
                            {usersPosition.lat ? `${travelDistance}` : "- m"} od
                            vás <span />
                            &nbsp;
                            <span className="dot"> · </span>
                            &nbsp;
                            <Icon name="male" />
                            {usersPosition.lat ? `${walkDuration}` : "- min"}
                            &nbsp;
                            <span className="dot"> · </span>
                            &nbsp;
                            <Icon name="car" />
                            {usersPosition.lat ? `${carDuration}` : "- min"}
                        </small>
                    </Card.Description> */}
                    <Grid columns={1}>
                            <Grid.Row style={{ padding: 0 }}>
                                {/* <Grid.Column>
                                    {props.rating}
                                    <Rating
                                        icon="star"
                                        className="icnPink"
                                        maxRating={5}
                                        disabled
                                        defaultRating={3}
                                    />
                                </Grid.Column> */}
                            </Grid.Row>
                            <Grid.Row centered style={{ padding: 0 }}>
                                <Label
                                    className={ props.event.is_highlighted && "special-event" }
                                    color={
                                        props.event.event_type === "future"
                                            ? "green"
                                            : props.event.event_type ===
                                              "solidarity"
                                            ? "blue"
                                            : "pink"
                                    }
                                    style={{
                                        marginRight: "0.5rem",
                                        display: "block",
                                        width: "100%",
                                    }}
                                >
                                    {eventType(props.event.event_type)}
                                </Label>
                            </Grid.Row>

                            {/* {(basket.future_events.indexOf(props.event.id) !==
                                -1 ||
                                basket.events.indexOf(props.event.id) !==
                                    -1) && (
                                <Grid.Row>
                                    <Label
                                        as="a"
                                        href={routes.BASKET}
                                        style={{
                                            marginRight: "0.5rem",
                                            display: "block",
                                            width: "100%",
                                        }}
                                    >
                                        <Icon
                                            name="cart"
                                            style={{ marginRight: "0" }}
                                        />
                                    </Label>
                                </Grid.Row>
                            )} */}
                        </Grid>
                </Card.Content>

                {/* <Card.Content extra>
                    <div style={{ color: "black" }}>
                        
                    </div>
                </Card.Content> */}
            </Card>

            {/* {usersPosition?.lat && props.mapLoaded && (
                <>
                    <DistanceMatrixService
                        options={{
                            destinations: [eventPosition],
                            origins: [props.usersPosition],
                            travelMode: "DRIVING",
                        }}
                        callback={distanceCalculationCar}
                    />
                    <DistanceMatrixService
                        options={{
                            destinations: [eventPosition],
                            origins: [props.usersPosition],
                            travelMode: "WALKING",
                        }}
                        callback={distanceCalculationWalk}
                    />
                </>
            )} */}
        </>
    );
}

export default InfoCard;
