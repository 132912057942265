// react
import React from "react";
// components
import EventCalendar from "./event/ActivityEventCalendar";
import EventHeader from "./event/ActivityEventHeader";
import { Grid, Divider } from "semantic-ui-react";

// component
export default function ActivityInteract(props) {
    // template
    return (
        <Grid.Column mobile={14} tablet={14} computer={6}>
            <div className="invisible-box">
                <EventHeader
                    eventName={props.eventName}
                    rating={props.rating}
                    cost={props.cost}
                    sale={props.sale}
                    duration={props.duration}
                    position={props.usersPosition}
                    destination={props.selected.coords}
                    selected={props.selected}
                    timetable={props.timetable}
                />

                <Divider />

                {props.timetable ? (
                    <EventCalendar timetable={props.timetable} selected={props.selected} setSelected={props.setSelected} />
                ) : (
                    <></>
                )}
            </div>
        </Grid.Column>
    );
}
