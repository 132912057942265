// react
import React from "react";
// components
import { Button, Confirm } from "semantic-ui-react";

// component
function ModalConfirm({
    open,
    header,
    content,
    confirmButton,
    cancelButton,
    onCancel,
    onConfirm,
}) {
    // template
    return (
        <Confirm
            size="mini"
            open={open}
            header={header}
            content={content}
            confirmButton={
                <Button style={{ background: "#e03997" }}>
                    {confirmButton}
                </Button>
            }
            cancelButton={cancelButton}
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    );
}

export default ModalConfirm;
