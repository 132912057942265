// react
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
// store
import { routes } from "../../store/routes";
import { authService } from "../../store/services/ServiceAuth";
// components
import HeaderTitle from "../../components/others/HeaderTitle";
import RegisterTypeSelect from "./RegisterTypeSelect";
import RegisterClient from "./RegisterClient";
import RegisterProvider from "./RegisterProvider";
import { GAPageView, initGA } from "../../index";
import Navbar from "../../components/general/Navbar";

// component
function Register() {
    let history = useHistory();
    let dispatch = useDispatch();

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    useEffect(() => {
        async function checkUser() {
            let result = await authService.getAccessToken();
            if (result !== false) {
                history.push(routes.DASHBOARD);
            }
        }

        checkUser();
    }, [history, dispatch]);

    // template
    return (
        <div className="cms">
            <Navbar linkBack="/" />
            <div className="content-container" style={{ width: "100%" }}>
                <HeaderTitle
                    title="Registrácia - vytvorenie nového účtu"
                    style={{ textAlign: "center" }}
                />
                <section style={{ maxWidth: 800, margin: "0 auto" }}>
                    <Switch>
                        <Route
                            exact
                            path={routes.REGISTER}
                            component={RegisterTypeSelect}
                        />
                        <Route
                            exact
                            path={routes.REGISTER_CLIENT}
                            component={RegisterClient}
                        />
                        <Route
                            exact
                            path={routes.REGISTER_PROVIDER}
                            component={() => <RegisterProvider coach={false} />}
                        />
                    </Switch>
                </section>
            </div>
        </div>
    );
}

export default Register;
