// react
import React from "react";
// components
import ProfileLanguage from "./ProfileLanguage";
import ProfileChangePassword from "./ProfileChangePassword";
import ProfileNickname from "./ProfileNickname";
import ProfileActivation from "./ProfileActivation";
import ProfileSocialNetworks from "./ProfileSocialNetworks";
import ProfileAddress from "./ProfileAddress";
import { Tab } from "semantic-ui-react";

// panes
const paneLanguage = {
    menuItem: {
        key: "language",
        content: "Jazyk",
        icon: "language",
    },
    render: () => (
        <Tab.Pane>
            <ProfileLanguage />
        </Tab.Pane>
    ),
};

const panePassword = {
    menuItem: { key: "change_password", content: "Heslo", icon: "key" },
    render: () => (
        <Tab.Pane>
            <ProfileChangePassword />
        </Tab.Pane>
    ),
};

const paneNickname = {
    menuItem: {
        key: "nickname",
        content: "Prezývka",
        icon: "user",
    },
    render: () => (
        <Tab.Pane>
            <ProfileNickname />
        </Tab.Pane>
    ),
};

const paneSocialNetworks = {
    menuItem: {
        key: "social",
        content: "Sociálne siete",
        icon: "globe",
    },
    render: () => (
        <Tab.Pane>
            <ProfileSocialNetworks />
        </Tab.Pane>
    ),
};

const paneActivation = {
    menuItem: {
        key: "activation_state",
        content: "Deaktivácia",
        icon: "user remove",
    },
    render: () => (
        <Tab.Pane>
            <ProfileActivation />
        </Tab.Pane>
    ),
};

const paneAddress = {
    menuItem: {
        key: "address",
        content: "Adresa",
        icon: "map marker alternate",
    },
    render: () => (
        <Tab.Pane>
            <ProfileAddress />
        </Tab.Pane>
    ),
};

// profile settings admin
export const adminPanes = [paneLanguage, panePassword];

// profile settings provider
export const providerPanes = [
    paneNickname,
    paneSocialNetworks,
    paneLanguage,
    panePassword,
    paneActivation,
];

// profile settings client
export const userPanes = [paneLanguage, panePassword, paneAddress];
