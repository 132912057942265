// react
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// store
import { addUser } from "../../store/actions";
import { authService } from "../../store/services/ServiceAuth";
import { useDispatch, useSelector } from "react-redux";
// components
import HeaderTitle from "../../components/others/HeaderTitle";
import ProfileSetup from "./ProfileSetup";
import ActivityPlaceForm from "./ActivityPlaceForm";
import ActivityCreateForm from "./ActivityCreateForm";
import TutorialBillingForm from "./TutorialBillingForm";
import TutorialBankForm from "./TutorialBankForm";
import TutorialSuccessMessage from "./TutorialSuccessMessage";
import LinkLogo from "../../components/others/LinkLogo";
// others
import { GAPageView, initGA } from "../../index";
import { setStepsDesign } from "./functions";

// component
function Tutorial() {
    authService.useIsProvider();

    let history = useHistory();
    let dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    // state
    const [tutorialStep, setTutorialStep] = useState(0);
    const [successOpen, setSuccessOpen] = useState(false);

    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    useEffect(() => {
        async function checkUser() {
            if (user.id && user.tutorial) {
                history.push("/dashboard/myactivities");
                return;
            }
        }

        checkUser();
    }, [user, history]);

    /** component functions **/

    const nextTutorialStep = () => {
        setStepsDesign(true, tutorialStep);
        // set next tutorial step in state
        setTutorialStep(tutorialStep + 1);
    };

    const backTutorialStep = () => {
        setStepsDesign(false, tutorialStep);
        // set previous tutorial step in state
        setTutorialStep(tutorialStep - 1);
    };

    const handleSuccessOpen = () => {
        setSuccessOpen(true);
    };

    const handleSuccessClose = () => {
        // perform tutorial update here to avoid redirect force
        user.tutorial = true;
        dispatch(addUser(user));

        setSuccessOpen(false);
    };

    const handleTutorialFinish = () => {
        handleSuccessOpen();
    };

    // template
    return (
        <div className="cms">
            <nav className="left-menu" style={{ paddingBottom: "1rem" }}>
                <LinkLogo />
                <p className="tutorial-step tutorialActive">Vytvor si profil</p>
                <p className="tutorial-step">Fakturačné údaje</p>
                <p className="tutorial-step">Bankový účet</p>
                <p className="tutorial-step">Miesto konania aktivity</p>
                <p className="tutorial-step">Vytvoriť aktivitu</p>
            </nav>
            <div className="content-container">
                <HeaderTitle title="Tutoriál - základné kroky poskytovateľa" />
                <section>
                    {tutorialStep === 0 ? (
                        <ProfileSetup nextTutorial={nextTutorialStep} />
                    ) : tutorialStep === 1 ? (
                        <TutorialBillingForm
                            nextTutorial={nextTutorialStep}
                            backTutorial={backTutorialStep}
                        />
                    ) : tutorialStep === 2 ? (
                        <TutorialBankForm
                            nextTutorial={nextTutorialStep}
                            backTutorial={backTutorialStep}
                        />
                    ) : tutorialStep === 3 ? (
                        <ActivityPlaceForm
                            nextTutorial={nextTutorialStep}
                            backTutorial={backTutorialStep}
                        />
                    ) : tutorialStep === 4 ? (
                        <ActivityCreateForm
                            backTutorial={backTutorialStep}
                            tutorialFinish={handleTutorialFinish}
                        />
                    ) : (
                        ""
                    )}
                </section>
                <TutorialSuccessMessage
                    successOpen={successOpen}
                    handleSuccessClose={handleSuccessClose}
                />
            </div>
        </div>
    );
}

export default Tutorial;
