// react
import React, { useState } from "react";
// store
import { registrationService } from "../../../../store/services/ServiceRegistration";
// components
import RequestDetail from "./RequestDetail";
import ButtonIconDetail from "../../../../components/buttons/ButtonIconDetail";
import DimmerLoader from "../../../../components/others/DimmerLoader";
import DataTable from "../../../../components/tables/DataTable";
import { Dimmer, Loader, Button, Icon } from "semantic-ui-react";

// component
function RequestTable({ data, setData, isLoading }) {
    // state
    const [modalOpen, setModalOpen] = useState(false);
    const [actualRequest, setActualRequest] = useState({});
    const [pleaseWait, setPleaseWait] = useState(false);

    const orderedHeaders = [
        "providerType",
        "providerName",
        "created",
        "tableActions",
        "detail",
    ];

    const TableProviderType = ({ value }) => {
        return (
            <>
                {value === 1 ? (
                    <Icon name="user" title="Živnostník" />
                ) : (
                    <Icon
                        name="building"
                        title="Právnická osoba"
                    />
                )}
            </>
        );
    };

    const TableProviderName = ({ row }) => {
        return (
            <>
                <span style={{ fontWeight: "bold" }}>
                    {row.companyName}

                    {row.is_coach ? " (Kouč účet)" : ""}
                </span>
            </>
        );
    };

    const TableAction = ({ row }) => {
        return (
            <Button.Group>
                <Button
                    circular
                    title="Schváliť žiadosť"
                    color="green"
                    icon="checkmark"
                    size="small"
                    onClick={(e) => handleApprove(e, row.id)}
                />
                <Button
                    title="Zamietnuť žiadosť"
                    circular
                    color="red"
                    icon="x"
                    size="small"
                    onClick={(e) => handleDeny(e, row.id)}
                />
            </Button.Group>
        );
    };

    const TableDetail = ({ row }) => {
        return (
            <ButtonIconDetail
                title="Zobraziť detail žiadosti"
                onClick={() => handleModalOpen(row)}
            />
        );
    };

    const headers = {
        id: {
            text: "ID",
            invisible: true,
        },
        firstName: {
            text: "Meno",
            invisible: true,
        },
        lastName: {
            text: "Priezvisko",
            invisible: true,
        },
        companyName: {
            text: "Spoločnosť",
            invisible: true,
        },
        phone: {
            text: "Telefón",
            invisible: true,
        },
        email: {
            text: "Email",
            invisible: true,
        },
        nickName: {
            text: "Prezývka",
            invisible: true,
        },
        message: {
            text: "Poznámka",
            invisible: true,
        },
        country: {
            text: "Krajina",
            invisible: true,
        },
        id_number: {
            text: "IČO",
            invisible: true,
        },
        providerType: {
            text: "Typ",
            filterable: false,
            sortable: true,
            transform: (value) => {
                return <TableProviderType value={value} />;
            },
        },
        providerName: {
            text: "Poskytovateľ",
            filterable: false,
            sortable: true,
            transform: (value, index, row) => {
                return <TableProviderName row={row} />;
            },
        },
        created: {
            text: "Dátum",
            invisible: false,
            sortable: true,
            filterable: false,
        },
        tableActions: {
            text: "Akcie",
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return <TableAction row={row} />;
            },
        },
        detail: {
            text: "Detail",
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return <TableDetail row={row} />;
            },
        },
    };

    /** component functions **/

    const serviceSuccess = (id) => {
        setData(prevState => prevState.filter((item) => item.id !== id));
        setPleaseWait(false);
    };

    const serviceError = () => {
        alert("Operácia sa nepodarila, skúste to znova prosím.");
        setPleaseWait(false);
    };

    const handleModalOpen = (request) => {
        setActualRequest(request);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleApprove = async (event, id) => {
        event.preventDefault();
        if (
            window.confirm(
                "Naozaj si želáte povoliť žiadosť? Služba vytvorí prístup do aplikácie pre žiadateľa."
            )
        ) {
            setPleaseWait(true);
            // send request for APPROVE service
            if (await registrationService.approveRequest(id)) {
                serviceSuccess(id);
            } else {
                serviceError();
            }
        }
    };

    const handleDeny = async (event, id) => {
        event.preventDefault();
        if (
            window.confirm(
                "Naozaj si želáte zamietnuť žiadosť? Služba informuje žiadateľa o tomto rozhodnutí."
            )
        ) {
            setPleaseWait(true);
            // send request for DENY service
            if (await registrationService.denyRequest(id)) {
                serviceSuccess(id);
            } else {
                serviceError();
            }
        }
    };

    // template
    return (
        <>
            <DimmerLoader active={isLoading} />
            <Dimmer active={pleaseWait}>
                <Loader indeterminate>
                    Spracovávam požiadavku, počkajte prosím
                </Loader>
            </Dimmer>
            <DataTable
                data={data}
                orderedHeaders={orderedHeaders}
                headers={headers}
            />

            <RequestDetail
                modalOpen={modalOpen}
                handleModalClose={handleModalClose}
                request={actualRequest}
            />
        </>
    );
}

export default RequestTable;
