// react
import React, { Fragment, useEffect, useState } from "react";
// store
import { PAGINATION_LIMIT } from "../../../../store/config";
import { paginationChangePage } from "../../../../store/services/helpers/functions";
// components
import ProviderDetail from "./ProviderDetail";
import ButtonIconDetail from "../../../../components/buttons/ButtonIconDetail";
import DimmerLoader from "../../../../components/others/DimmerLoader";
import { Table, Header, Pagination } from "semantic-ui-react";

// components
function CoachNetworkTable({ providers, isLoading }) {
    // state
    const [providerList, setProviderList] = useState(
        providers.slice(0, PAGINATION_LIMIT)
    );
    const [modalOpen, setModalOpen] = useState(false);
    const [providerData, setProviderData] = useState({});

    // set data to table
    useEffect(() => {
        setProviderList(providers.slice(0, PAGINATION_LIMIT));
    }, [providers]);

    /** component functions **/

    const handleModalOpen = (provider) => {
        setProviderData(provider);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    // template
    return (
        <Fragment>
            <DimmerLoader active={isLoading} />
            <Table striped unstackable style={{ overflow: "auto" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Meno</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Aktívny
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Dátum registrácie
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Počet aktivít
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Obrat
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Akcia
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {providerList.map((provider, providerId) => (
                        <Table.Row key={providerId}>
                            <Table.Cell>
                                <Header as="h5">
                                    {provider.firstname} {provider.lastname}{" "}
                                    <br />
                                    <small>{provider.user.email}</small>
                                </Header>
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {provider.user.is_active ? (
                                    <span
                                        style={{
                                            color: "green",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Áno
                                    </span>
                                ) : (
                                    <span
                                        style={{
                                            color: "red",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Nie
                                    </span>
                                )}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {provider.created}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {provider.offers.length}
                            </Table.Cell>
                            <Table.Cell textAlign="center">0 €</Table.Cell>
                            <Table.Cell textAlign="center">
                                <ButtonIconDetail
                                    title="Zobraziť detail"
                                    onClick={() => handleModalOpen(provider)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="6">
                            <Pagination
                                defaultActivePage={1}
                                totalPages={Math.ceil(
                                    providers.length / PAGINATION_LIMIT
                                )}
                                onPageChange={(
                                    event,
                                    data,
                                    setProviderList,
                                    providers
                                ) => paginationChangePage}
                                boundaryRange={0}
                                siblingRange={0}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <ProviderDetail
                modalOpen={modalOpen}
                handleModalClose={handleModalClose}
                provider={providerData}
            />
        </Fragment>
    );
}

export default CoachNetworkTable;
