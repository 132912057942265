import React from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
// components
import { Icon, Label } from "semantic-ui-react";
import { useSelector } from "react-redux";

const BasketIcon = () => {
    const basket = useSelector((state) => state.basket);
    const total = basket.future_events.length + basket.events.length;

    return (
        <Link to={routes.BASKET}>
            <div style={{ 
                zIndex: "100",
                position: "absolute",
                right: "10px",
                top: "20px",
                padding: "0.8rem",
                background: "#d60c8c",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                marginRight: "2rem",
                // boxShadow: "1px 2px 2px #000000",
                border: "2px solid black"
            }}>
                <Icon name="shopping basket" size="large" style={{ color: "white" }}/>
                <Label
                    circular
                    color="black"
                    style={{ top: "0rem", left: "-1rem", position: "absolute", zIndex: "100" }}
                >
                    {total}
                </Label>
            </div>
        </Link>
    );
};

export default BasketIcon;
