import React, { useState } from 'react';
import moment from 'moment';
// store 
import { API } from '../../../../store/config';
import { commonService } from '../../../../store/services/ServiceCommon';
// components
import { Button, Header, Divider, Form } from 'semantic-ui-react';
import { DateInput } from "semantic-ui-calendar-react";

const GenerateRewardForm = ({ view, filters, fetchRewards, onClose }) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const [rewards, setRewards] = useState(null)
    const [form, setForm] = useState({
        type: view,
        from: filters.from,
        to: filters.to
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        setRewards(null)
        const dateFrom = moment(form.from, "DD.MM.YYYY").format("YYYY-MM-DD")
        const dateTo = moment(form.to, "DD.MM.YYYY").format("YYYY-MM-DD")

        const request = await commonService.postRequest(API.GENERATE_REWARDS + `?from=${dateFrom}&to=${dateTo}&type=${form.type}`, {})
        
        if (request.status === 200){
            if( request.response.length === 0 ){
                setRewards(request.response)
            } else {
                await fetchRewards()
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            

            <Header as="h3" content="Vyberte obdobie pre generovanie odmien"/>
            <Form.Group widths="equal">
                <DateInput
                    fluid
                    required
                    clearable
                    localization="sk"
                    label="Dátum od"
                    placeholder="DD.MM.YYYY"
                    dateFormat="DD.MM.YYYY"
                    value={form.from}
                    onChange={(e, { value }) => setForm({...form, from: value})}
                    iconPosition="right"
                    animation="none"
                    closable
                    hideMobileKeyboard
                />
                <DateInput
                    fluid
                    required
                    clearable
                    localization="sk"
                    label="Dátum do"
                    placeholder="DD.MM.YYYY"
                    dateFormat="DD.MM.YYYY"
                    value={form.to}
                    onChange={(e, { value }) => setForm({...form, to: value})}
                    iconPosition="right"
                    animation="none"
                    closable
                    hideMobileKeyboard
                />
            </Form.Group>

            { rewards?.length === 0 && 
                <p style={{ fontWeight: "bold", fontSize: "1.3rem", textAlign: "center", color: "red"}}>
                    Za vybrané obdobie nebol identifikovaný žiaden nárok na odmeny.
                </p>
            }
            
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <Button type="button" onClick={() => onClose()} content={"Zavrieť"}/>
                <Button 
                    style={{ background: "var(--primary)", color: "var(--white)" }}
                    loading={isProcessing}
                    disabled={isProcessing || form.from === "" || form.to === ""}
                >Potvrdiť</Button>
            </Form.Field>
        </Form>
    );
};

export default GenerateRewardForm;