import request from "umi-request";
import { API } from "../config";

// services
import { authService } from "./ServiceAuth";

/*
 * Service functions
 */
export const socialNetworkService = {
    getSocialNetworks,
    getSocialNetworkById,
    createSocialNetwork,
    updateSocialNetwork,
    deleteSocialNetwork,
};

async function getSocialNetworks() {
    let data;
    // create Async - Await request
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_SOCIAL_NETWORKS, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return [];
        });

    return data;
}

async function getSocialNetworkById(id) {
    let data;
    // create Async - Await request
    let accessToken = await authService.getAccessToken();
    data = await request(API.GET_SOCIAL_NETWORKS + "?id=" + id, {
        method: "get",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return {};
        });

    return data;
}

async function createSocialNetwork(data) {
    let created;
    // create Async - Await request
    let accessToken = await authService.getAccessToken();
    created = await request(API.CREATE_SOCIAL_NETWORK, {
        method: "post",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return created;
}

async function updateSocialNetwork(data, id) {
    let updated;
    let accessToken = await authService.getAccessToken();
    updated = await request(API.EDIT_OR_DELETE_SOCIAL_NETWORK + id, {
        method: "put",
        data: data,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return updated;
}

async function deleteSocialNetwork(id) {
    let deleted;

    let accessToken = await authService.getAccessToken();
    deleted = await request(API.EDIT_OR_DELETE_SOCIAL_NETWORK + id, {
        method: "delete",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return deleted;
}
